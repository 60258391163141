import { Grid, InputLabel, TextField } from '@mui/material';

import Address from '~/models/masterdata/Address';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import UserUtils from '~/utils/userUtils';

import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import ImageUpload from '~/components/ImageUpload';
import BasicForm from '~/components/BasicForm';

import { OrganisationalGroupPaths } from '../paths/OrganisationalGroupPaths';
import { PermissionGrantEntityTable } from '../permissionGrant/PermissionsTable';

import { useCompanyForm } from './useCompanyForm';

export const CompanyForm = ({
  closeForm,
  company,
  onOpenOrganisationalGroup,
  onUpdatedOrganisationalGroupsChange,
  open,
  type,
}) => {
  const {
    formState,
    getUnsavedChanges,
    handleCancel,
    handleChangeCountry,
    handleChangeOrganisationalGroups,
    handleInputChange,
    handleSubmit,
    refreshCompany,
    renderForCreate,
    setCompanyLogo,
  } = useCompanyForm({
    closeForm,
    company,
    type,
  });

  return (
    <BasicForm
      open={open}
      formSuccess={handleSubmit}
      formAbort={handleCancel}
      title={`Firma ${renderForCreate() ? 'Erstellen' : company.name}`}
      fullWidth
      submittingForm={formState.submittingForm}
      id={company?.id}
      unsavedChanges={getUnsavedChanges()}
      missingPermissionsToSubmit={
        !UserUtils.isVestigasAccount() ||
        (renderForCreate()
          ? !UserUtils.isCompanyCreateAllowedUser()
          : !UserUtils.isCompanyWriteAllowedUser())
      }
    >
      <Grid container direction="row">
        {company?.organisationalGroupPaths?.length > 0 && (
          <Grid item xs={12}>
            <div className="mb-4">
              <OrganisationalGroupPaths
                id={company.id}
                organisationalGroupPaths={company.organGroupPaths}
                onOpenOrganisationalGroup={(organisationalGroup) =>
                  onOpenOrganisationalGroup(
                    organisationalGroup,
                    getUnsavedChanges(),
                  )
                }
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12} lg={8}>
          <Grid container direction="row" spacing={3} space={4}>
            <Grid item xs={12}>
              <h3 className="main-text mt-0">Firma</h3>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="name-input"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    value={formState.company?.name}
                    onChange={handleInputChange}
                    autoFocus
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className="main-text mt-4">Details</h3>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="street_name-input"
                    name="street_name"
                    label="Straße"
                    type="text"
                    fullWidth
                    required
                    value={formState.company?.address?.streetName}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="building_number-input"
                    name="building_number"
                    label="Hausnummer"
                    type="text"
                    fullWidth
                    value={formState.company?.address?.buildingNumber}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="post_code-input"
                    name="post_code"
                    label="PLZ"
                    type="text"
                    fullWidth
                    required
                    value={formState.company?.address?.postCode}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="city-input"
                    name="city"
                    label="Ort"
                    type="text"
                    fullWidth
                    required
                    value={formState.company?.address?.city}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel className="text-13px">Land</InputLabel>
                  <SearchableSelect
                    value={formState.company?.address?.country}
                    fullWidth
                    onChange={handleChangeCountry}
                    size="small"
                    options={Address.getCountryCodeOptions()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          className="mt-12 flex flex-col items-center justify-center"
        >
          <InputLabel className="text-13px">Firmenlogo</InputLabel>
          <ImageUpload
            image={formState.companyLogo}
            setImage={setCompanyLogo}
            onDelete={() => setCompanyLogo(null)}
            uploadText="Füge hier das Firmenlogo hinzu."
            missingPermissionsToWrite={!UserUtils.isCompanyWriteAllowedUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <h3 className="main-text mt-4">Ist Teil von...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={
                  PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                }
                pickedIds={formState.company.organisationalGroups}
                callbackPickedItems={handleChangeOrganisationalGroups}
                onChipClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(
                    organisationalGroup,
                    getUnsavedChanges(),
                  )
                }
                onUpdatedItemsChange={onUpdatedOrganisationalGroupsChange}
              />
            </Grid>
          </Grid>
        </Grid>

        {!renderForCreate() && (
          <Grid item xs={12}>
            <div className="h-4" />
            <PermissionGrantEntityTable
              title="Wer ist auf diese Firma berechtigt?"
              permissionGrantsFrom={formState.company.permissionGrantsFrom}
              defaultEntities={[company.id]}
              defaultEntityType={PermissionGrant.ENTITY_TYPE.COMPANY.KEY}
              fixedPicker={PermissionGrant.TYPE.ENTITY}
              refreshData={refreshCompany}
              loading={formState.companyLoading}
            />
          </Grid>
        )}
      </Grid>
    </BasicForm>
  );
};
