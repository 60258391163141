import { useQuery } from '@tanstack/react-query';

import axios from '~/utils/api-client';

import Config from '~/Config';

import { queryKeysSite } from './queryKeys';

/**
 * Fetches site data from the API.
 * @param {string} siteId - The ID of the site to fetch.
 * @returns {Promise<Object|null>} The site data if successful, null otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/get_site_site__site_uuid__get
 */
export const fetchSite = async (siteId) =>
  axios
    .get(`${Config.apiUrl}/site/${siteId}`)
    .then(({ data, status }) => (status === 200 ? data : null));

/**
 * React Query based custom hook for getting the data for a site with a given siteId.
 * @param {string} siteId - The ID of the site to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQuerySite = (siteId, options) =>
  useQuery({
    queryKey: queryKeysSite.get(siteId),
    queryFn: () => fetchSite(siteId),
    ...options,
  });
