import React from 'react';
import { connect } from 'react-redux';
import { SettingsTable } from '../../SettingsTable';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import MapperService from '~/services/mapper.service';
import Category from '~/models/articleMaster/Category';
import Article from '~/models/articleMaster/Article';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'linkedArticles',
    headerName: 'Enthaltene Artikel',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'subCategories',
    headerName: 'Unterkategorien',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'parentCategories',
    headerName: 'Oberkategorien',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'active',
    headerName: 'Aktiv',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'access',
    headerName: 'Berechtigung',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'ownerCompanies',
    headerName: 'Zuordnung zu Firmen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'filterCompanies',
    headerName: 'Suchbar in Firmen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'filterSites',
    headerName: 'Suchbar in Standorten',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'filterCostCenters',
    headerName: 'Suchbar in Kostenstellen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  articleMaster: state.articleMaster,
  companies: state.companies,
  sites: state.sites,
  costCenters: state.costCenters,
});

class CategoryTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({
      rows: this.props.articleMaster?.categories?.map((category) => {
        const row = {
          id: category.id,
          name: category.name,
          linkedArticles: category.linkedArticles
            .map((linkedArticleId) => {
              const article = this.props.articleMaster.articles.find(
                (article) => article.id === linkedArticleId,
              );
              if (!article) {
                return '';
              }

              return article.articleId + ' ' + article.name;
            })
            .join(', '),
          subCategories: category.subCategories
            .map(
              (subCategoryId) =>
                this.props.articleMaster.categories.find(
                  (category) => category.id === subCategoryId,
                )?.name ?? '',
            )
            .join(', '),
          parentCategories: category.parentCategories
            .map(
              (parentCategoryId) =>
                this.props.articleMaster.categories.find(
                  (category) => category.id === parentCategoryId,
                )?.name ?? '',
            )
            .join(', '),
          active: category.active
            ? Category.IS_ACTIVE.YES
            : Category.IS_ACTIVE.NO,
          access: Article.getAccessString(category.access),
          ownerCompanies: category.ownerCompanies
            .map(
              (ownerCompanyId) =>
                this.props.companies.companies.find(
                  (company) => company.id === ownerCompanyId,
                )?.name ?? '',
            )
            .join(', '),
          filterCompanies: category.filterCompanies
            .map(
              (filterCompanyId) =>
                this.props.companies.companies.find(
                  (company) => company.id === filterCompanyId,
                )?.name ?? '',
            )
            .join(', '),
          filterSites: category.filterSites
            .map(
              (filterSiteId) =>
                this.props.sites.sites.find((site) => site.id === filterSiteId)
                  ?.name ?? '',
            )
            .join(', '),
          filterCostCenters: category.filterCostCenters
            .map(
              (filterCostCenter) =>
                this.props.costCenters.costCenters.find(
                  (costCenter) => costCenter.id === filterCostCenter,
                )?.name ?? '',
            )
            .join(', '),
        };

        return MapperService.addSearchString(row, [
          'id',
          'name',
          'linkedArticles',
          'subCategories',
          'parentCategories',
          'active',
          'access',
          'ownerCompanies',
          'filterCompanies',
          'filterSites',
          'filterCostCenters',
        ]);
      }),
    });
  };

  render() {
    return (
      <SettingsTable
        entity="category"
        title="Kategorie"
        rows={this.state.rows}
        columns={columns}
        items={this.props.articleMaster.categories}
        localStorageKey={LocalStorageService.CATEGORY_TABLE}
        loading={this.props.articleMaster.categoriesLoading}
        hasActiveStateFilter
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CategoryTable),
  'Kategorien konnten nicht geladen werden.',
);
