import MapperService from '~/services/mapper.service';

import OrganisationalGroup from '~/models/masterdata/OrganisationalGroup';

export const selectOrganizationalUnits = (data) => {
  const rows = data.data.map((organizationalUnit) => {
    const row = {
      id: organizationalUnit.id,
      name: organizationalUnit.name,
    };

    return MapperService.addSearchString(row, ['id', 'name']);
  });

  return {
    organisationalGroups: data.data.map(
      (organizationalUnit) => new OrganisationalGroup(organizationalUnit),
    ), // FIXME: this needs to be mapped and initialize an OrganisationalGroup model
    rows,
    total: 2000, // data.total, FIXME: we are not getting this information back from the API yet!
  };
};
