import MasterDataService from '~/services/masterData.service';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import { updateDefaultSignatureRole } from './updateDefaultSignatureRole';
import { updateFeatureFlags } from './updateFeatureFlags';
import { updateParentOrganisationalGroups } from './updateParentOrganisationalGroups';
import { updatePushNotificationSettings } from './updatePushNotificationSettings';
import { updateSignatureRoles } from './updateSignatureRoles';
import { updateUser } from './updateUser';

const handleUserCreationError = (error) => {
  Log.error('Failed to create user.', error);

  if (error.response?.status === 409) {
    ToastService.httpError(
      [ToastService.MESSAGE.USER_CREATION_FAILED_DUPLICATE],
      error.response,
    );
    Log.productAnalyticsEvent(
      'Failed to create due to duplicate user',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  } else {
    ToastService.httpError(
      [ToastService.MESSAGE.USER_CREATION_FAILED],
      error.response,
    );
    Log.productAnalyticsEvent(
      'Failed to create',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  }
};

export const handleSubmit = async ({
  closeForm,
  event,
  isCreatingUser,
  originalData,
  refetchUser,
  refetchUsers,
  resetForm,
  setState,
  user,
}) => {
  event.preventDefault();

  setState((prevState) => ({
    ...prevState,
    isSubmitting: true,
  }));

  try {
    let body = {
      company_id: user.company.id,
      email: user.email,
      first_name: user.firstName,
      is_active: user.isActive,
      last_name: user.lastName,
      position: user.position,
      user_type: user.signatureRoles?.getUserType?.() ?? null,
      username: user.email,
    };

    if (user.password) {
      body.password = user.password;
    }

    if (isCreatingUser) {
      body = {
        ...body,
        org_units: user.organisationalGroups,
        permitted_signature_types:
          user.signatureRoles?.getSignatureRoles?.() ?? [],
      };
    }

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['company_id'],
        Log.FEATURE.USER,
      )
    ) {
      throw new Error('Missing required properties');
    }

    Log.info('Submit user form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.USER);

    if (isCreatingUser) {
      const [userId, error] = await promiseHandler(
        UserService.createUser(body),
      );

      if (error) {
        throw error;
      }

      if (
        JSON.stringify(user.featureFlags) !==
        JSON.stringify(originalData.featureFlags)
      ) {
        await updateFeatureFlags(userId, user.featureFlags);
      }

      if (
        user.signatureRoles.defaultSignatureRole !==
        originalData.signatureRoles.defaultSignatureRole
      ) {
        await updateDefaultSignatureRole(
          userId,
          user.signatureRoles.defaultSignatureRole,
        );
      }

      if (
        JSON.stringify(user.pushNotificationSettings) !==
        JSON.stringify(originalData.pushNotificationSettings)
      ) {
        await updatePushNotificationSettings(
          userId,
          user.pushNotificationSettings.getBackendFormat(),
        );
      }
    } else {
      const [, error] = await updateUser(user.id, body, setState);

      if (error) {
        throw error;
      }

      await updateParentOrganisationalGroups(
        user.id,
        originalData.organisationalGroups,
        user.organisationalGroups,
      );

      if (
        JSON.stringify(user.signatureRoles) !==
        JSON.stringify(originalData.signatureRoles)
      ) {
        await updateSignatureRoles(user.id, user.signatureRoles);
      }

      if (
        JSON.stringify(user.featureFlags) !==
        JSON.stringify(originalData.featureFlags)
      ) {
        await updateFeatureFlags(user.id, user.featureFlags);
      }

      if (
        user.signatureRoles.defaultSignatureRole !==
        originalData.signatureRoles.defaultSignatureRole
      ) {
        await updateDefaultSignatureRole(
          user.id,
          user.signatureRoles.defaultSignatureRole,
        );
      }

      if (
        JSON.stringify(user.pushNotificationSettings) !==
        JSON.stringify(originalData.pushNotificationSettings)
      ) {
        await updatePushNotificationSettings(
          user.id,
          user.pushNotificationSettings.getBackendFormat(),
        );
      }
    }

    setState((prevState) => ({
      ...prevState,
      isSubmitting: false,
    }));

    closeForm();
    resetForm(true);

    // Refresh the updated user and the list of all users.
    refetchUser();
    refetchUsers();

    ToastService.success(
      [
        'Benutzer',
        [user.firstName, user.lastName].filter(Boolean).join(' '),
        isCreatingUser ? 'erstellt' : 'aktualisiert',
      ]
        .filter(Boolean)
        .join(' '),
    );
  } catch (error) {
    Log.error('Error in handleSubmit', error);
    if (isCreatingUser) {
      handleUserCreationError(error);
    } else {
      ToastService.httpError(
        [ToastService.MESSAGE.USER_UPDATE_FAILED],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to update user',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    }
  } finally {
    setState((prevState) => ({
      ...prevState,
      isSubmitting: false,
    }));
  }
};
