import React, { useRef, useCallback } from 'react';

import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';

import BasicForm from '~/components/BasicForm';

import { withErrorBoundary } from '~/ui/atoms';

import PermissionGrantPicker from './PermissionGrantPicker';

export const PermissionGrantDialog = withErrorBoundary(
  ({
    closeForm,
    defaultEntities,
    defaultEntityType,
    defaultSubjects,
    defaultSubjectType,
    fixedPicker,
    loadData,
    open,
  }) => {
    const permissionGrantPickerRef = useRef(null);

    const handleSubmit = useCallback(
      async (event) => {
        event.preventDefault();
        event.stopPropagation();

        Log.info(
          'Submit permission grant dialog',
          null,
          Log.BREADCRUMB.FORM_SUBMIT.KEY,
        );
        Log.productAnalyticsEvent(
          'Submit form',
          Log.FEATURE.PERMISSION_GRANT_DIALOG,
        );

        if (
          permissionGrantPickerRef.current &&
          typeof permissionGrantPickerRef.current.submit === 'function'
        ) {
          const success = await permissionGrantPickerRef.current.submit();
          if (success) {
            if (typeof loadData === 'function') {
              loadData();
            }

            closeForm();
          } else {
            ToastService.error([
              'Es ist ein Fehler beim Vergeben der Berechtigungen aufgetreten.',
            ]);
          }
        } else {
          console.error(
            'PermissionGrantPicker ref is not available or submit method is not a function',
          );
          ToastService.error([
            'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
          ]);
        }

        closeForm();
      },
      [closeForm, loadData],
    );

    const handleCancel = useCallback(() => {
      Log.productAnalyticsEvent(
        'Abort form',
        Log.FEATURE.PERMISSION_GRANT_DIALOG,
      );
      closeForm();
    }, [closeForm]);

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        title="Berechtigungen vergeben"
        fullWidth
      >
        <PermissionGrantPicker
          ref={permissionGrantPickerRef}
          defaultSubjects={defaultSubjects}
          defaultSubjectType={defaultSubjectType}
          defaultEntityType={defaultEntityType}
          defaultEntities={defaultEntities}
          fixedPicker={fixedPicker}
        />
      </BasicForm>
    );
  },
  'Dialog konnte nicht geladen werden.',
);

PermissionGrantDialog.displayName = 'PermissionGrantDialog';
