import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { queryKeysSite } from './queryKeys';

export type SitesQueryParams = {
  limit: number;
  offset: number;
  searchString?: string;
  filterActive?: boolean;
  filterCompany?: UUID;
  filterSiteType?: string;
  orderBy: 'name' | 'id';
  returnAccountingReferences: boolean;
  returnTotalCount: boolean;
  sort: PaginationSortDirection;
};

export type Site = {
  id: UUID;
  name: string;
  // TODO: fully type site
};

type SitesResponse = {
  count: number;
  data: Site[];
};

const defaultQueryParams: SitesQueryParams = {
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  searchString: undefined,
  filterActive: undefined,
  filterCompany: undefined,
  filterSiteType: undefined,
  orderBy: 'name',
  returnAccountingReferences: true,
  returnTotalCount: true,
  sort: 'ASC',
};

/**
 * Generates the query options for the sites query so they can be shared between the useQuery hook and the prefetching.
 */
export const getSitesQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<SitesQueryParams>;
  options?: Omit<UseQueryOptions<SitesResponse, Error>, 'queryKey' | 'queryFn'>;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryKey: queryKeysSite.getAll(qp),
    queryFn: async () => fetchSites(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all sites from the API.
 * @param queryParams - The query parameters for fetching sites.
 * @returns The site data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/get_sites_site_get
 */
export const fetchSites = async (
  queryParams: Partial<SitesQueryParams>,
): Promise<SitesResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase() as PaginationSortDirection;

  for (const key of Object.keys(qp)) {
    if (qp[key as keyof SitesQueryParams] === undefined) {
      delete qp[key as keyof SitesQueryParams];
    }
  }

  try {
    return await vestigasApi
      .get(ENDPOINT.SITE.GET_ALL, {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<SitesResponse>();
  } catch (error) {
    Log.error('Error fetching sites', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all sites with given query parameters.
 * Handles pagination and prefetches the next page of sites for better performance.
 */
export const useQuerySites = (
  queryParams: Partial<SitesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getSitesQueryOptions({
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
        options,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<SitesResponse, Error>({
    ...getSitesQueryOptions({ queryParams, options }),
    placeholderData: keepPreviousData,
  });
};
