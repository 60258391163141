import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import UserUtils from '~/utils/userUtils';
import { promiseHandler } from '~/utils/promiseHandler';

import Log from '~/utils/Log';

export const updateFeatureFlags = async (userId, featureFlags) => {
  if (!UserUtils.isVestigasAccount()) {
    return;
  }

  Log.productAnalyticsEvent('Update feature flags', Log.FEATURE.USER);

  const {
    accessPermittedSites,
    concreteDiary,
    concreteEditingEnabled,
    lineItemDetailsEditingDisabled,
    ocrEnabled,
    recipientSetArrived,
    showOrderAtPreview,
    userCannotSetAccepted,
    userCannotSetDeclined,
    userCannotSetDelivered,
    userMetrics,
  } = featureFlags;

  const [, error] = await promiseHandler(
    UserService.updateUserFeatureFlags(userId, {
      access_permitted_sites: accessPermittedSites,
      concrete_diary: concreteDiary,
      concrete_editing_enabled: concreteEditingEnabled,
      line_item_details_editing_disabled: lineItemDetailsEditingDisabled,
      ocr_enabled: ocrEnabled,
      recipient_set_arrived: recipientSetArrived,
      show_order_at_preview: showOrderAtPreview,
      user_cannot_set_accepted: userCannotSetAccepted,
      user_cannot_set_declined: userCannotSetDeclined,
      user_cannot_set_delivered: userCannotSetDelivered,
      user_metrics: userMetrics,
    }),
  );

  if (error) {
    const lines = [
      ToastService.MESSAGE.USER_FEATURE_FLAGS_UPDATE_FAILED,
      UserService.getUserEmailFromStore(userId),
    ];
    ToastService.httpError(lines, error.response);
    Log.productAnalyticsEvent(
      'Failed to update feature flags',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  }
};
