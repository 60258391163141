import { useEffect } from 'react';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import ms from 'ms';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { queryKeysOrganizationalUnit } from './queryKeys';

export type OrganizationalUnitsQueryParams = {
  limit: number;
  offset: number;
  searchString?: string;
  filterCompanyAccount?: string;
  orderBy: string;
  sort: 'ASC' | 'DESC';
};

const defaultQueryParams: OrganizationalUnitsQueryParams = {
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  searchString: undefined,
  filterCompanyAccount: undefined,
  orderBy: 'name',
  sort: 'ASC',
};

export type OrganizationalUnitListItem = {
  companyAccountId: UUID;
  createdOn: string;
  id: UUID;
  modifiedOn: string;
  name: string;
};

export const getOrganizationalUnitsQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<OrganizationalUnitsQueryParams>;
  options?: Parameters<typeof useQuery>[0];
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryKey: queryKeysOrganizationalUnit.getAll(qp),
    queryFn: async () => fetchOrganizationalUnits(qp),
    staleTime: ms('60s'),
    ...options,
  };
};

export const fetchOrganizationalUnits = async (
  queryParams: Partial<OrganizationalUnitsQueryParams>,
): Promise<
  { count: number; data: OrganizationalUnitListItem[] } | undefined
> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase() as 'ASC' | 'DESC';

  for (const key of Object.keys(qp)) {
    if (qp[key] === undefined) {
      delete qp[key];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.ORGANIZATIONAL_UNIT.GET_ALL, {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<{
        count: number;
        data: OrganizationalUnitListItem[];
      }>();

    return response;
  } catch (error) {
    Log.error('Error fetching organizational units', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

export const useQueryOrganizationalUnits = (
  queryParams: Partial<OrganizationalUnitsQueryParams>,
  options: Parameters<typeof useQuery>[0],
): UseQueryResult<
  | {
      count: number;
      data: OrganizationalUnitListItem[];
    }
  | undefined
> => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getOrganizationalUnitsQueryOptions({
        queryParams: {
          ...queryParams,
          offset: queryParams.offset + queryParams.limit,
        },
        options,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery({
    ...getOrganizationalUnitsQueryOptions({ queryParams, options }),
    placeholderData: keepPreviousData,
  });
};
