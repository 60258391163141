import { memo } from 'react';

import DatagridUtils from '~/utils/datagridUtils';

import DeliveryStatus from '../../../DeliveryStatus';

export const DeliveryStatusCell = memo(({ localData, value }) => {
  const valueArray = value.split(';');

  const processState = valueArray?.[0];
  const combinedState = valueArray?.[2];
  const settledStatus = valueArray?.[3];

  const className =
    'w-170px ' + DatagridUtils.getStatusBoxHeight(localData?.rowHeight);

  const centerIcon =
    !localData?.rowHeight ||
    localData?.rowHeight <= DatagridUtils.ROW_HEIGHT.THIN;

  return (
    <div className={className}>
      <DeliveryStatus
        processState={processState}
        combinedState={combinedState}
        settledStatus={settledStatus}
        centerIcon={centerIcon}
        whiteBackground
      />
    </div>
  );
});
