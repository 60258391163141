import { type UUID } from '~/types/common';

/**
 * Query key factory for delivery note-related queries.
 */
export const queryKeysDeliveryNote = {
  base: () => 'deliveryNotes' as const,
  getPermittedUserIds: (deliveryNoteId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'permittedUsers',
      { deliveryNoteId },
    ] as const,
  getSharedWithUsers: (deliveryNoteId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'sharedWithUsers',
      { deliveryNoteId },
    ] as const,
} as const;
