import { useQuery } from '@tanstack/react-query';

import axios from '~/utils/api-client';

import Config from '~/Config';

import { queryKeysCostCenter } from './queryKeys';

/**
 * Fetches cost center data from the API.
 * @param {string} costCenterId - The ID of the cost center to fetch.
 * @returns {Promise<Object|null>} The cost center data if successful, null otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/Cost-Center/operation/get_cost_center_cost_center__id__get
 */
export const fetchCostCenter = async (costCenterId) =>
  axios
    .get(`${Config.apiUrl}/accounting_reference/${costCenterId}`)
    .then(({ data, status }) => (status === 200 ? data : null));

/**
 * React Query based custom hook for getting the data for a cost center with a given costCenterId.
 * @param {string} costCenterId - The ID of the cost center to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryCostCenter = (costCenterId, options) =>
  useQuery({
    queryKey: queryKeysCostCenter.get(costCenterId),
    queryFn: () => fetchCostCenter(costCenterId),
    ...options,
  });
