import Vehicle from '~/models/masterdata/Vehicle';

import MapperService from '~/services/mapper.service';

export const selectVehicles = (data) => {
  const vehicles = data.data.map((vehicle) => new Vehicle(vehicle));
  const rows = vehicles.map((vehicle) => {
    const row = {
      id: vehicle.id,
      licensePlate: vehicle.licensePlate.name,
      company: vehicle.companyName,
      active: vehicle.active ? Vehicle.IS_ACTIVE.YES : Vehicle.IS_ACTIVE.NO,
    };

    return MapperService.addSearchString(row, [
      'id',
      'licensePlate',
      'company',
    ]);
  });

  return {
    vehicles,
    rows,
    total: 2000, // data.total, FIXME: we are not getting this information back from the API yet!
  };
};
