import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { Divider, InputLabel, Checkbox, FormControlLabel } from '@mui/material';

import BasicForm from '~/components/BasicForm';
import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';

import { LightTooltipWide } from '~/utils/componentUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { useMapDirectDeliveryNoteForm } from './useMapDirectDeliveryNoteForm';

const selectActiveSites = createSelector(
  (state) => state.sites?.sites || [],
  (sites) => sites.filter(({ active }) => active),
);

const selectActiveCostCenters = createSelector(
  (state) => state.costCenters?.costCenters || [],
  (costCenters) => costCenters.filter(({ active }) => active),
);

export const MapDirectDeliveryNoteForm = withErrorBoundary(
  ({
    closeForm,
    defaultSelectedCostCenterId,
    defaultSelectedSiteId,
    deliveryNoteIds,
    formSuccess,
    issuerIds,
    open,
    refreshDeliveryNote,
    toSiteSupplierNames,
  }) => {
    const costCentersLoading = useSelector(
      (state) => state.costCenters.costCentersLoading,
    );
    const sitesLoading = useSelector((state) => state.sites.sitesLoading);
    const activeSites = useSelector(selectActiveSites);
    const activeCostCenters = useSelector(selectActiveCostCenters);

    const {
      handleCancel,
      handleDelete,
      handleSubmit,
      hasMultipleIssuersOrIdentifiers,
      setValue,
      state,
    } = useMapDirectDeliveryNoteForm({
      activeCostCenters,
      activeSites,
      costCentersLoading,
      sitesLoading,
      closeForm,
      defaultSelectedCostCenterId,
      defaultSelectedSiteId,
      deliveryNoteIds,
      formSuccess,
      issuerIds,
      refreshDeliveryNote,
      toSiteSupplierNames,
    });

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        formDelete={handleDelete}
        title="Standort zuweisen"
        submitButtonTitle="Zuweisen"
        deleteButtonTitle="Zuordnungen entfernen"
        submittingForm={state.submittingForm}
        deletingForm={state.deletingForm}
        noDeleteForm
      >
        <div className="text-13px text-grey600">
          Bitte gib an, zu welchem Standort und Kostenstelle die Lieferung(en)
          zugewiesen werden soll(en).
        </div>
        <InputLabel className="text-13px mt-20px">Standort</InputLabel>
        <div className="w-600px">
          <SearchableSelect
            options={state.sortedSites}
            value={state.form.selectedSite}
            onChange={(event) => setValue('selectedSite', event.target.value)}
            size="small"
            loading={sitesLoading}
            name="selectedSite"
            withEmptyOption
          />
        </div>
        <LightTooltipWide title="Sollen alle bisher berechtigten Standorte entfernt werden?">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.form.replaceSites}
                onChange={(e) => setValue('replaceSites', e.target.checked)}
                name="replaceSites"
              />
            }
            label="Bisher berechtigte Standorte entfernen"
          />
        </LightTooltipWide>
        <InputLabel className="text-13px mt-20px">Kostenstelle</InputLabel>
        <div className="w-600px">
          <SearchableSelect
            options={state.sortedCostCenters}
            value={state.form.selectedCostCenter}
            onChange={(event) =>
              setValue('selectedCostCenter', event.target.value)
            }
            size="small"
            loading={costCentersLoading}
            name="selectedCostCenter"
            withEmptyOption
          />
        </div>
        <LightTooltipWide title="Sollen alle bisher berechtigten Kostenstellen entfernt werden?">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.form.replaceCostCenters}
                onChange={(event) =>
                  setValue('replaceCostCenters', event.target.checked)
                }
                name="replaceCostCenters"
              />
            }
            label="Bisher berechtigte Kostenstellen entfernen"
          />
        </LightTooltipWide>
        <Divider className="mt-20px" />
        <LightTooltipWide
          title={
            hasMultipleIssuersOrIdentifiers() ? (
              'Verschiedene Lieferortbezeichnungen können nicht gleichzeitig einem Standort zugeordnet werden.'
            ) : (
              <span>
                Sollen Lieferungen mit der Lieferortbezeichnung{' '}
                <span className="ml-5px mr-5px bold">
                  {toSiteSupplierNames[0]}
                </span>{' '}
                zukünftig auch zu dem ausgewählten Lieferort zugeordnet werden?
              </span>
            )
          }
        >
          <FormControlLabel
            className="mt-10px"
            control={
              <Checkbox
                checked={state.form.shouldAddIdentifierToSite}
                onChange={(e) =>
                  setValue('shouldAddIdentifierToSite', e.target.checked)
                }
                name="shouldAddIdentifierToSite"
                disabled={hasMultipleIssuersOrIdentifiers()}
              />
            }
            label="Zukünftige Lieferungen mit dieser Lieferortbezeichnung dem Standort zuordnen"
          />
        </LightTooltipWide>
        {state.form.shouldAddIdentifierToSite && (
          <div>
            <LightTooltipWide title="Eine Zuordnung basiert normalerweise auf der vollständigen Adresse des Orts. Wenn die Adresse ignoriert wird, wird nur die Bezeichnung des Orts in der Zuordnung verwendet.">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.form.ignoreAddressInfo}
                    onChange={(event) =>
                      setValue('ignoreAddressInfo', event.target.checked)
                    }
                    name="ignoreAddressInfo"
                    disabled={!state.form.shouldAddIdentifierToSite}
                  />
                }
                label="Nur Lieferort-Bezeichnung verwenden und -Adresse ignorieren für Zuordnung"
              />
            </LightTooltipWide>
          </div>
        )}
      </BasicForm>
    );
  },
  null,
);
