import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Grid, Button } from '@mui/material';

import { connect } from 'react-redux';
import {
  TEST_TEMPLATE_FILE_TYPE,
  TestPdfTemplateForm,
} from './TestPdfTemplateForm';
import PdfTemplate from '~/models/masterdata/PdfTemplate';
import PdfTemplateTile from './PdfTemplateTile';
import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/components/Spinner';
import Log from '~/utils/Log';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import PdfsettingsService from '~/services/pdfsettings.service';
import { promiseHandler } from '~/utils/promiseHandler';
import ToastService from '~/services/toast.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import { PdfTestTemplateTile } from './PdfTestTemplateTile';
import PdfTemplateForm from './PdfTemplateForm';
import { SettingsTestIds } from 'constants/test-ids';

const mapStateToProps = (state) => ({
  pdfTemplates: state.pdfTemplates,
  companies: state.companies,
});

class PdfSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfTemplateFormOpen: false,
      newPdfTemplate: new PdfTemplate(),
      dlnToInvoiceCompanies: [],
      protocolToInvoiceCompanies: [],
      invoiceSettingsLoading: LOADING_STATE.NOT_LOADED,

      // test template state
      testPdfTemplateFormOpen: false,
      testPdfTemplateLoading: false,
      testPdfDataJSON: null,
      testPdfTemplate: null,
      testPdfTemplateId: null,
      testPdfAssetIds: '',
    };

    // if not company is selected, the pdf template is valid for the whole company account
    this.COMPANY_ACCOUNT_OPTION = {
      ID: 'company-account',
      NAME: 'Gesamter Firmenverbund',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.companies.companies) !==
      JSON.stringify(prevProps.companies.companies)
    ) {
      this.loadData();
    }
  }

  async loadData() {
    this.setState({
      invoiceSettingsLoading: LOADING_STATE.LOADING,
    });

    const newDlnToInvoiceCompanies = [];
    const newProtocolToInvoiceCompanies = [];

    for (
      let index = 0;
      index < this.props.companies.companies.length;
      index++
    ) {
      const [response, error] = await promiseHandler(
        PdfsettingsService.getCompanyDlnToInvoice(
          this.props.companies.companies[index].id,
        ),
      );

      if (error) {
        Log.error(
          'Failed to load invoice pdf dln setting for company. company id: ' +
            this.props.companies.companies[index].id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to load invoice pdf dln setting for company',
          Log.FEATURE.PDF_SETTINGS,
          Log.TYPE.ERROR,
        );
        this.setState({
          invoiceSettingsLoading: LOADING_STATE.FAILED,
        });
        return;
      }

      if (response.data) {
        newDlnToInvoiceCompanies.push(this.props.companies.companies[index]);
      }
    }

    for (
      let index = 0;
      index < this.props.companies.companies.length;
      index++
    ) {
      const [response, error] = await promiseHandler(
        PdfsettingsService.getInvoiceProtocolToInvoice(
          this.props.companies.companies[index].id,
        ),
      );

      if (error) {
        Log.error(
          'Failed to load invoice pdf protocol setting for company. company id: ' +
            this.props.companies.companies[index].id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to load invoice pdf protocol setting for company',
          Log.FEATURE.PDF_SETTINGS,
          Log.TYPE.ERROR,
        );
        this.setState({
          invoiceSettingsLoading: LOADING_STATE.FAILED,
        });
        return;
      }

      if (response.data) {
        newProtocolToInvoiceCompanies.push(
          this.props.companies.companies[index],
        );
      }
    }

    this.setState({
      invoiceSettingsLoading: LOADING_STATE.SUCCEEDED,
      dlnToInvoiceCompanies: newDlnToInvoiceCompanies,
      protocolToInvoiceCompanies: newProtocolToInvoiceCompanies,
    });
  }

  openForm = (type) => {
    Log.info(
      'Open pdf template create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent('Open create form', Log.FEATURE.PDF_TEMPLATE);

    const newPdfTemplate = new PdfTemplate();
    newPdfTemplate.type = type;
    newPdfTemplate.company.id = this.COMPANY_ACCOUNT_OPTION.ID;

    this.setState({
      pdfTemplateFormOpen: true,
      newPdfTemplate,
    });
  };
  closeForm = () => {
    Log.info('Close pdf template form', null, Log.BREADCRUMB.FORM_OPEN.KEY);

    this.setState({
      pdfTemplateFormOpen: false,
      newPdfTemplate: new PdfTemplate(),
    });
  };
  handleDlnToInvoiceCompaniesChange = async (companies) => {
    Log.productAnalyticsEvent(
      'Update invoice pdf dln setting',
      Log.FEATURE.PDF_SETTINGS,
    );

    for (
      let index = 0;
      index < this.props.companies.companies.length;
      index++
    ) {
      const [response, error] = await promiseHandler(
        PdfsettingsService.putCompanyDlnToInvoice(
          this.props.companies.companies[index].id,
          Boolean(
            companies.find(
              (company) =>
                company.id === this.props.companies.companies[index].id,
            ),
          ),
        ),
      );

      if (error) {
        ToastService.error([
          'PDF Einstellungen für Rechnungen konnten nicht vollständig aktualisiert werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.error(
          'Failed to update invoice pdf dln setting for company. company id: ' +
            this.props.companies.companies[index].id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to update invoice pdf dln setting',
          Log.FEATURE.PDF_SETTINGS,
          Log.TYPE.ERROR,
        );
      }
    }

    this.setState({
      dlnToInvoiceCompanies: companies,
    });
  };
  handleProtocolToInvoiceCompaniesChange = async (companies) => {
    Log.productAnalyticsEvent(
      'Update invoice pdf protocol setting',
      Log.FEATURE.PDF_SETTINGS,
    );

    for (
      let index = 0;
      index < this.props.companies.companies.length;
      index++
    ) {
      const [response, error] = await promiseHandler(
        PdfsettingsService.putInvoiceProtocolToInvoice(
          this.props.companies.companies[index].id,
          Boolean(
            companies.find(
              (company) =>
                company.id === this.props.companies.companies[index].id,
            ),
          ),
        ),
      );

      if (error) {
        ToastService.error([
          'PDF Einstellungen für Rechnungen konnten nicht vollständig aktualisiert werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.error(
          'Failed to update invoice pdf protocol setting for company. company id: ' +
            this.props.companies.companies[index].id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to update invoice pdf protocol setting',
          Log.FEATURE.PDF_SETTINGS,
          Log.TYPE.ERROR,
        );
      }
    }

    this.setState({
      protocolToInvoiceCompanies: companies,
    });
  };
  openTestPdfForm = () => {
    Log.info(
      'Open test pdf template create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent(
      'Open test pdf create form',
      Log.FEATURE.PDF_TEMPLATE,
    );

    this.setState({
      testPdfTemplateFormOpen: true,
    });
  };
  closeTestPdfForm = () => {
    Log.info(
      'Close test pdf template create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );

    this.setState({
      testPdfTemplateFormOpen: false,
      testPdfTemplateLoading: false,
      testPdfTemplate: null,
      testPdfTemplateId: null,
    });
  };
  handleTestPdfFileChange = async ({ type, value }) => {
    const stateKey =
      type === TEST_TEMPLATE_FILE_TYPE.DOCX
        ? 'testPdfTemplate'
        : type === TEST_TEMPLATE_FILE_TYPE.JSON
          ? 'testPdfDataJSON'
          : 'testPdfAssetIds';

    this.setState({
      [stateKey]: value,
    });
  };
  handleTestPdfFormSubmit = async () => {
    this.setState({
      testPdfTemplateLoading: true,
      testPdfTemplateId: null,
    });

    const body = {
      asset_file: this.state.testPdfDataJSON,
      template_file: this.state.testPdfTemplate,
      asset_ids:
        this.state.testPdfAssetIds?.length > 0
          ? this.state.testPdfAssetIds?.split(',')
          : [],
    };

    Log.info(
      'Submit test pdf tempalte form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit test template form',
      Log.FEATURE.PDF_TEMPLATE,
    );

    const [response, error] = await promiseHandler(
      PdfsettingsService.createTestPdfTemplate(body),
    );

    if (error) {
      this.setState({
        testPdfTemplateLoading: false,
      });

      ToastService.httpError(
        [ToastService.MESSAGE.PDF_TEMPLATE_CREATION_FAILED],
        error.response,
      );
      Log.error('Failed to create test pdf template', error);
      Log.productAnalyticsEvent(
        'Failed to create test template',
        Log.FEATURE.PDF_TEMPLATE,
        Log.TYPE.ERROR,
      );
      return;
    }

    if (response?.data?.id) {
      this.setState({
        testPdfTemplateId: response?.data?.id,
      });
    }

    this.setState({
      testPdfTemplateLoading: false,
      testPdfTemplateFormOpen: false,
    });
  };

  render() {
    const showTestPdfTile =
      !this.state.testPdfTemplateFormOpen && this.state.testPdfTemplateId;

    return (
      <div className="ml-2rem mr-2rem">
        <div data-testid={SettingsTestIds.HEADER.TITLE} className="main-header">PDF Einstellungen</div>
        <div className="text-24px bold mt-20px mb-20px">PDF Rechnungen</div>
        <div className="rounded-5px box-shadow-blue p-20px mb-20px flex-s-c gap-20px w-full bg-white">
          <div className="w-full">
            <div className="bold">Lieferungen in der Rechnung anhängen</div>
            {this.state.invoiceSettingsLoading === LOADING_STATE.FAILED ? (
              <div className="mt-20px">
                Einstellungen konnten nicht geladen werden.
              </div>
            ) : this.props.companies.companiesLoading ===
                LOADING_STATE.LOADING ||
              this.state.invoiceSettingsLoading === LOADING_STATE.LOADING ? (
              <div className="mt-20px inline-block">
                <Spinner />
              </div>
            ) : (
              <GenericMultiPicker
                textfieldLabel="für die Firmen..."
                pickedItems={this.state.dlnToInvoiceCompanies}
                allItems={this.props.companies.companies}
                callbackPickedItems={this.handleDlnToInvoiceCompaniesChange}
                fieldName="name"
                sortItems
                sortItemsByKey="name"
              />
            )}
          </div>
          <div className="w-full">
            <div className="bold">Prüfprotokoll in der Rechnung anhängen</div>
            {this.state.invoiceSettingsLoading === LOADING_STATE.FAILED ? (
              <div className="mt-20px">
                Einstellungen konnten nicht geladen werden.
              </div>
            ) : this.props.companies.companiesLoading ===
                LOADING_STATE.LOADING ||
              this.state.invoiceSettingsLoading === LOADING_STATE.LOADING ? (
              <div className="mt-20px inline-block">
                <Spinner />
              </div>
            ) : (
              <GenericMultiPicker
                textfieldLabel="für die Firmen..."
                pickedItems={this.state.protocolToInvoiceCompanies}
                allItems={this.props.companies.companies}
                callbackPickedItems={
                  this.handleProtocolToInvoiceCompaniesChange
                }
                fieldName="name"
                sortItems
                sortItemsByKey="name"
              />
            )}
          </div>
        </div>
        <div className="h-1px bg-grey400 w-full" />
        <div className="text-24px bold mt-20px mb-20px">
          PDF Vorlagen für Lieferungen
        </div>
        <Grid
          container
          columnSpacing="40px"
          justifyContent="space-between"
          className="mb-20px"
        >
          <Grid item xs={6}>
            <div className="text-20px bold mb-10px">
              {PdfTemplate.TYPE.DEFAULT.STRING}
            </div>
            <div className="flex-s-s flexdir-column gap-20px">
              {this.props.pdfTemplates.pdfTemplatesLoading ===
              LOADING_STATE.LOADING ? (
                <Spinner />
              ) : null}
              {this.props.pdfTemplates.pdfTemplatesLoading ===
              LOADING_STATE.FAILED
                ? 'PDF Vorlagen konnten nicht geladen werden.'
                : null}
              {this.props.pdfTemplates.pdfTemplatesLoading ===
              LOADING_STATE.SUCCEEDED
                ? this.props.pdfTemplates.pdfTemplates
                    .filter(
                      (pdfTemplate) =>
                        pdfTemplate.type === PdfTemplate.TYPE.DEFAULT.KEY,
                    )
                    .map((pdfTemplate) => (
                      <PdfTemplateTile
                        key={pdfTemplate.id}
                        pdfTemplate={pdfTemplate}
                        companyAccountOption={this.COMPANY_ACCOUNT_OPTION}
                      />
                    ))
                : null}
              <Button
                className="primary-button"
                startIcon={<AddIcon />}
                onClick={() => this.openForm(PdfTemplate.TYPE.DEFAULT.KEY)}
              >
                Neue Vorlage
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="text-20px bold mb-10px">
              {PdfTemplate.TYPE.CONCRETE.STRING}
            </div>
            <div className="flex-s-s flexdir-column gap-20px">
              {this.props.pdfTemplates.pdfTemplatesLoading ===
              LOADING_STATE.LOADING ? (
                <Spinner />
              ) : null}
              {this.props.pdfTemplates.pdfTemplatesLoading ===
              LOADING_STATE.FAILED
                ? 'PDF Vorlagen konnten nicht geladen werden.'
                : null}
              {this.props.pdfTemplates.pdfTemplatesLoading ===
              LOADING_STATE.SUCCEEDED
                ? this.props.pdfTemplates.pdfTemplates
                    .filter(
                      (pdfTemplate) =>
                        pdfTemplate.type === PdfTemplate.TYPE.CONCRETE.KEY,
                    )
                    .map((pdfTemplate) => (
                      <PdfTemplateTile
                        key={pdfTemplate.id}
                        pdfTemplate={pdfTemplate}
                        companyAccountOption={this.COMPANY_ACCOUNT_OPTION}
                      />
                    ))
                : null}
              <Button
                className="primary-button"
                startIcon={<AddIcon />}
                onClick={() => this.openForm(PdfTemplate.TYPE.CONCRETE.KEY)}
              >
                Neue Vorlage
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <div className="h-1px bg-grey400 w-full" />
        <div className="text-24px bold mt-20px mb-20px">
          PDF Vorlagen testen
        </div>
        <Grid
          container
          columnSpacing="40px"
          justifyContent="space-between"
          className="mb-20px"
        >
          <Grid item xs={6}>
            {showTestPdfTile ? (
              <PdfTestTemplateTile
                onClick={() => this.openTestPdfForm()}
                id={this.state.testPdfTemplateId}
                files={{
                  json: this.state.testPdfDataJSON,
                  template: this.state.testPdfTemplate,
                }}
                onDelete={() => {
                  this.setState({
                    testPdfDataJSON: null,
                    testPdfTemplate: null,
                  });
                }}
              />
            ) : (
              <Button
                className="primary-button"
                startIcon={<AddIcon />}
                onClick={() => this.openTestPdfForm()}
              >
                Vorlage testen
              </Button>
            )}
          </Grid>
        </Grid>

        <PdfTemplateForm
          open={this.state.pdfTemplateFormOpen}
          closeForm={this.closeForm}
          pdfTemplate={this.state.newPdfTemplate}
          companyAccountOption={this.COMPANY_ACCOUNT_OPTION}
          testPdfAssetIds={this.state.testPdfAssetIds}
        />

        <TestPdfTemplateForm
          loadingState={this.state.testPdfTemplateLoading}
          open={this.state.testPdfTemplateFormOpen}
          handleOnFileChange={this.handleTestPdfFileChange}
          closeForm={this.closeTestPdfForm}
          handleFormSubmit={this.handleTestPdfFormSubmit}
          files={{
            json: this.state.testPdfDataJSON,
            template: this.state.testPdfTemplate,
          }}
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(PdfSettings),
  'PDF Einstellungen konnten nicht geladen werden.',
);
