import { useMemo } from 'react';
import { useSettingsTable } from './useSettingsTable';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import { FreeTextSearch } from '~/components/baseComponents/inputs/FreeTextSearch';
import BasicTable from '~/components/BasicTable';

import {
  DataManagementLayout,
  DataTable,
  FiltersRow,
  PageTitle,
} from '~/ui/layouts/DataManagementLayout';
import { DatagridServerDriven } from '~/ui/molecules/Datagrid';

import { PermissionGrantDialog } from '../masterData/permissionGrant/PermissionGrantDialog';

import { ActiveStateFilter } from './ActiveStateFilter';
import { CreateItemButton } from './CreateItemButton';
import { FormComponent } from './FormComponent';
import { SettingsTestIds } from 'constants/test-ids';

/**
 * SettingsTable Component
 *
 * This component renders a settings page with a configurable data table for various settings entities.
 * It supports both client-side and server-side pagination, sorting, and filtering.
 *
 * @param {Object} props
 * @param {Array} props.columns - Array of column definitions for the table
 * @param {string} props.entity - The type of entity being displayed (e.g., 'user')
 * @param {Function} props.filterFunction - Filter function applied by the datagrid itself. Pass in x => x to handle filtering outside of the datagrid.
 * @param {boolean} props.hasActiveStateFilter - Whether to show the active state filter
 * @param {boolean} props.hasMultiPermissionGrantEditing - Whether to allow multi-permission grant editing
 * @param {Array} props.items - The data items to be displayed in the table
 * @param {boolean} props.loading - Whether the data is currently loading
 * @param {string} props.localStorageKey - Key for storing table state in localStorage
 * @param {string} props.multiPermissionGrantDefaultEntityType - Default entity type for multi-permission grants
 * @param {string} props.multiPermissionGrantDefaultSubjectType - Default subject type for multi-permission grants
 * @param {string} props.multiPermissionGrantFixedPicker - Fixed picker type for multi-permission grants
 * @param {Function} props.onPaginationModelChange - Callback for pagination model changes
 * @param {Function} props.onSearchStringChange - Callback for search string changes
 * @param {Function} props.onSortModelChange - Callback for sort model changes
 * @param {Object} props.paginationModel - Current pagination model
 * @param {Function} props.refreshData - Function to refresh the data
 * @param {Array} props.rows - The rows of data to be displayed in the datagrid
 * @param {string} props.sortBy - Default sort field
 * @param {Array} props.sortModel - Current sort model
 * @param {string} props.title - Title of the settings page
 * @param {number} props.totalRowCount - Total number of rows (for server-side pagination)
 *
 * @returns {JSX.Element} The rendered SettingsTable component
 */
export const SettingsTable = ({
  columns,
  entity,
  filterFunction,
  hasActiveStateFilter,
  hasMultiPermissionGrantEditing,
  items,
  loading,
  localStorageKey,
  multiPermissionGrantDefaultEntityType,
  multiPermissionGrantDefaultSubjectType,
  multiPermissionGrantFixedPicker,
  onPaginationModelChange,
  onSearchStringChange,
  onSortModelChange,
  paginationModel,
  refreshData,
  rows,
  sortBy,
  sortModel,
  title,
  totalRowCount,
}) => {
  const { eventHandlers, setState, state, utils } = useSettingsTable({
    entity,
    filterFunction,
    hasActiveStateFilter,
    hasMultiPermissionGrantEditing,
    items,
    onSearchStringChange,
    refreshData,
    rows,
    sortBy,
  });

  // We need a different Datagrid for data that is already using server-side paginated data.
  const isMigratedToServerSidePagination = [
    'user',
    'organisationalGroup',
    'vehicle',
  ].includes(entity);

  const memoizedDatagrid = useMemo(() => {
    if (isMigratedToServerSidePagination) {
      return (
        <DatagridServerDriven
          columns={columns}
          isLoading={loading}
          onMultiPermissionGrantEdit={eventHandlers.handleMultiPermissionGrantEdit()}
          onPaginationModelChange={onPaginationModelChange}
          onRowClick={(params) => eventHandlers.onRowClick(params.row)}
          onRowSelectionModelChange={eventHandlers.onRowSelectionModelChange}
          onSortModelChange={onSortModelChange}
          paginationMeta={{ totalRowCount }}
          paginationModel={paginationModel}
          rows={state.filteredRows}
          sortModel={sortModel}
          checkboxSelection
          disableRowSelectionOnClick
        />
      );
    }

    return (
      <BasicTable
        columns={columns}
        defaultHiddenColumns={['id']}
        excelColumns={columns}
        excelData={state.excelData}
        loading={loading}
        localStorageKey={localStorageKey}
        onMultiPermissionGrantEdit={eventHandlers.handleMultiPermissionGrantEdit()}
        onRowClick={(params) => eventHandlers.onRowClick(params.row)}
        onRowSelectionModelChange={eventHandlers.onRowSelectionModelChange}
        onSortModelChange={eventHandlers.onSortModelChange}
        rows={state.filteredRows}
        rowSelectionModel={state.rowSelectionModel}
        sortModel={state.sortModel}
        disableRowSelectionOnClick
        checkboxSelection
      />
    );
  }, [
    columns,
    eventHandlers,
    isMigratedToServerSidePagination,
    loading,
    localStorageKey,
    onPaginationModelChange,
    onSortModelChange,
    paginationModel,
    sortModel,
    state.excelData,
    state.filteredRows,
    state.rowSelectionModel,
    state.sortModel,
    totalRowCount,
  ]);

  return (
    <DataManagementLayout>
      <PageTitle data-testid={SettingsTestIds.HEADER.TITLE} className="pt-4">{title}</PageTitle>
      <FiltersRow
        CreateItemButton={
          <CreateItemButton
            entity={entity}
            openCreateForm={eventHandlers.openCreateForm}
          />
        }
        Filters={
          hasActiveStateFilter ? (
            <ActiveStateFilter
              value={state.activeStateFilter}
              onChange={eventHandlers.setActiveStateFilter}
            />
          ) : null
        }
        SearchInput={
          <FreeTextSearch
            value={state.freeTextFilter}
            onChange={eventHandlers.setFreeTextFilter}
            debounceTime={250}
            productAnalyticsFeature={Log.FEATURE.SETTINGS_TABLE}
            autoFocus
          />
        }
      />
      <DataTable>{memoizedDatagrid}</DataTable>
      {UserUtils.isPermissionGrantAllowedUser() &&
        state.showMultiPermissionGrantEdit && (
          <PermissionGrantDialog
            open={state.showMultiPermissionGrantEdit}
            loadData={utils.refreshSubjects}
            closeForm={eventHandlers.closeMultiPermissionGrantEdit}
            defaultSubjects={
              multiPermissionGrantFixedPicker === PermissionGrant.TYPE.SUBJECT
                ? state.rowSelectionModel
                : []
            }
            defaultSubjectType={multiPermissionGrantDefaultSubjectType}
            defaultEntities={
              multiPermissionGrantFixedPicker === PermissionGrant.TYPE.ENTITY
                ? state.rowSelectionModel
                : []
            }
            defaultEntityType={multiPermissionGrantDefaultEntityType}
            fixedPicker={multiPermissionGrantFixedPicker}
          />
        )}
      <FormComponent
        closeForm={eventHandlers.closeForm}
        entity={entity}
        onOpenCompany={eventHandlers.onOpenCompany}
        onOpenCostCenter={eventHandlers.onOpenCostCenter}
        onOpenOrganisationalGroup={eventHandlers.onOpenOrganisationalGroup}
        onOpenSite={eventHandlers.onOpenSite}
        onOpenUser={eventHandlers.onOpenUser}
        onOpenUserGroup={eventHandlers.onOpenUserGroup}
        onOpenVehicle={eventHandlers.onOpenVehicle}
        refreshEntities={utils.refreshEntities}
        refreshSubjects={utils.refreshSubjects}
        setState={setState}
        state={state}
      />
    </DataManagementLayout>
  );
};
