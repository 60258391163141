import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysUser } from './queryKeys';

type UserType =
  | 'buyer'
  | 'carrier'
  | 'recipient'
  | 'supplier'
  | 'on_behalf_buyer'
  | 'on_behalf_carrier'
  | 'on_behalf_recipient'
  | 'on_behalf_seller'
  | 'on_behalf_supplier';

type UserSettings = {
  language: 'german';
  position: string;
  lastName: string;
  userType: UserType;
  firstName: string;
  costCenter: UUID[];
  deviceTokens: [
    {
      token: string;
      lastUsed: string;
      createdOn: string;
    },
  ];
  licensePlates: string[];
  currentVehicles: [
    {
      lastUsed: string;
      vehicleId: UUID;
    },
  ];
  lastUsedVehicles: [
    {
      lastUsed: string;
      vehicleId: UUID;
    },
  ];
  defaultCostCenter: string;
  permittedUserTypes: UserType[];
  defaultSignatureRole: UserType;
  permittedSignatureRoles: UserType[];
};

/**
 * Fetches user settings data from the API.
 *
 * @param {string} userId - The ID of the user to fetch settings for.
 * @returns {Promise<UserSettings>} A promise that resolves with the user settings.
 * @see https://app.dev.vestigas.com/redoc#tag/Admin/operation/get_settings_for_user_admin_user__id__settings_get
 */
export const fetchUserSettings = async (userId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER.GET_SETTINGS(userId))
      .json<UserSettings>();

    return response;
  } catch (error) {
    Log.error('Error fetching user settings:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for fetching user settings.
 *
 * @param {string} userId - The ID of the user.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query result.
 */
export const useQueryUserSettings = (
  userId: UUID,
  options: Parameters<typeof useQuery>[0],
) =>
  useQuery({
    queryKey: queryKeysUser.getSettings(userId),
    queryFn: async () => fetchUserSettings(userId),
    ...options,
  });
