import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { Delete as DeleteIcon, Info as InfoIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

import { CostCenterIcon, LocationIcon } from '~/assets/icons';

import { setSelectedSites, setSelectedCostCenters } from '~/redux/filtersSlice';
import {
  replaceFilteredDeliveryNotes,
  setDeliveryNotesLoading,
} from '~/redux/deliveryNotesSlice';

import SiteService from '~/services/site.service';
import CostCenterService from '~/services/costCenter.service';
import DeliveriesService from '~/services/deliveries.service';

import Log from '~/utils/Log';
import { LightTooltip } from '~/utils/componentUtils';
import ArrayUtils from '~/utils/arrayUtils';
import { usePrevious } from '~/utils/customHooks';

import { LOADING_STATE } from '~/constants/LoadingState';
import { withErrorBoundary } from '~/ui/atoms';
import BasicPopover from '~/components/BasicPopover';
import { Spinner } from '~/components/Spinner';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';

import { initSelectableCostCentersAndSites } from './initSelectableCostCentersAndSites';

// Memoized selectors
const selectSitesAndCostCenters = createSelector(
  [(state) => state.sites, (state) => state.costCenters],
  (sitesState, costCentersState) => ({
    sites: sitesState.sites || [],
    costCenters: costCentersState.costCenters || [],
    sitesLoading: sitesState.sitesLoading,
    costCentersLoading: costCentersState.costCentersLoading,
  }),
);

const selectActiveEntities = createSelector(
  [selectSitesAndCostCenters],
  ({ sites, costCenters }) => ({
    activeSites: sites.filter(({ active }) => active),
    activeCostCenters: costCenters.filter(({ active }) => active),
  }),
);

const selectDeliveryNotesData = createSelector(
  [(state) => state.deliveryNotes],
  (deliveryNotesState) => ({
    deliveryNotes: deliveryNotesState.deliveryNotes,
    deliveryNotesVersion: deliveryNotesState.deliveryNotesVersion,
  }),
);

const selectUserinfoLoading = createSelector(
  [(state) => state.userinfo],
  (userinfoState) => userinfoState.userinfoLoading,
);

const selectFilters = createSelector(
  [(state) => state.filters],
  (filtersState) => ({
    selectedSites: filtersState.selectedSites,
    selectedCostCenters: filtersState.selectedCostCenters,
  }),
);

export const SitesCostCentersSelection = withErrorBoundary(
  memo((props) => {
    const { deliveryNotes, deliveryNotesVersion } = useSelector(
      selectDeliveryNotesData,
    );
    const { costCentersLoading, sitesLoading, costCenters, sites } =
      useSelector(selectSitesAndCostCenters);
    const { activeCostCenters, activeSites } =
      useSelector(selectActiveEntities);
    const userinfoLoading = useSelector(selectUserinfoLoading);
    const { selectedCostCenters, selectedSites } = useSelector(selectFilters);

    const [sortedSites, setSortedSites] = useState([]);
    const [sortedCostCenters, setSortedCostCenters] = useState([]);
    const [localSelectedSites, setLocalSelectedSites] = useState([]);
    const [localSelectedCostCenters, setLocalSelectedCostCenters] = useState(
      [],
    );
    const [anchorElement, setAnchorElement] = useState(null);

    const previousDeliveryNotesVersion = usePrevious(deliveryNotesVersion);

    const dispatch = useDispatch();

    const filterDeliveryNotes = useCallback(
      (selectedSites, selectedCostCenters, deliveryNotes) => {
        return deliveryNotes.filter((deliveryNote) => {
          const permittedToSiteIds = deliveryNote.permittedToSites.map(
            ({ id }) => id,
          );
          const permittedCostCenterIds = deliveryNote.permittedCostCenters.map(
            ({ id }) => id,
          );

          const correctPermittedToSite =
            ArrayUtils.getOverlappingValues(selectedSites, permittedToSiteIds)
              .length > 0;
          const correctPermittedCostCenter =
            ArrayUtils.getOverlappingValues(
              selectedCostCenters,
              permittedCostCenterIds,
            ).length > 0;

          return correctPermittedToSite || correctPermittedCostCenter;
        });
      },
      [],
    );

    useEffect(() => {
      const updateByBulkLoad =
        previousDeliveryNotesVersion !== deliveryNotesVersion;

      // If no filter is set, show all delivery notes
      if (selectedSites.length === 0 && selectedCostCenters.length === 0) {
        dispatch(
          replaceFilteredDeliveryNotes({
            deliveryNotes,
            updateByBulkLoad,
          }),
        );
        return;
      }

      // If a filter is set, show only the delivery notes that are permitted to the selected sites and cost centers
      const filteredDeliveryNotes = filterDeliveryNotes(
        selectedSites,
        selectedCostCenters,
        deliveryNotes,
      );

      dispatch(
        replaceFilteredDeliveryNotes({
          deliveryNotes: filteredDeliveryNotes,
          updateByBulkLoad,
        }),
      );
    }, [
      deliveryNotesVersion,
      selectedSites,
      selectedCostCenters,
      deliveryNotes,
      dispatch,
      filterDeliveryNotes,
      previousDeliveryNotesVersion,
    ]);

    useEffect(() => {
      SiteService.loadSites();
      CostCenterService.loadCostCenters();
    }, []);

    useEffect(() => {
      resetSelectedSites();
      resetSelectedCostCenters();
    }, [sitesLoading, costCentersLoading]);

    useEffect(() => {
      initLocalSelectedSites();
    }, [selectedSites, sortedSites]);

    useEffect(() => {
      initLocalSelectedCostCenters();
    }, [selectedCostCenters, sortedCostCenters]);

    useEffect(() => {
      initSelectableSitesCostCenters();
    }, [sites, costCenters]);

    // We have to reset the selected sites and cost centers if the user (mainly Vestigas support)
    // is switching between different accounts. Therefore, when the sites and cost centers are loaded,
    // we check if the selected sites and cost centers are still valid. If not, we reset them.
    const resetSelectedSites = useCallback(() => {
      if (sitesLoading !== LOADING_STATE.SUCCEEDED) {
        return;
      }

      const newSelectedSites = selectedSites.filter((selectedSite) =>
        sites.find((site) => site.id === selectedSite),
      );
      if (newSelectedSites.length !== selectedSites.length) {
        dispatch(setSelectedSites(newSelectedSites));
      }
    }, [sitesLoading, selectedSites, sites, dispatch]);

    const resetSelectedCostCenters = useCallback(() => {
      if (costCentersLoading !== LOADING_STATE.SUCCEEDED) {
        return;
      }

      const newSelectedCostCenters = selectedCostCenters.filter(
        (selectedCostCenter) =>
          costCenters.find(
            (costCenter) => costCenter.id === selectedCostCenter,
          ),
      );
      if (newSelectedCostCenters.length !== selectedCostCenters.length) {
        dispatch(setSelectedCostCenters(newSelectedCostCenters));
      }
    }, [costCentersLoading, selectedCostCenters, costCenters, dispatch]);

    const initLocalSelectedSites = useCallback(() => {
      const newLocalSelectedSites = [];

      for (const siteId of selectedSites) {
        const site = sortedSites.find(({ id }) => id === siteId);
        if (site) {
          newLocalSelectedSites.push(site);
        }
      }

      setLocalSelectedSites(newLocalSelectedSites);
    }, [selectedSites, sortedSites]);

    const initLocalSelectedCostCenters = useCallback(() => {
      const newLocalSelectedCostCenters = [];

      for (const costCenterId of selectedCostCenters) {
        const costCenter = sortedCostCenters.find(
          ({ id }) => id === costCenterId,
        );
        if (costCenter) {
          newLocalSelectedCostCenters.push(costCenter);
        }
      }

      setLocalSelectedCostCenters(newLocalSelectedCostCenters);
    }, [selectedCostCenters, sortedCostCenters]);

    const initSelectableSitesCostCenters = useCallback(() => {
      initSelectableCostCentersAndSites({
        activeCostCenters,
        activeSites,
        setSortedCostCenters,
        setSortedSites,
      });
    }, [activeCostCenters, activeSites]);

    const formSuccess = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        Log.info(
          'Submit current site and cost center selection',
          null,
          Log.BREADCRUMB.FORM_SUBMIT.KEY,
        );
        Log.productAnalyticsEvent(
          'Submit',
          Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
        );

        const siteIds = localSelectedSites.map(({ id }) => id);
        const costCenterIds = localSelectedCostCenters.map(({ id }) => id);

        dispatch(setSelectedSites(siteIds));
        dispatch(setSelectedCostCenters(costCenterIds));

        dispatch(setDeliveryNotesLoading(LOADING_STATE.LOADING));
        DeliveriesService.initStoredDlns(false, siteIds, costCenterIds).catch(
          () => {
            dispatch(setDeliveryNotesLoading(LOADING_STATE.FAILED));
          },
        );

        setAnchorElement(null);
      },
      [localSelectedSites, localSelectedCostCenters, dispatch],
    );

    const formAbort = useCallback(() => {
      Log.productAnalyticsEvent(
        'Abort',
        Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
      );

      initLocalSelectedSites();
      initLocalSelectedCostCenters();

      setAnchorElement(null);
    }, [initLocalSelectedSites, initLocalSelectedCostCenters]);

    const handleChangeSites = useCallback(
      (data) => {
        Log.info(
          'Change form value of sites',
          { from: localSelectedSites, to: data },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change sites',
          Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
        );

        setLocalSelectedSites(data);

        // Determine the sites that were added
        const newSites = ArrayUtils.subtract(
          data.map(({ id }) => id),
          localSelectedSites.map(({ id }) => id),
        );

        // Determine the cost centers that belong to the new sites. Subtract the already selected cost centers.
        const newCostCenters = ArrayUtils.subtract(
          newSites.flatMap((siteId) =>
            sortedSites
              .find(({ id }) => id === siteId)
              .costCenters.map(({ id }) => id),
          ),
          localSelectedCostCenters.map(({ id }) => id),
        );

        // Add the new cost centers to the selected cost centers.
        setLocalSelectedCostCenters([
          ...localSelectedCostCenters,
          ...sortedCostCenters.filter((costCenter) =>
            newCostCenters.includes(costCenter.id),
          ),
        ]);
      },
      [
        localSelectedSites,
        localSelectedCostCenters,
        sortedSites,
        sortedCostCenters,
      ],
    );

    const handleChangeCostCenters = useCallback(
      (data) => {
        Log.info(
          'Change form value of cost centers',
          { from: localSelectedCostCenters, to: data },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change cost centers',
          Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
        );

        setLocalSelectedCostCenters(data);
      },
      [localSelectedCostCenters],
    );

    const handleDelete = useCallback(() => {
      Log.info(
        'Delete current site and cost center selection',
        null,
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Delete',
        Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
      );

      setLocalSelectedSites([]);
      setLocalSelectedCostCenters([]);
    }, []);

    const getSelectedSitesString = useMemo(() => {
      if (selectedSites.length === 0) {
        return 'Kein Standort ausgewählt';
      }

      if (selectedSites.length === 1) {
        return sites.find((site) => site.id === selectedSites[0])?.name ?? '';
      }

      return selectedSites.length + ' Standorte ausgewählt';
    }, [selectedSites, sites]);

    const getSelectedCostCentersString = useMemo(() => {
      if (selectedCostCenters.length === 0) {
        return 'Keine Kostenstelle ausgewählt';
      }

      if (selectedCostCenters.length === 1) {
        return (
          costCenters.find(
            (costCenter) => costCenter.id === selectedCostCenters[0],
          )?.name ?? ''
        );
      }

      return selectedCostCenters.length + ' Kostenstellen ausgewählt';
    }, [selectedCostCenters, costCenters]);

    const getComponent = useCallback(() => {
      if (userinfoLoading === LOADING_STATE.LOADING) {
        return <Spinner title="Laden..." />;
      }

      if (selectedSites.length === 0 && selectedCostCenters.length === 0) {
        return (
          <LightTooltip title="Aktuellen Standort und Kostenstelle auswählen. Lieferungen, Statistiken und Rechnungen werden nur für diese angezeigt.">
            <Button
              variant="outlined"
              onClick={(event) => setAnchorElement(event.currentTarget)}
            >
              Aktuellen Standort auswählen
            </Button>
          </LightTooltip>
        );
      }

      return (
        <div
          className="bold text-primary500 cursor-pointer underline"
          onClick={(event) => setAnchorElement(event.currentTarget)}
        >
          <div className="flex-s-c gap-8px">
            <LocationIcon className="text-primary500 icon-15px" />
            <div className="max-w-400px text-overflow">
              {getSelectedSitesString}
            </div>
          </div>
          <div className="flex-s-c gap-8px">
            <CostCenterIcon className="text-primary500 icon-15px" />
            <div className="max-w-400px text-overflow">
              {getSelectedCostCentersString()}
            </div>
          </div>
        </div>
      );
    }, [
      getSelectedSitesString,
      getSelectedCostCentersString,
      userinfoLoading,
      selectedSites,
      selectedCostCenters,
    ]);

    const getDeleteIconButton = () => {
      return (
        <LightTooltip title="Standorte und Kostenstellen zurücksetzen.">
          <IconButton onClick={handleDelete} size="large">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </LightTooltip>
      );
    };

    return (
      <div className={props.className}>
        {getComponent()}
        <BasicPopover
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          onClose={formAbort}
          formSuccess={formSuccess}
          customDeleteButton={getDeleteIconButton()}
        >
          <div className="w-500px flexdir-column gap-20px flex">
            <GenericMultiPicker
              textfieldLabel="Standorte"
              pickedItems={localSelectedSites}
              allItems={sortedSites}
              callbackPickedItems={handleChangeSites}
              loading={sitesLoading}
            />
            <GenericMultiPicker
              textfieldLabel="Kostenstellen"
              pickedItems={localSelectedCostCenters}
              allItems={sortedCostCenters}
              callbackPickedItems={handleChangeCostCenters}
              loading={costCentersLoading}
            />
            <div className="flex-s-c gap-8px">
              <InfoIcon fontSize="small" className="text-primary500" />
              Es werden nur Daten für diese Auswahl angezeigt.
            </div>
          </div>
        </BasicPopover>
      </div>
    );
  }),
  null,
);

SitesCostCentersSelection.displayName = 'SitesCostCentersSelection';
