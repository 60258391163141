import React from 'react';

import { PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { UserSignatureIcon } from '~/assets/icons';

import { LightTooltip, LightTooltipWide } from '~/utils/componentUtils';
import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';
import ExportService from '~/services/export.service';
import { RequestSignatureForm } from '../../deliveries/deliveryNoteActions/RequestSignatureForm';
import ArrayUtils from '~/utils/arrayUtils';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';

class InvoiceCheckButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      permittedUserIds: [],
      unsignedDeliveryNoteIds: [],
      requestDeliveryNoteSignatureFormOpen: false,
    };
  }

  componentDidMount() {
    this.initPermittedUserIds();
    this.initUnsignedDeliveryNoteIds();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.referencedDeliveryNotes) !==
      JSON.stringify(this.props.referencedDeliveryNotes)
    ) {
      this.initPermittedUserIds();
    }

    if (
      JSON.stringify(this.props.category.errorChecks) !==
      JSON.stringify(this.props.category.errorChecks)
    ) {
      this.initUnsignedDeliveryNoteIds();
    }
  }

  initPermittedUserIds() {
    const permittedUserIds = [];

    for (const deliveryNote of this.props.referencedDeliveryNotes) {
      for (const user of deliveryNote.permittedUsers) {
        permittedUserIds.push(user.id);
      }
    }

    this.setState({
      permittedUserIds: ArrayUtils.removeDuplicates(permittedUserIds),
    });
  }

  initUnsignedDeliveryNoteIds() {
    this.setState({
      unsignedDeliveryNoteIds: InvoiceCheckCategory.getUnsignedDeliveryNoteIds(
        this.props.category.errorChecks,
      ),
    });
  }

  onExportPdfs = (e) => {
    e.stopPropagation();
    ExportService.exportDeliveryNotes(
      this.props.referencedDeliveryNotes.map((deliveryNote) => deliveryNote.id),
      ExportService.DOWNLOAD_OPTION.ZIP,
    );
  };
  onRequestSignatures = (e) => {
    e.stopPropagation();

    Log.info('Open request signature form');
    Log.productAnalyticsEvent(
      'Open request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    this.setState({
      requestDeliveryNoteSignatureFormOpen: true,
    });
  };

  getPDFExportButton() {
    if (
      this.props.category.key !== InvoiceCheckCategory.CATEGORIES.DLN_CHECK.KEY
    ) {
      return null;
    }

    if (this.props.referencedDeliveryNotes.length === 0) {
      return null;
    }

    return (
      <LightTooltip title="PDF Download der vorliegenden Lieferungen.">
        <Button
          variant="outlined"
          color="primary"
          onClick={this.onExportPdfs}
          className="h-30px"
          startIcon={<PictureAsPdfIcon className="ml-8px" />}
        />
      </LightTooltip>
    );
  }

  getRequestSignaturesButton() {
    if (
      this.props.category.key !==
      InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY
    ) {
      return null;
    }

    if (this.props.category.status !== InvoiceCheckResult.STATUS.WARNING) {
      return null;
    }

    if (this.state.unsignedDeliveryNoteIds.length === 0) {
      return null;
    }

    return (
      <>
        <LightTooltipWide title="Signaturen für die nicht signierten Lieferungen anfordern.">
          <Button
            variant="outlined"
            color="primary"
            onClick={this.onRequestSignatures}
            className="h-30px"
            startIcon={<UserSignatureIcon className="icon-small ml-8px" />}
          />
        </LightTooltipWide>
        {this.state.requestDeliveryNoteSignatureFormOpen ? (
          <RequestSignatureForm
            // ?? operator is necessary due to the temporary backend bug that invoice check results where missing delivery notes ids
            // so that corresponding delivery notes couldn't be retrieved from the store
            permittedUserIds={this.state.permittedUserIds ?? []}
            deliveryNoteIds={this.state.unsignedDeliveryNoteIds}
            open={this.state.requestDeliveryNoteSignatureFormOpen}
            closeForm={() =>
              this.setState({ requestDeliveryNoteSignatureFormOpen: false })
            }
          />
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        {this.getPDFExportButton()}
        {this.getRequestSignaturesButton()}
      </>
    );
  }
}

export default withErrorBoundary(InvoiceCheckButtons, null);
