import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { promiseHandler } from '~/utils/promiseHandler';

import Log from '~/utils/Log';

export const updateUser = async (userId, userData, setState) => {
  const [, error] = await promiseHandler(
    UserService.updateUser(userId, userData),
  );

  if (error) {
    ToastService.httpError(
      [ToastService.MESSAGE.USER_UPDATE_FAILED],
      error.response,
    );
    Log.error(`Failed to update user. id: ${userId}`, error);
    Log.productAnalyticsEvent(
      'Failed to update',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
    setState((prevState) => ({
      ...prevState,
      submittingForm: false,
    }));

    return [null, true];
  }

  return [true, null];
};
