import ValueGroup from '../deliveries/ValueGroup';
import iso3311a2 from 'iso-3166-1-alpha-2';
import MapperService from '~/services/mapper.service';

export default class Address {
  constructor(address) {
    this.streetName = address?.street_name ?? address?.streetName ?? '';
    this.buildingNumber =
      address?.building_number ?? address?.buildingNumber ?? '';
    this.postCode = address?.post_code ?? address?.postCode ?? '';
    this.city = address?.city ?? '';
    this.country = address?.country ?? '';
  }

  getConcatenatedAddress() {
    return Address.getConcatenatedAddress(this);
  }

  static getConcatenatedAddress(address) {
    const streetName = ValueGroup.getCurrentValue(address?.streetName);
    const buildingNumber = ValueGroup.getCurrentValue(address?.buildingNumber);
    const postCode = ValueGroup.getCurrentValue(address?.postCode);
    const city = ValueGroup.getCurrentValue(address?.city);
    const country = ValueGroup.getCurrentValue(address?.country);

    return (
      (streetName ?? '') +
      (streetName && buildingNumber ? ' ' : '') +
      (buildingNumber ?? '') +
      (streetName || buildingNumber ? ', ' : '') +
      (postCode ?? '') +
      (postCode && city ? ' ' : '') +
      (city ?? '') +
      (postCode && city && country ? ', ' + country : '')
    );
  }

  getBackendFormat() {
    return {
      street_name: this.streetName,
      building_number: this.buildingNumber,
      post_code: this.postCode,
      city: this.city,
      country: this.country,
    };
  }

  static getCountryCodes() {
    return iso3311a2.getData();
  }

  static getCountryCodeOptions() {
    this.countryCodes = Address.getCountryCodes();
    return Object.keys(this.countryCodes).map((code) => {
      const newCountry = {
        id: code,
        name: code + ': ' + this.countryCodes[code],
      };

      return MapperService.addSearchStringWithValues(newCountry, [
        code,
        this.countryCodes[code],
      ]);
    });
  }

  static getDifferentValues(addressA, addressB) {
    const differentValues = [];

    if (addressA?.streetName !== addressB?.streetName) {
      differentValues.push('Straße');
    }

    if (addressA?.buildingNumber !== addressB?.buildingNumber) {
      differentValues.push('Hausnummer');
    }

    if (addressA?.postCode !== addressB?.postCode) {
      differentValues.push('PLZ');
    }

    if (addressA?.city !== addressB?.city) {
      differentValues.push('Ort');
    }

    if (addressA?.country !== addressB?.country) {
      differentValues.push('Land');
    }

    return differentValues;
  }

  static DEFAULT_COUNTRY_CODE = {
    DE: 'DE',
  };
}
