import ArrayUtils from '~/utils/arrayUtils';

export default class PushNotificationSettings {
  constructor(pushNotificationSettings) {
    this.dlnCreated = {
      all:
        pushNotificationSettings?.dln_created?.all ??
        pushNotificationSettings?.dlnCreated?.all ??
        false,
      siteIds:
        pushNotificationSettings?.dln_created?.sites ??
        pushNotificationSettings?.dlnCreated?.sites ??
        [],
      costCenterIds:
        pushNotificationSettings?.dln_created?.acc_refs ??
        pushNotificationSettings?.dlnCreated?.accRefs ??
        [],
    };

    this.dlnSignReminder = {
      all:
        pushNotificationSettings?.dln_sign_reminder?.all ??
        pushNotificationSettings?.dlnSignReminder?.all ??
        false,
      siteIds:
        pushNotificationSettings?.dln_sign_reminder?.sites ??
        pushNotificationSettings?.dlnSignReminder?.sites ??
        [],
      costCenterIds:
        pushNotificationSettings?.dln_sign_reminder?.acc_refs ??
        pushNotificationSettings?.dlnSignReminder?.accRefs ??
        [],
    };
  }

  getBackendFormat() {
    return {
      dln_created: {
        all: this.dlnCreated.all,
        sites: this.dlnCreated.siteIds,
        acc_refs: this.dlnCreated.costCenterIds,
      },
      dln_sign_reminder: {
        all: this.dlnSignReminder.all,
        sites: this.dlnSignReminder.siteIds,
        acc_refs: this.dlnSignReminder.costCenterIds,
      },
    };
  }

  static getDifferentValues(
    pushNotificationSettingsA,
    pushNotificationSettingsB,
  ) {
    const differentValues = [];

    if (
      pushNotificationSettingsA?.dlnCreated?.all !==
      pushNotificationSettingsB?.dlnCreated?.all
    ) {
      differentValues.push('Lieferung erstellt - Alle');
    }

    if (
      pushNotificationSettingsA?.dlnSignReminder?.all !==
      pushNotificationSettingsB?.dlnSignReminder?.all
    ) {
      differentValues.push('Am Ende des Tages - Alle');
    }

    const differentDlnCreatedSiteIds = ArrayUtils.getDifference(
      pushNotificationSettingsA?.dlnCreated?.siteIds,
      pushNotificationSettingsB?.dlnCreated?.siteIds,
    );
    if (
      differentDlnCreatedSiteIds[0].length > 0 ||
      differentDlnCreatedSiteIds[1].length > 0
    ) {
      differentValues.push('Lieferung erstellt - Standorte');
    }

    const differentDlnCreatedCostCenterIds = ArrayUtils.getDifference(
      pushNotificationSettingsA?.dlnCreated?.costCenterIds,
      pushNotificationSettingsB?.dlnCreated?.costCenterIds,
    );
    if (
      differentDlnCreatedCostCenterIds[0].length > 0 ||
      differentDlnCreatedCostCenterIds[1].length > 0
    ) {
      differentValues.push('Lieferung erstellt - Kostenstellen');
    }

    const differentDlnSignReminderSiteIds = ArrayUtils.getDifference(
      pushNotificationSettingsA?.dlnSignReminder?.siteIds,
      pushNotificationSettingsB?.dlnSignReminder?.siteIds,
    );
    if (
      differentDlnSignReminderSiteIds[0].length > 0 ||
      differentDlnSignReminderSiteIds[1].length > 0
    ) {
      differentValues.push('Am Ende des Tages - Standorte');
    }

    const differentDlnSignReminderCostCenterIds = ArrayUtils.getDifference(
      pushNotificationSettingsA?.dlnSignReminder?.costCenterIds,
      pushNotificationSettingsB?.dlnSignReminder?.costCenterIds,
    );
    if (
      differentDlnSignReminderCostCenterIds[0].length > 0 ||
      differentDlnSignReminderCostCenterIds[1].length > 0
    ) {
      differentValues.push('Am Ende des Tages - Kostenstellen');
    }

    return differentValues;
  }
}
