import { useState, useEffect, useRef, useCallback } from 'react';

import { Check as CheckIcon } from '@mui/icons-material';
import { FormHelperText, Chip, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { LOADING_STATE } from '~/constants/LoadingState';
import { Spinner } from '~/components/Spinner';
import ArrayUtils from '~/utils/arrayUtils';

import { ListboxComponent } from './ListboxComponent';

const filter = createFilterOptions();

const GenericMultiPicker = ({
  allItems: allItemsProps = [],
  callbackPickedItems,
  disabled,
  errorText,
  fieldName,
  freeSolo,
  loading,
  onNewOptionCreate,
  pickedItemIds = [],
  pickedItems: pickedItemsProps,
  prioritizedItems,
  sortItems,
  sortItemsByKey,
  startAdornment,
  textfieldLabel,
}) => {
  const [open, setOpen] = useState(false);
  const [lastOpenedDateTime, setLastOpenedDateTime] = useState(null);
  const [pickedItems, setPickedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const textFieldRef = useRef();

  const getPickedItems = useCallback(() => {
    if (pickedItemsProps) {
      return pickedItemsProps;
    }

    const customPickedItems = [];

    const pickedItemsFromAllItems = pickedItemIds
      ?.map((pickedItemId) =>
        allItemsProps.find((item) => item.id === pickedItemId),
      )
      .filter(Boolean);

    if (onNewOptionCreate) {
      for (const pickedItemId of pickedItemIds) {
        if (allItemsProps.every((item) => item.id !== pickedItemId)) {
          customPickedItems.push(
            onNewOptionCreate({
              inputValue: pickedItemId,
            }),
          );
        }
      }
    }

    return [...pickedItemsFromAllItems, ...customPickedItems];
  }, [onNewOptionCreate, allItemsProps, pickedItemIds, pickedItemsProps]);

  const getAllItems = useCallback(() => {
    if (!allItemsProps) {
      return [];
    }

    if (!prioritizedItems) {
      return allItemsProps;
    }

    return ArrayUtils.pushToFrontByKeyValues(
      allItemsProps,
      prioritizedItems,
      'id',
    );
  }, [allItemsProps, prioritizedItems]);

  useEffect(() => {
    setAllItems(getAllItems());
  }, []);

  useEffect(() => {
    setPickedItems(getPickedItems());
  }, [pickedItemsProps, pickedItemIds, allItemsProps, getPickedItems]);

  useEffect(() => {
    setAllItems(getAllItems());
  }, [getAllItems, allItemsProps, prioritizedItems]);

  const renderOption = (
    { fieldName, key, prioritizedItems, ...rest },
    option,
    { selected },
  ) => {
    const renderedOption = option.nameComponent ?? option[fieldName ?? 'name'];

    if (selected) {
      return (
        <li key={key} {...rest}>
          <div className="text-primary500 flex w-full items-center justify-between py-1">
            <div>{renderedOption}</div>
            <CheckIcon className="text-primary500" fontSize="small" />
          </div>
        </li>
      );
    }

    if (
      prioritizedItems === undefined ||
      prioritizedItems.includes(option.id)
    ) {
      return (
        <li key={key} {...rest}>
          {renderedOption}
        </li>
      );
    }

    return (
      <li key={key} {...rest}>
        <span className="text-gray-400">{renderedOption}</span>
      </li>
    );
  };

  const getLabel = () => {
    if (loading === LOADING_STATE.LOADING) {
      const loadingLabel = textfieldLabel
        ? 'Laden... (' + textfieldLabel + ')'
        : 'Laden...';
      return <Spinner title={loadingLabel} />;
    }

    if (textfieldLabel) {
      return textfieldLabel;
    }

    return 'Suchen';
  };

  const renderTags = (value, getTagProps) => {
    return value.map((option, index) => {
      const { key, ...rest } = getTagProps({ index });

      return <Chip key={key} label={option[fieldName ?? 'name']} {...rest} />;
    });
  };

  const onOpen = () => {
    setOpen(true);
    setLastOpenedDateTime(new Date());
  };

  const onClose = () => {
    if (Date.now() - lastOpenedDateTime < 300) {
      textFieldRef.current.focus();
      return;
    }

    setOpen(false);
    setLastOpenedDateTime(null);
  };

  const handleChange = (value) => {
    const newOption = value.find((value) => value.isNewOption);

    if (onNewOptionCreate && newOption) {
      const newCreatedOption = onNewOptionCreate(newOption);
      const updatedList = value.filter((value) => !value.isNewOption);

      if (newCreatedOption) {
        updatedList.push(newCreatedOption);
      }

      setPickedItems(updatedList);
      callbackPickedItems(updatedList);

      return;
    }

    setPickedItems(value);
    callbackPickedItems(value);
  };

  const getOptions = () => {
    if (!sortItems) {
      return allItems;
    }

    if (!sortItemsByKey) {
      return allItems.sort();
    }

    return ArrayUtils.sortByKey(allItems, sortItemsByKey);
  };

  return (
    <div className="relative w-full">
      <Autocomplete
        ListboxComponent={ListboxComponent}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        disabled={disabled}
        multiple
        options={getOptions()}
        getOptionLabel={(option) =>
          option.searchString ?? option[fieldName ?? 'name']
        }
        value={pickedItems}
        noOptionsText="Keine Optionen"
        freeSolo={freeSolo}
        autoSelect={freeSolo}
        clearOnBlur={freeSolo}
        onChange={(event, value) => handleChange(value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionDisabled={(option) => option.disabled}
        renderOption={renderOption}
        renderTags={renderTags}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const fieldKey = fieldName || 'name';
          const isExisting = options.some(
            (option) => inputValue === option[fieldKey],
          );

          if (inputValue !== '' && !isExisting && onNewOptionCreate) {
            filtered.push({
              inputValue: inputValue.trim(),
              isNewOption: true,
              [fieldKey]: `Hinzufügen: ${inputValue}`,
            });
          }

          return filtered;
        }}
        renderInput={(params) => {
          params.inputProps.autoComplete = 'new-password'; // prevent autocomplete by browser
          return (
            <TextField
              {...params}
              inputRef={textFieldRef}
              autoFocus
              variant="standard"
              label={getLabel()}
              placeholder={
                disabled || pickedItems.length === allItemsProps?.length
                  ? ''
                  : 'Weitere'
              }
              autoComplete="off"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {startAdornment}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
      {loading === LOADING_STATE.FAILED ? (
        <FormHelperText className="text-mui-error-red absolute">
          {errorText ?? 'Daten konnten nicht geladen werden.'}
        </FormHelperText>
      ) : null}
    </div>
  );
};

export default GenericMultiPicker;
