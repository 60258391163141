import { PROMISE_STATUS } from '~/constants/AsyncOperationConsts';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import CompanyService from '~/services/company.service';
import CostCenterService from '~/services/costCenter.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import SiteService from '~/services/site.service';
import UserGroupService from '~/services/userGroup.service';
import UserService from '~/services/user.service';
import VehicleService from '~/services/vehicle.service';

import PermissionGrant, {
  ENTITY_TYPES,
} from '~/models/masterdata/PermissionGrant';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

import { PermissionGrantTableBase } from './PermissionGrantTableBase';

const getEntityName = async (entityType, entityId) => {
  switch (entityType) {
    case PermissionGrant.ENTITY_TYPE.USER.KEY: {
      const [user, error] = await promiseHandler(
        UserService.getUserById(entityId),
      );

      if (error) {
        throw error;
      }

      return user.email;
    }

    case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
      const [site, error2] = await promiseHandler(
        SiteService.getSiteById(entityId),
      );

      if (error2) {
        throw error2;
      }

      return site.name;
    }

    case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
      const [costCenter, error3] = await promiseHandler(
        CostCenterService.getCostCenterById(entityId),
      );

      if (error3) {
        throw error3;
      }

      return costCenter.name;
    }

    case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
      const [vehicle, error4] = await promiseHandler(
        VehicleService.getVehicleById(entityId),
      );

      if (error4) {
        throw error4;
      }

      return vehicle.licensePlate.name;
    }

    case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
      const [company, error5] = await promiseHandler(
        CompanyService.getCompanyById(entityId),
      );

      if (error5) {
        throw error5;
      }

      return company.name;
    }

    case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
      const [organisationalGroup, error6] = await promiseHandler(
        OrganisationalGroupService.getOrganisationalGroupById(entityId),
      );

      if (error6) {
        throw error6;
      }

      return organisationalGroup.name;
    }

    case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
      const [userGroup, error7] = await promiseHandler(
        UserGroupService.getUserGroupById(entityId),
      );

      if (error7) {
        throw error7;
      }

      return userGroup.name;
    }

    default: {
      Log.error(
        null,
        new EnumValueNotFoundException(`Invalid entity type: ${entityType}`),
      );

      return '...';
    }
  }
};

const initRows = async (permissionGrantsOn, setState) => {
  const promises = permissionGrantsOn.map(({ entityId, entityType }) =>
    getEntityName(entityType, entityId),
  );

  const [results, error] = await promiseHandler(Promise.allSettled(promises));

  if (error) {
    Log.error('Failed to load subjects.', error);
    Log.productAnalyticsEvent(
      'Failed to load subjects',
      Log.FEATURE.PERMISSIONS,
      Log.TYPE.ERROR,
    );

    return;
  }

  const newRows = [];
  for (const [index, result] of results.entries()) {
    const { entityId, entityType, id, permissions } = permissionGrantsOn[index];

    newRows.push({
      defaultRole: permissions.getDefaultRoleName(),
      entityId,
      entityName:
        result.status === PROMISE_STATUS.FULFILLED ? result.value : '...',
      entityType: ENTITY_TYPES[entityType],
      id,
      permissions: {
        permissions,
        permissionGrantId: id,
      },
    });
  }

  setState((prevState) => ({
    ...prevState,
    rows: newRows,
  }));
};

export const PermissionGrantSubjectTable = ({
  defaultSubjects,
  defaultSubjectType,
  fixedPicker,
  loading,
  permissionGrantsOn,
  refreshData,
  title,
}) => (
  <PermissionGrantTableBase
    columnVisibilityModel={{ entityId: UserUtils.isVestigasAccount() }}
    defaultSubjects={defaultSubjects}
    defaultSubjectType={defaultSubjectType}
    fixedPicker={fixedPicker}
    initRows={initRows}
    loading={loading}
    permissionGrants={permissionGrantsOn}
    refreshData={refreshData}
    title={title}
    type={PermissionGrant.TYPE.SUBJECT}
  />
);

PermissionGrantSubjectTable.displayName = 'PermissionGrantSubjectTable';
