import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';

import { Grid } from '@mui/material';

import SiteService from '~/services/site.service';
import { SentryRoute } from '~/services/thirdParty.service';
import CostCenterService from '~/services/costCenter.service';
import VehicleService from '~/services/vehicle.service';
import CustomerSubscriptionService from '~/services/customerSubscription.service';
import UserService from '~/services/user.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import UserGroupService from '~/services/userGroup.service';
import CompanyService from '~/services/company.service';
import SignatureFieldService from '~/services/signatureField.service';
import ArticleMasterService from '~/services/articleMaster.service';
import InvoicesService from '~/services/invoices.service';
import DataSubscriptionService from '~/services/dataSubscription.service';
import PdfSettingsService from '~/services/pdfsettings.service';

import { setPageTitle } from '~/redux/menuSlice';

import { ROUTE } from '~/constants/Route';

import { withErrorBoundary } from '~/ui/atoms';

import UserUtils from '~/utils/userUtils';

import { UnauthorizedPage } from '../menu/UnauthorizedPage';

import { SettingsNav } from './SettingsNav';
import { OrganizationalUnitsTable } from './masterData/organizationalUnit/OrganizationalUnitsTable';
import { SiteTable } from './masterData/site/SiteTable';
import { UsersTable } from './masterData/users/UsersTable';
import { VehiclesTable } from './masterData/vehicle/VehiclesTable';
import CompanyTable from './masterData/company/CompanyTable';
import CostCenterTable from './masterData/costCenter/CostCenterTable';
import CustomerSubscriptionTable from './admin/customerSubscription/CustomerSubscriptionTable';
import DataProtection from './dataProtection/DataProtection';
import Demo from './Demo';
import ProfileSettings from './profile/ProfileSettings';
import SupportSettings from './support/SupportSettings';

import PdfSettings from './admin/pdf/PdfSettings';
import Impressum from './Impressum';
import UserGroupTable from './masterData/userGroup/UserGroupTable';
import MasterData from './masterData/MasterData';
import CustomFieldTable from './admin/customField/CustomFieldTable';
import SignatureFieldTable from './admin/signatureField/SignatureFieldTable';
import ArticleTable from './admin/articleMaster/ArticleTable';
import CategoryTable from './admin/articleMaster/CategoryTable';
import InvoiceCheckIgnoredArticleTable from './admin/invoiceCheckIgnoredArticle/InvoiceCheckIgnoredArticleTable';
import DataSubscriptions from './admin/dataSubscriptions/DataSubscriptions';

const SettingsComponent = () => {
  const userinfo = useSelector((state) => state.userinfo);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    UserService.loadUsers();
    SiteService.loadSites();
    CostCenterService.loadCostCenters();
    VehicleService.loadVehicles();
    CompanyService.loadCompanies();
    OrganisationalGroupService.loadOrganisationalGroups();
    UserGroupService.loadUserGroups();
    CustomerSubscriptionService.loadCustomerSubscriptions();

    if (UserUtils.isVestigasAccount()) {
      SignatureFieldService.loadSignatureFields();
      ArticleMasterService.loadArticles(
        userinfo.userinfo.company?.companyAccount,
        userinfo.userinfo.company?.id,
      );
      ArticleMasterService.loadCategories(
        userinfo.userinfo.company?.companyAccount,
        userinfo.userinfo.company?.id,
      );
      PdfSettingsService.loadPdfTemplates();
      InvoicesService.loadInvoiceCheckIgnoredArticles();
    }

    if (UserUtils.isVestigasSupportAccount()) {
      DataSubscriptionService.loadContactPoints();
      DataSubscriptionService.loadDataSubscriptions();
      DataSubscriptionService.loadNotificationPolicies();
    }

    dispatch(setPageTitle('Einstellungen'));

    document.title = 'VESTIGAS - Einstellungen';
  }, [dispatch, userinfo.userinfo.company]);

  return (
    <div className="main-padding">
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} lg={3}>
          <SettingsNav />
        </Grid>
        <Grid item xs={12} lg={9}>
          {UserService.userIsAuthorizedForPage(
            userinfo.userPermissions,
            location.pathname,
          ) ? (
            <Switch>
              <SentryRoute
                exact
                path={ROUTE.SETTINGS.ROUTE}
                component={ProfileSettings}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_PROFILE.ROUTE}
                component={ProfileSettings}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_SUPPORT.ROUTE}
                component={SupportSettings}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_COMPANY.ROUTE}
                component={CompanyTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_MASTERDATA.ROUTE}
                component={MasterData}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_USER.ROUTE}
                component={UsersTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_SITE.ROUTE}
                component={SiteTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_COST_CENTER.ROUTE}
                component={CostCenterTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_VEHICLE.ROUTE}
                component={VehiclesTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_ORGANISATIONAL_GROUP.ROUTE}
                component={OrganizationalUnitsTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_USER_GROUP.ROUTE}
                component={UserGroupTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_DATA_SUBSCRIPTIONS.ROUTE}
                component={DataSubscriptions}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_CUSTOM_FIELDS.ROUTE}
                component={CustomFieldTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_SIGNATURE_FIELDS.ROUTE}
                component={SignatureFieldTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_ARTICLES.ROUTE}
                component={ArticleTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_CATEGORIES.ROUTE}
                component={CategoryTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_CUSTOMER_SUBSCRIPTIONS.ROUTE}
                component={CustomerSubscriptionTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_PDF.ROUTE}
                component={PdfSettings}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_INVOICE_CHECK_IGNORED_ARTICLES.ROUTE}
                component={InvoiceCheckIgnoredArticleTable}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_DEMO.ROUTE}
                component={Demo}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_DATA_PROTECTION.ROUTE}
                component={DataProtection}
              />
              <SentryRoute
                exact
                path={ROUTE.SETTINGS_IMPRESSUM.ROUTE}
                component={Impressum}
              />
            </Switch>
          ) : (
            <UnauthorizedPage />
          )}
        </Grid>
      </Grid>
      <div
        className="min-h-2rem"
        /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
        /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
        /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
      />
    </div>
  );
};

export const Settings = withErrorBoundary(
  SettingsComponent,
  'Einstellungen konnten nicht geladen werden.',
);
