import React from 'react';

import { Grid, TextField } from '@mui/material';

import { connect } from 'react-redux';
import BasicForm from '~/components/BasicForm';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import UserGroup from '~/models/masterdata/UserGroup';
import ToastService from '~/services/toast.service';
import { promiseHandler } from '~/utils/promiseHandler';
import UserGroupService from '~/services/userGroup.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import UserUtils from '~/utils/userUtils';
import FunctionUtils from '~/utils/functionUtils';
import ObjectUtils from '~/utils/objectUtils';

import { OrganisationalGroupPaths } from '../paths/OrganisationalGroupPaths';
import UserGroupPaths from '../paths/UserGroupPaths';
import { PermissionGrantSubjectTable } from '../permissionGrant/PermissionsTable';

const mapStateToProps = (state) => ({
  userGroups: state.userGroups,
});
const mapDispatchToProps = () => ({});

class UserGroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submittingForm: false,
      deletingForm: false,
      userGroup: new UserGroup(),
      userGroupLoading: LOADING_STATE.NOT_LOADED,
    };
  }

  componentDidMount() {
    this.resetForm(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.userGroup) !==
      JSON.stringify(this.props.userGroup)
    ) {
      this.resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          this.props.userGroup,
          prevProps.userGroup,
          'permissionGrantsOn',
        ),
      );
    }
  }

  resetForm(resetGeneralUserGroupInformation) {
    if (!resetGeneralUserGroupInformation) {
      // Only reset the granted permissions because this is the only thing that has been changed.
      const newUserGroup = cloneDeep(this.state.userGroup);
      newUserGroup.permissionGrantsOn = this.props.userGroup
        ? this.props.userGroup.permissionGrantsOn
        : new UserGroup().permissionGrantsOn;

      this.setState({
        userGroup: newUserGroup,
      });

      return;
    }

    this.setState({
      userGroup: this.props.userGroup ?? new UserGroup(),
    });
  }

  renderForCreate = () => {
    return this.props.type === 'create';
  };
  formSuccess = async (event) => {
    event.preventDefault();

    if (this.state.userGroup.organisationalGroups.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Organisations-Gruppe aus, zu der diese Benutzer-Gruppe gehört.',
      ]);
      Log.productAnalyticsEvent(
        'Missing organisational group',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.userGroup.name,
      users: this.state.userGroup.users,
      org_units: this.state.userGroup.organisationalGroups,
      parent_user_groups: this.state.userGroup.parentUserGroups,
      member_user_groups: this.state.userGroup.childUserGroups,
    };

    if (this.renderForCreate()) {
      body.org_units = this.state.userGroup.organisationalGroups;
    }

    Log.info('Submit user group form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.USER_GROUP);

    if (this.renderForCreate()) {
      const [userGroupId, error] = await promiseHandler(
        UserGroupService.createUserGroup(body),
      );

      if (error) {
        ToastService.httpError(
          ['Benutzer-Gruppe konnte nicht angelegt werden.'],
          error.response,
        );
        Log.error('Failed to create user group.', error);
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.USER_GROUP,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        UserGroupService.updateUserGroup(this.props.userGroup.id, {
          name: body.name,
        }),
      );

      const [response1, error1] = await promiseHandler(
        OrganisationalGroupService.updateParentOrganisationalGroups(
          this.props.userGroup.id,
          PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY,
          this.props.userGroup.organisationalGroups,
          this.state.userGroup.organisationalGroups,
        ),
      );
      const [response2, error2] = await promiseHandler(
        UserGroupService.updateEntities(
          this.props.userGroup.id,
          PermissionGrant.SUBJECT_TYPE.USER.KEY,
          this.props.userGroup.users,
          this.state.userGroup.users,
        ),
      );
      // update child user groups
      const [response3, error3] = await promiseHandler(
        UserGroupService.updateEntities(
          this.props.userGroup.id,
          PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY,
          this.props.userGroup.childUserGroups,
          this.state.userGroup.childUserGroups,
        ),
      );
      // update parent user groups
      const [response4, error4] = await promiseHandler(
        UserGroupService.updateParentUserGroups(
          this.props.userGroup.id,
          PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY,
          this.props.userGroup.parentUserGroups,
          this.state.userGroup.parentUserGroups,
        ),
      );

      if (error || error1 || error2 || error3 || error4) {
        ToastService.error([
          'Benutzer-Gruppe konnte nicht vollständig aktualisiert werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.USER_GROUP,
          Log.TYPE.ERROR,
        );
        Log.error(
          'Failed to update user group.',
          error ?? error1 ?? error2 ?? error3 ?? error4,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm(true);
    await UserGroupService.refreshUserGroups();
    this.props.onRefreshEntities();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.USER_GROUP);
    this.props.closeForm();
    this.resetForm(true);
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete user group',
      { id: this.props.userGroup.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.USER_GROUP);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      UserGroupService.deleteUserGroup(this.props.userGroup.id),
    );

    if (error) {
      ToastService.httpError(
        ['Benutzer-Gruppe konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete user group.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm(true);
    await UserGroupService.refreshUserGroups();
    this.props.onRefreshEntities();
  };

  // Only refreshing permission grants is actually a good idea but then the updated data has to written to the redux store
  /* refreshPermissionGrants = async() => {
        const [userGroup, err] = await promiseHandler(UserGroupService.getUserGroup(this.state.userGroup.id));

        if(err) {
            Log.error('Failed to load user group. id: ' + this.state.userGroup.id, err);
            return;
        }

        let newUserGroup = cloneDeep(this.state.userGroup);

        newUserGroup.permissionGrants = userGroup.permissionGrants;

        this.setState({
            userGroup: newUserGroup
        });
    } */

  handleInputChange = (event) => {
    const newUserGroup = cloneDeep(this.state.userGroup);

    switch (event.target.name) {
      case 'name': {
        newUserGroup.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.state.userGroup.name, to: newUserGroup.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_group_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.USER_GROUP],
        );
        break;
      }
    }

    this.setState({
      userGroup: newUserGroup,
    });
  };
  handleChangeUsers = (users) => {
    const newUserGroup = cloneDeep(this.state.userGroup);

    newUserGroup.users = users.map((user) => user.id);

    Log.info(
      'Change form value of users',
      { from: this.state.userGroup.users, to: newUserGroup.users },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change users', Log.FEATURE.USER_GROUP);

    this.setState({
      userGroup: newUserGroup,
    });
  };
  handleChangeChildUserGroups = (childUserGroups) => {
    const newUserGroup = cloneDeep(this.state.userGroup);

    newUserGroup.childUserGroups = childUserGroups.map(
      (userGroup) => userGroup.id,
    );

    Log.info(
      'Change form value of child user groups',
      {
        from: this.state.userGroup.childUserGroups,
        to: newUserGroup.childUserGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change child user groups',
      Log.FEATURE.USER_GROUP,
    );

    this.setState({
      userGroup: newUserGroup,
    });
  };
  handleChangeOrganisationalGroups = (organisationalGroups) => {
    const newUserGroup = cloneDeep(this.state.userGroup);

    newUserGroup.organisationalGroups = organisationalGroups.map(
      (organisationalGroup) => organisationalGroup.id,
    );

    Log.info(
      'Change form value of organisational groups',
      {
        from: this.state.userGroup.organisationalGroups,
        to: newUserGroup.organisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change organisational groups',
      Log.FEATURE.USER_GROUP,
    );

    this.setState({
      userGroup: newUserGroup,
    });
  };
  handleChangeParentUserGroups = (parentUserGroups) => {
    const newUserGroup = cloneDeep(this.state.userGroup);

    newUserGroup.parentUserGroups = parentUserGroups.map(
      (userGroup) => userGroup.id,
    );

    Log.info(
      'Change form value of parent user groups',
      {
        from: this.state.userGroup.parentUserGroups,
        to: newUserGroup.parentUserGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change parent user groups',
      Log.FEATURE.USER_GROUP,
    );

    this.setState({
      userGroup: newUserGroup,
    });
  };
  refreshUserGroup = async () => {
    this.setState({
      userGroupLoading: LOADING_STATE.LOADING,
    });

    const [response, error] = await promiseHandler(
      UserGroupService.refreshUserGroup(this.props.userGroup.id),
    );

    if (error) {
      this.setState({
        userGroupLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      userGroupLoading: LOADING_STATE.SUCCEEDED,
    });
  };

  getPaths() {
    if (this.renderForCreate()) {
      return null;
    }

    return (
      <div className="flexdir-column gap-10px flex">
        <OrganisationalGroupPaths
          id={this.props.userGroup.id}
          organisationalGroupPaths={
            this.props.userGroup.organisationalGroupPaths
          }
          onOpenOrganisationalGroup={(organisationalGroup) =>
            this.props.onOpenOrganisationalGroup(
              organisationalGroup,
              this.getUnsavedChanges(),
            )
          }
        />
        <UserGroupPaths
          id={this.props.userGroup.id}
          userGroupPaths={this.props.userGroup.userGroupPaths}
          onOpenUserGroup={(userGroup) =>
            this.props.onOpenUserGroup(userGroup, this.getUnsavedChanges())
          }
        />
      </div>
    );
  }

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return UserGroup.getDifferentValues(
      this.props.userGroup,
      this.state.userGroup,
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Benutzer-Gruppe ' +
          (this.renderForCreate() ? 'Erstellen' : this.state.userGroup.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        id={this.props.userGroup?.id}
        unsavedChanges={this.getUnsavedChanges()}
        missingPermissionsToSubmit={
          this.renderForCreate()
            ? !UserUtils.isUserGroupCreateAllowedUser()
            : !UserUtils.isUserGroupWriteAllowedUser()
        }
        missingPermissionsToDelete={!UserUtils.isUserGroupDeleteAllowedUser()}
      >
        <Grid container direction="row" spacing={3} space={4}>
          {this.props.userGroup?.organisationalGroupPaths?.length > 0 ||
          this.props.userGroup?.userGroupPaths?.length > 0 ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  {this.getPaths()}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <h3 className="main-text mt-0">Benutzer-Gruppe</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  required
                  value={this.state.userGroup.name}
                  onChange={this.handleInputChange}
                  autoFocus
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Enthält die Folgenden...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.USER.KEY}
                  pickedIds={this.state.userGroup.users}
                  callbackPickedItems={this.handleChangeUsers}
                  onChipClick={(user) =>
                    this.props.onOpenUser(user, this.getUnsavedChanges())
                  }
                  onUpdatedItemsChange={this.props.onUpdatedUsersChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY}
                  pickedIds={this.state.userGroup.childUserGroups}
                  callbackPickedItems={this.handleChangeChildUserGroups}
                  onChipClick={(userGroup) =>
                    this.props.onOpenUserGroup(
                      userGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
                  // onUpdatedItemsChange={this.props.onUpdatedUserGroupsChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Ist Teil von...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={
                    PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                  }
                  pickedIds={this.state.userGroup.organisationalGroups}
                  callbackPickedItems={this.handleChangeOrganisationalGroups}
                  onChipClick={(organisationalGroup) =>
                    this.props.onOpenOrganisationalGroup(
                      organisationalGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  onUpdatedItemsChange={
                    this.props.onUpdatedOrganisationalGroupsChange
                  }
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY}
                  pickedIds={this.state.userGroup.parentUserGroups}
                  callbackPickedItems={this.handleChangeParentUserGroups}
                  onChipClick={(userGroup) =>
                    this.props.onOpenUserGroup(
                      userGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
                  // onUpdatedItemsChange={this.props.onUpdatedUserGroupsChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {this.renderForCreate() ? null : (
            <Grid item xs={12}>
              <PermissionGrantSubjectTable
                title="Worauf ist diese Benutzer-Gruppe berechtigt?"
                permissionGrantsOn={this.state.userGroup.permissionGrantsOn}
                defaultSubjects={[this.props.userGroup?.id]}
                defaultSubjectType={PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY}
                fixedPicker={PermissionGrant.TYPE.SUBJECT}
                refreshData={this.refreshUserGroup}
                loading={this.state.userGroupLoading}
              />
            </Grid>
          )}
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(UserGroupForm);
