import React from 'react';
import { connect } from 'react-redux';
import { SettingsTable } from '../../SettingsTable';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import MapperService from '~/services/mapper.service';
import Article from '~/models/articleMaster/Article';

const columns = [
  {
    field: 'articleId',
    headerName: 'Artikel-ID',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'description',
    headerName: 'Beschreibung',
    width: 200,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'ean',
    headerName: 'EAN',
    width: 200,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'active',
    headerName: 'Aktiv',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'virtual',
    headerName: 'Generischer Artikel',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'equivalentArticles',
    headerName: 'Ersetzbare Artikel',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'access',
    headerName: 'Berechtigung',
    width: 150,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'ownerCompanies',
    headerName: 'Zuordnung zu Firmen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'filterCompanies',
    headerName: 'Suchbar in Firmen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'filterSites',
    headerName: 'Suchbar in Standorten',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'filterCostCenters',
    headerName: 'Suchbar in Kostenstellen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  articleMaster: state.articleMaster,
  companies: state.companies,
  sites: state.sites,
  costCenters: state.costCenters,
});

class ArticleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({
      rows: this.props.articleMaster?.articles?.map((article) => {
        const row = {
          id: article.id,
          articleId: article.articleId,
          name: article.name,
          description: article.description,
          ean: article.ean,
          active: article.active ? Article.IS_ACTIVE.YES : Article.IS_ACTIVE.NO,
          virtual: article.virtual
            ? Article.IS_ACTIVE.YES
            : Article.IS_ACTIVE.NO,
          equivalentArticles: article.equivalentArticles
            .map((equivalentArticleId) => {
              const article = this.props.articleMaster.articles.find(
                (article) => article.id === equivalentArticleId,
              );
              if (!article) {
                return '';
              }

              return article.articleId + ' ' + article.name;
            })
            .join(', '),
          access: Article.getAccessString(article.access),
          ownerCompanies: article.ownerCompanies
            .map(
              (ownerCompanyId) =>
                this.props.companies.companies.find(
                  (company) => company.id === ownerCompanyId,
                )?.name ?? '',
            )
            .join(', '),
          filterCompanies: article.filterCompanies
            .map(
              (filterCompanyId) =>
                this.props.companies.companies.find(
                  (company) => company.id === filterCompanyId,
                )?.name ?? '',
            )
            .join(', '),
          filterSites: article.filterSites
            .map(
              (filterSiteId) =>
                this.props.sites.sites.find((site) => site.id === filterSiteId)
                  ?.name ?? '',
            )
            .join(', '),
          filterCostCenters: article.filterCostCenters
            .map(
              (filterCostCenter) =>
                this.props.costCenters.costCenters.find(
                  (costCenter) => costCenter.id === filterCostCenter,
                )?.name ?? '',
            )
            .join(', '),
        };

        return MapperService.addSearchString(row, [
          'id',
          'articleId',
          'name',
          'description',
          'ean',
          'active',
          'virtual',
          'equivalentArticles',
          'access',
          'ownerCompanies',
          'filterCompanies',
          'filterSites',
          'filterCostCenters',
        ]);
      }),
    });
  };

  render() {
    return (
      <SettingsTable
        entity="article"
        title="Artikel"
        rows={this.state.rows}
        columns={columns}
        items={this.props.articleMaster.articles}
        localStorageKey={LocalStorageService.ARTICLE_TABLE}
        loading={this.props.articleMaster.articlesLoading}
        hasActiveStateFilter
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(ArticleTable),
  'Artikel konnten nicht geladen werden.',
);
