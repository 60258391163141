import React from 'react';
import { Add as AddIcon, Check as CheckIcon } from '@mui/icons-material';

import BasicModal from './BasicModal';

import { LightTooltipWide } from '~/utils/componentUtils';
import ArrayUtils from '~/utils/arrayUtils';
import { SquaredIconButton } from './baseComponents/iconButtons/SquaredIconButton';
import { FreeTextSearch } from './baseComponents/inputs/FreeTextSearch';
import Log from '~/utils/Log';

class AddItemModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
    };
  }

  setFilter = (filter) => {
    this.setState({
      filter,
    });
  };

  render() {
    return (
      <BasicModal
        open={this.props.open}
        closeModal={this.props.closeModal}
        title={this.props.title}
      >
        <FreeTextSearch
          value={this.state.filter}
          onChange={this.setFilter}
          productAnalyticsFeature={Log.FEATURE.ADD_ITEM_MODAL}
          autoFocus
        />
        <div className="flexdir-column gap-8px h-50vh w-500px mt-16px flex overflow-scroll">
          {ArrayUtils.sortStringArrayByKey(this.props.items, 'name')
            .filter((item) =>
              item.name.toLowerCase().includes(this.state.filter.toLowerCase()),
            )
            .map((item) => {
              const alreadyPicked = this.props.pickedIds?.includes(item?.id);

              return (
                <div key={item.id}>
                  <div className="h-1px bg-grey200 w-full" />
                  <div className="flex-sb-c mt-8px">
                    <LightTooltipWide title={item.name}>
                      <div
                        className={
                          'text-overflow ' +
                          (alreadyPicked ? 'text-grey200' : '')
                        }
                      >
                        {item.name}
                      </div>
                    </LightTooltipWide>
                    <SquaredIconButton
                      onClick={() => this.props.onAddItemClick(item.id)}
                      disabled={alreadyPicked}
                      size="small"
                    >
                      {alreadyPicked ? (
                        <CheckIcon
                          className="text-primary100"
                          fontSize="small"
                        />
                      ) : (
                        <AddIcon className="text-primary500" fontSize="small" />
                      )}
                    </SquaredIconButton>
                  </div>
                </div>
              );
            })}
        </div>
      </BasicModal>
    );
  }
}

export default AddItemModal;
