import { useSelector } from 'react-redux';
import { useState, useEffect, useRef, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { LOADING_STATE } from '~/constants/LoadingState';

import Address from '~/models/masterdata/Address';
import CostCenter from '~/models/masterdata/CostCenter';
import Site from '~/models/masterdata/Site';

import SiteService from '~/services/site.service';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import ObjectUtils from '~/utils/objectUtils';

import { onSubmit } from './utils';

export const useSiteForm = ({ closeForm, site, type }) => {
  const companies = useSelector((state) => state.companies);
  const costCenters = useSelector((state) => state.costCenters);
  const userinfo = useSelector((state) => state.userinfo);

  const getDefaultSite = useCallback(() => {
    const site = new Site();
    site.company = userinfo.userinfo.company?.id;
    site.type = Site.getSiteTypes()[0].id;
    site.address.country = Address.DEFAULT_COUNTRY_CODE.DE;

    return site;
  }, [userinfo.userinfo]);

  const [costCenterOpen, setCostCenterOpen] = useState(false);
  const [deactivateCoupledCostCenters, setDeactivateCoupledCostCenters] =
    useState(false);
  const [
    deactivateCoupledCostCentersFormOpen,
    setDeactivateCoupledCostCentersFormOpen,
  ] = useState(false);
  const [formSite, setFormSite] = useState(getDefaultSite());
  const [siteLoading, setSiteLoading] = useState(LOADING_STATE.NOT_LOADED);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [syncCoupledPermissionGrants, setSyncCoupledPermissionGrants] =
    useState(false);
  const [
    syncCoupledPermissionGrantsFormOpen,
    setSyncCoupledPermissionGrantsFormOpen,
  ] = useState(false);

  // Refs to store previous values of props/state
  const previousSiteRef = useRef();
  const previousCompaniesRef = useRef();

  const renderForCreate = () => type === 'create';

  // Update the refs after rendering
  useEffect(() => {
    previousSiteRef.current = site;
    previousCompaniesRef.current = companies.companies;
  });

  const previousSite = previousSiteRef.current;

  useEffect(() => {
    resetForm(true);
  }, []);

  useEffect(() => {
    if (JSON.stringify(site) !== JSON.stringify(previousSite)) {
      resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          site,
          previousSite,
          'permissionGrantsFrom',
        ),
      );
    }
  }, [site, companies.companies]);

  const resetForm = useCallback(
    (resetGeneralSiteInformation) => {
      if (!resetGeneralSiteInformation) {
        const newSite = cloneDeep(formSite);
        newSite.permissionGrantsFrom = site
          ? site.permissionGrantsFrom
          : getDefaultSite().permissionGrantsFrom;

        setFormSite(newSite);
        return;
      }

      setFormSite(site ?? getDefaultSite());

      if (site && !site.additionalDataInitiated) {
        refreshSite();
      }
    },
    [site, formSite, getDefaultSite],
  );

  const handleSubmit = async () => {
    onSubmit({
      closeForm,
      deactivateCoupledCostCenters,
      formSite,
      renderForCreate,
      resetForm,
      setSubmittingForm,
      site,
      syncCoupledPermissionGrants,
    });
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.SITE);
    closeForm();
    resetForm(true);
  };

  const handleChangeCompany = (event) => {
    const newSite = cloneDeep(formSite);
    newSite.company = event.target.value;

    Log.info(
      'Change form value of company',
      { from: formSite.company, to: newSite.company },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change company', Log.FEATURE.SITE);

    setFormSite(newSite);
  };

  const handleChangeSiteType = (event) => {
    const newSite = cloneDeep(formSite);
    newSite.type = event.target.value;

    Log.info(
      'Change form value of type',
      { from: formSite.type, to: newSite.type },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change type', Log.FEATURE.SITE);

    setFormSite(newSite);
  };

  const handleChangeDate = (date, eventName) => {
    const newSite = cloneDeep(formSite);
    newSite[eventName] = new Date(
      new Date(date).setUTCHours(0, 0, 0, 0),
    ).toISOString();

    Log.info(
      `Change form value of ${eventName} date`,
      { from: formSite[eventName], to: newSite[eventName] },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(`Change ${eventName} date`, Log.FEATURE.SITE);

    setFormSite(newSite);
  };

  const handleChangeCostCenters = (costCenters) => {
    const newSite = cloneDeep(formSite);
    newSite.costCenters = costCenters.map((costCenter) => costCenter.id);

    Log.info(
      'Change form value of cost centers',
      { from: formSite.costCenters, to: newSite.costCenters },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change cost centers', Log.FEATURE.SITE);

    setFormSite(newSite);
  };

  const handleInputChange = (event) => {
    const newSite = cloneDeep(formSite);

    switch (event.target.name) {
      case 'name': {
        newSite.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: formSite.name, to: newSite.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'street_name': {
        newSite.address.streetName = event.target.value;
        Log.info(
          'Change form value of street name',
          {
            from: formSite.address?.streetName,
            to: newSite.address.streetName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'building_number': {
        newSite.address.buildingNumber = event.target.value;
        Log.info(
          'Change form value of building number',
          {
            from: formSite.address?.buildingNumber,
            to: newSite.address.buildingNumber,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'city': {
        newSite.address.city = event.target.value;
        Log.info(
          'Change form value of city',
          { from: formSite.address?.city, to: newSite.address.city },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'post_code': {
        newSite.address.postCode = event.target.value;
        Log.info(
          'Change form value of post code',
          {
            from: formSite.address?.postCode,
            to: newSite.address.postCode,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'latitude': {
        newSite.coords.latitude = event.target.value;
        Log.info(
          'Change form value of latitude',
          {
            from: formSite.coords?.latitude,
            to: newSite.coords.latitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'longitude': {
        newSite.coords.longitude = event.target.value;
        Log.info(
          'Change form value of longitude',
          {
            from: formSite.coords?.longitude,
            to: newSite.coords.longitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      default: {
        break;
      }
    }

    setFormSite(newSite);
  };

  const handleCheckboxChange = (event) => {
    const newSite = cloneDeep(formSite);
    newSite.active = event.target.checked;

    Log.info(
      'Change form value of active',
      { from: formSite.active, to: newSite.active },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change active checkbox', Log.FEATURE.SITE);

    setFormSite(newSite);
  };

  const handleChangeOrganisationalGroups = (organisationalGroups) => {
    const newSite = cloneDeep(formSite);
    newSite.organisationalGroups = organisationalGroups.map(
      (organisationalGroup) => organisationalGroup.id,
    );

    Log.info(
      'Change form value of organisational groups',
      {
        from: formSite.organisationalGroups,
        to: newSite.organisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change organisational groups', Log.FEATURE.SITE);

    setFormSite(newSite);
  };

  const handleCostCenterOpen = () => {
    Log.info(
      'Open cost center create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent('Open form', Log.FEATURE.COST_CENTER);

    setCostCenterOpen(true);
  };

  const closeCostCenter = () => {
    Log.info('Close cost center form', null, Log.BREADCRUMB.FORM_CLOSE.KEY);

    setCostCenterOpen(false);
  };

  const setCostCenter = (id) => {
    const newSite = cloneDeep(formSite);
    newSite.costCenters = [...newSite.costCenters, id];

    setFormSite(newSite);
  };

  const getActiveCostCenters = () => {
    return (
      costCenters?.costCenters ??
      [...costCenters.costCenters].filter(
        ({ active, id }) => active || formSite.costCenters.includes(id),
      )
    );
  };

  const costCentersMap = new Map(
    costCenters?.costCenters?.map((costCenter) => [costCenter.id, costCenter]),
  );

  const getPickedCostCenters = () => {
    return formSite.costCenters.map(
      (id) => costCentersMap.get(id) ?? new CostCenter({ id }),
    );
  };

  const refreshSite = async () => {
    setSiteLoading(LOADING_STATE.LOADING);

    const [, error] = await promiseHandler(SiteService.refreshSite(site.id));

    if (error) {
      setSiteLoading(LOADING_STATE.FAILED);
      return;
    }

    setSiteLoading(LOADING_STATE.SUCCEEDED);
  };

  const getPaths = () => {
    if (renderForCreate()) {
      return null;
    }

    return {
      id: site.id,
      organisationalGroupPaths: site.organisationalGroupPaths,
    };
  };

  const getUnsavedChanges = () => {
    if (renderForCreate()) {
      return [];
    }

    return Site.getDifferentValues(site, formSite);
  };

  return {
    closeCostCenter,
    companies,
    costCenterOpen,
    costCenters,
    deactivateCoupledCostCentersFormOpen,
    handleCancel,
    formSite,
    handleSubmit,
    getActiveCostCenters,
    getPaths,
    getPickedCostCenters,
    getUnsavedChanges,
    handleChangeCompany,
    handleChangeCostCenters,
    handleChangeDate,
    handleChangeOrganisationalGroups,
    handleChangeSiteType,
    handleCheckboxChange,
    handleCostCenterOpen,
    handleInputChange,
    refreshSite,
    renderForCreate,
    setCostCenter,
    setDeactivateCoupledCostCenters,
    setDeactivateCoupledCostCentersFormOpen,
    setSyncCoupledPermissionGrants,
    setSyncCoupledPermissionGrantsFormOpen,
    siteLoading,
    submittingForm,
    syncCoupledPermissionGrantsFormOpen,
    userinfo,
  };
};
