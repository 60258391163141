import React from 'react';

import {
  ChevronRight as ChevronRightIcon,
  LocationOn as LocationOnIcon,
  Person as PersonIcon,
  Shield as ShieldIcon,
} from '@mui/icons-material';

import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import CreateUserWizard from './wizard/createUserWizard/CreateUserWizard';
import { Chip } from '@mui/material';

import CreateSiteWizard from './wizard/createSiteWizard/CreateSiteWizard';
import UpdatePermissionsWizard from './wizard/updatePermissionsWizard/UpdatePermissionsWizard';

import { ROUTE } from '~/constants/Route';
import Log from '~/utils/Log';
import UserService from '~/services/user.service';
import { SettingsTestIds } from '~/constants/test-ids';

const mapStateToProps = (state) => ({
  userinfo: state.userinfo,
});

class MasterData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chips: [],
    };
  }

  componentDidMount() {
    this.initChips();
  }

  initChips() {
    let chips = [
      {
        label: 'Benutzer',
        route: ROUTE.SETTINGS_USER.ROUTE,
      },
      {
        label: 'Standorte',
        route: ROUTE.SETTINGS_SITE.ROUTE,
      },
      {
        label: 'Kostenstellen',
        route: ROUTE.SETTINGS_COST_CENTER.ROUTE,
      },
      {
        label: 'Fahrzeuge',
        route: ROUTE.SETTINGS_VEHICLE.ROUTE,
      },
      {
        label: 'Firmen',
        route: ROUTE.SETTINGS_COMPANY.ROUTE,
      },
      {
        label: 'Organisations-Gruppen',
        route: ROUTE.SETTINGS_ORGANISATIONAL_GROUP.ROUTE,
      },
      {
        label: 'Benutzer-Gruppen',
        route: ROUTE.SETTINGS_USER_GROUP.ROUTE,
      },
    ];

    chips = chips.filter((chip) =>
      UserService.userIsAuthorizedForPage(
        this.props.userinfo.userPermissions,
        chip.route,
      ),
    );

    this.setState({
      chips,
    });
  }

  openSettingsPage = (chip) => {
    Log.productAnalyticsEvent(
      'Open ' + chip.label + ' from Stammdaten',
      Log.FEATURE.MENU,
    );
    this.props.history.push(chip.route);
  };

  render() {
    return (
      <div className="ml-2rem mr-2rem">
        <div
          className="main-header"
          data-testid={SettingsTestIds.HEADER.TITLE}
        >
          Stammdaten
        </div>
        <div className="flex-sb-s gap-20px">
          <div className="p-20px rounded-5px box-shadow-blue w-full bg-white text-center">
            <div className="flex-c-c gap-5px mb-20px">
              <PersonIcon />
              <div className="text-16px bold">Benutzer</div>
            </div>
            <CreateUserWizard />
          </div>
          <div className="p-20px rounded-5px box-shadow-blue w-full bg-white text-center">
            <div className="flex-c-c gap-5px mb-20px">
              <LocationOnIcon />
              <div className="text-16px bold">Standorte</div>
            </div>
            <CreateSiteWizard />
          </div>
          <div className="p-20px rounded-5px box-shadow-blue w-full bg-white text-center">
            <div className="flex-c-c gap-5px mb-20px">
              <ShieldIcon />
              <div className="text-16px bold">Berechtigungen</div>
            </div>
            <UpdatePermissionsWizard />
          </div>
        </div>
        {this.state.chips.length > 0 ? (
          <>
            <div className="mt-40px h-1px bg-grey200 w-full" />
            <div className="mt-40px">Gehe zu bestehenden:</div>
            <div className="flex-s-c gap-10px mt-10px">
              {this.state.chips.map((chip) => (
                <Chip
                  key={chip.label}
                  label={chip.label}
                  onClick={() => this.openSettingsPage(chip)}
                  icon={<ChevronRightIcon />}
                />
              ))}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(MasterData),
  'Seite konnte nicht geladen werden.',
);
