import OrganisationalGroupService from '~/services/organisationalGroup.service';
import ToastService from '~/services/toast.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { promiseHandler } from '~/utils/promiseHandler';

import Log from '~/utils/Log';

export const updateParentOrganisationalGroups = async (
  userId,
  existingOrganisationalGroups,
  newOrganisationalGroups,
) => {
  const [, error] = await promiseHandler(
    OrganisationalGroupService.updateParentOrganisationalGroups(
      userId,
      PermissionGrant.ENTITY_TYPE.USER.KEY,
      existingOrganisationalGroups,
      newOrganisationalGroups,
    ),
  );

  if (error) {
    ToastService.httpError(
      ['Organisations-Gruppen konnten nicht geändert werden.'],
      error.response,
    );
    Log.productAnalyticsEvent(
      'Failed to update organisational groups',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  }
};
