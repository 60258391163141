import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysOrganizationalUnit } from './queryKeys';

type Permission = string; // TODO: add real permission type

export type OrganizationalUnit = {
  id: UUID;
  name: string;
  parentOus: UUID[];
  childOus: UUID[];
  users: UUID[];
  userGroups: UUID[];
  companies: UUID[];
  sites: UUID[];
  accountingReferences: UUID[]; // aka cost centers
  vehicles: UUID[];
  orgUnitPaths: UUID[][];
  permissionsFrom: Array<{
    id: number;
    subjectId: UUID;
    subjectType: string;
    permissions: Permission[];
  }>;
};

export const fetchOrganizationalUnit = async (
  organizationalUnitId: UUID,
): Promise<OrganizationalUnit | undefined> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.ORGANIZATIONAL_UNIT.GET(organizationalUnitId))
      .json<OrganizationalUnit>();

    return response;
  } catch (error) {
    Log.error('Error fetching organizational unit', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

export const getOrganizationalUnitQueryOptions = ({
  organizationalUnitId,
  options,
}: {
  organizationalUnitId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => {
  return {
    queryKey: queryKeysOrganizationalUnit.get(organizationalUnitId),
    queryFn: async () => fetchOrganizationalUnit(organizationalUnitId),
    ...options,
  };
};

export const useQueryOrganizationalUnit = (
  organizationalUnitId: UUID,
  options: Parameters<typeof useQuery>[0],
): UseQueryResult<OrganizationalUnit | undefined> =>
  useQuery(
    getOrganizationalUnitQueryOptions({ organizationalUnitId, options }),
  );
