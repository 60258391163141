import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MASTERDATA } from '~/constants/Masterdata';

import CompanyService from '~/services/company.service';
import CostCenterService from '~/services/costCenter.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import SiteService from '~/services/site.service';
import UserGroupService from '~/services/userGroup.service';
import UserService from '~/services/user.service';
import VehicleService from '~/services/vehicle.service';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

const selectCompanies = (state) => state.companies;
const selectCostCenters = (state) => state.costCenters;
const selectOrganisationalGroups = (state) => state.organisationalGroups;
const selectSites = (state) => state.sites;
const selectVehicles = (state) => state.vehicles;

export const useSettingsTable = ({
  entity,
  filterFunction,
  hasActiveStateFilter,
  hasMultiPermissionGrantEditing,
  items,
  onSearchStringChange,
  refreshData,
  rows,
  sortBy,
}) => {
  const companies = useSelector(selectCompanies);
  const costCenters = useSelector(selectCostCenters);
  const organisationalGroups = useSelector(selectOrganisationalGroups);
  const sites = useSelector(selectSites);
  const vehicles = useSelector(selectVehicles);

  const [state, setState] = useState({
    activeStateFilter: MASTERDATA.IS_ACTIVE.YES,
    company: null,
    costCenter: null,
    excelData: [],
    filteredRows: [],
    formOpen: false,
    formType: 'create',
    freeTextFilter: '',
    item: null,
    organisationalGroup: null,
    rowSelectionModel: [],
    showMultiPermissionGrantEdit: false,
    site: null,
    sortModel: [
      {
        field: sortBy ?? 'name',
        sort: 'asc',
      },
    ],
    updatedCompanies: [],
    updatedCostCenters: [],
    updatedOrganisationalGroups: [],
    updatedSites: [],
    updatedUserGroups: [],
    updatedUsers: [],
    updatedVehicles: [],
    user: null,
    userGroup: null,
    vehicle: null,
  });

  const filterRows = useCallback(() => {
    const doFilterRows = (activeStateFilter, freeTextFilter) => {
      // Legacy fuckup: check if the rows contain a key "active" or "isActive" and set the isActiveKey accordingly
      const isActiveKey =
        rows[0] && Object.keys(rows[0]).includes('active')
          ? 'active'
          : 'isActive';
      const filteredRows = hasActiveStateFilter
        ? ArrayUtils.filterByKey(rows, isActiveKey, activeStateFilter)
        : rows;

      if (filterFunction) {
        return filterFunction(filteredRows, freeTextFilter);
      }

      return filteredRows.filter(({ searchString }) =>
        searchString.includes(freeTextFilter.toLowerCase()),
      );
    };

    setState((prevState) => ({
      ...prevState,
      filteredRows: doFilterRows(
        prevState.activeStateFilter,
        prevState.freeTextFilter,
      ),
    }));
  }, [hasActiveStateFilter, rows, filterFunction]);

  useEffect(() => {
    filterRows();
  }, [filterRows]);

  useEffect(() => {
    if (!state.item) {
      return;
    }

    const newItem = items.find(({ id }) => id === state.item.id);
    if (newItem) {
      setState((prevState) => ({
        ...prevState,
        item: newItem,
      }));
    }
  }, [items, state.item]);

  useEffect(() => {
    if (!state.site) {
      return;
    }

    const newSite = sites.sites.find(({ id }) => id === state.site.id);
    if (JSON.stringify(state.site) !== JSON.stringify(newSite)) {
      setState((prevState) => ({
        ...prevState,
        site: newSite,
      }));
    }
  }, [sites, state.site]);

  useEffect(() => {
    if (!state.costCenter) {
      return;
    }

    const newCostCenter = costCenters.costCenters.find(
      ({ id }) => id === state.costCenter.id,
    );

    if (JSON.stringify(state.costCenter) !== JSON.stringify(newCostCenter)) {
      setState((prevState) => ({
        ...prevState,
        costCenter: newCostCenter,
      }));
    }
  }, [costCenters, state.costCenter]);

  useEffect(() => {
    if (!state.vehicle) {
      return;
    }

    const newVehicle = vehicles.vehicles.find(
      ({ id }) => id === state.vehicle.id,
    );
    if (JSON.stringify(state.vehicle) !== JSON.stringify(newVehicle)) {
      setState((prevState) => ({
        ...prevState,
        vehicle: newVehicle,
      }));
    }
  }, [vehicles, state.vehicle]);

  useEffect(() => {
    if (!state.company) {
      return;
    }

    const newCompany = companies.companies.find(
      ({ id }) => id === state.company.id,
    );
    if (JSON.stringify(state.company) !== JSON.stringify(newCompany)) {
      setState((prevState) => ({
        ...prevState,
        company: newCompany,
      }));
    }
  }, [companies, state.company]);

  useEffect(() => {
    if (!state.organisationalGroup) {
      return;
    }

    const newOrganisationalGroup =
      organisationalGroups.organisationalGroups.find(
        ({ id }) => id === state.organisationalGroup.id,
      );
    if (
      JSON.stringify(state.organisationalGroup) !==
      JSON.stringify(newOrganisationalGroup)
    ) {
      setState((prevState) => ({
        ...prevState,
        organisationalGroup: newOrganisationalGroup,
      }));
    }
  }, [organisationalGroups, state.organisationalGroup]);

  useEffect(() => {
    filterRows();
  }, [rows, state.freeTextFilter, state.activeStateFilter, filterRows]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      excelData: prevState.filteredRows.filter(
        ({ id }) => prevState.rowSelectionModel?.includes(id) ?? true,
      ),
    }));
  }, [state.rowSelectionModel, state.filteredRows]);

  const setFreeTextFilter = useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        freeTextFilter: value,
      }));

      if (onSearchStringChange) {
        onSearchStringChange(value);
      }
    },
    [onSearchStringChange],
  );

  const eventHandlers = {
    setActiveStateFilter: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          activeStateFilter: value,
        })),
      [],
    ),
    setFreeTextFilter,
    setFilteredRows: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          filteredRows: value,
        })),
      [],
    ),
    setSortModel: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          sortModel: value,
        })),
      [],
    ),
    setRowSelectionModel: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          rowSelectionModel: value,
        })),
      [],
    ),
    setFormOpen: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          formOpen: value,
        })),
      [],
    ),
    setFormType: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          formType: value,
        })),
      [],
    ),
    setItem: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          item: value,
        })),
      [],
    ),
    setShowMultiPermissionGrantEdit: useCallback(
      (value) =>
        setState((prevState) => ({
          ...prevState,
          showMultiPermissionGrantEdit: value,
        })),
      [],
    ),
    onSortModelChange: useCallback((event) => {
      Log.productAnalyticsEvent('Sort', Log.FEATURE.SETTINGS_TABLE);

      setState((prevState) => ({
        ...prevState,
        sortModel: event,
      }));
    }, []),
    onRowSelectionModelChange: useCallback(
      (event) => {
        Log.info(
          `Change selection value of ${entity}`,
          {
            from: state.rowSelectionModel,
            to: event,
          },
          Log.BREADCRUMB.SELECTION_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          `(De)select ${entity}`,
          Log.FEATURE.SETTINGS_TABLE,
        );

        setState((prevState) => ({
          ...prevState,
          rowSelectionModel: event,
        }));
      },
      [entity, state.rowSelectionModel],
    ),
    onRowClick: useCallback(
      (event) => {
        const item = items.find(({ id }) => id === event.id);
        Log.info(
          `Open ${entity} edit form`,
          item,
          Log.BREADCRUMB.FORM_OPEN.KEY,
        );
        Log.productAnalyticsEvent(
          `Open ${entity} edit form`,
          Log.FEATURE.SETTINGS_TABLE,
        );

        setState((prevState) => ({
          ...prevState,
          item,
        }));
        eventHandlers.openEditForm();
      },
      [entity, items],
    ),
    onOpenUser: useCallback((user) => {
      Log.info('Open user edit form', user, Log.BREADCRUMB.FORM_OPEN.KEY);
      Log.productAnalyticsEvent(
        'Open user edit form',
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        user,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    onOpenSite: useCallback((site) => {
      Log.info('Open site edit form', site, Log.BREADCRUMB.FORM_OPEN.KEY);
      Log.productAnalyticsEvent(
        'Open site edit form',
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        site,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    onOpenCostCenter: useCallback((costCenter) => {
      Log.info(
        'Open cost center edit form',
        costCenter,
        Log.BREADCRUMB.FORM_OPEN.KEY,
      );
      Log.productAnalyticsEvent(
        'Open cost center edit form',
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        costCenter,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    onOpenVehicle: useCallback((vehicle) => {
      Log.info('Open vehicle edit form', vehicle, Log.BREADCRUMB.FORM_OPEN.KEY);
      Log.productAnalyticsEvent(
        'Open vehicle edit form',
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        vehicle,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    onOpenCompany: useCallback((company) => {
      Log.info('Open company edit form', company, Log.BREADCRUMB.FORM_OPEN.KEY);
      Log.productAnalyticsEvent(
        'Open company edit form',
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        company,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    onOpenOrganisationalGroup: useCallback((organisationalGroup) => {
      Log.info(
        'Open organisationalGroup edit form',
        organisationalGroup,
        Log.BREADCRUMB.FORM_OPEN.KEY,
      );
      Log.productAnalyticsEvent(
        'Open organisationalGroup edit form',
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        organisationalGroup,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    onOpenUserGroup: useCallback((userGroup) => {
      Log.info(
        'Open user group edit form',
        userGroup,
        Log.BREADCRUMB.FORM_OPEN.KEY,
      );
      Log.productAnalyticsEvent(
        'Open user group edit form',
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        userGroup,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    closeForm: useCallback(() => {
      Log.info(`Close ${entity} form`, null, Log.BREADCRUMB.FORM_CLOSE.KEY);

      setState((prevState) => ({
        ...prevState,
        company: null,
        costCenter: null,
        formOpen: false,
        organisationalGroup: null,
        site: null,
        user: null,
        userGroup: null,
        vehicle: null,
      }));
    }, [entity]),
    openEditForm: useCallback(() => {
      setState((prevState) => ({
        ...prevState,
        formOpen: true,
        formType: 'edit',
      }));
    }, []),
    openCreateForm: useCallback(() => {
      Log.info(
        `Open ${entity} create form`,
        null,
        Log.BREADCRUMB.FORM_OPEN.KEY,
      );
      Log.productAnalyticsEvent(
        `Open ${entity} create form`,
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        formOpen: true,
        formType: 'create',
        item: null,
      }));
    }, [entity]),
    openMultiPermissionGrantEdit: useCallback(() => {
      Log.info(
        'Open multi user edit form',
        { rowSelectionModel: state.rowSelectionModel },
        Log.BREADCRUMB.FORM_OPEN.KEY,
      );
      Log.productAnalyticsEvent(
        `Open ${entity} multi permission grant edit form`,
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((prevState) => ({
        ...prevState,
        showMultiPermissionGrantEdit: true,
      }));
    }, [entity, state.rowSelectionModel]),
    closeMultiPermissionGrantEdit: useCallback(() => {
      Log.info(
        'Close multi permission grant form',
        null,
        Log.BREADCRUMB.FORM_CLOSE.KEY,
      );

      setState((prevState) => ({
        ...prevState,
        showMultiPermissionGrantEdit: false,
      }));
    }, []),
    handleMultiPermissionGrantEdit: useCallback(() => {
      return hasMultiPermissionGrantEditing &&
        UserUtils.isPermissionGrantAllowedUser()
        ? () => eventHandlers.setShowMultiPermissionGrantEdit(true)
        : null;
    }, [hasMultiPermissionGrantEditing]),
  };

  const utils = useMemo(
    () => ({
      refreshSubjects() {
        if (refreshData) {
          refreshData();
        }
      },
      refreshEntities() {
        for (const user of state.updatedUsers) {
          console.log('Do we still need this? Refresh user', user);
          UserService.refreshUser(user.id);
        }

        for (const site of state.updatedSites) {
          SiteService.refreshSite(site.id);
        }

        for (const costCenter of state.updatedCostCenters) {
          CostCenterService.refreshCostCenter(costCenter.id);
        }

        for (const vehicle of state.updatedVehicles) {
          VehicleService.refreshVehicle(vehicle.id);
        }

        for (const company of state.updatedCompanies) {
          CompanyService.refreshCompany(company.id);
        }

        for (const org of state.updatedOrganisationalGroups) {
          OrganisationalGroupService.refreshOrganisationalGroup(org.id);
        }

        for (const userGroup of state.updatedUserGroups) {
          UserGroupService.refreshUserGroup(userGroup.id);
        }
      },
    }),
    [
      refreshData,
      state.updatedUsers,
      state.updatedSites,
      state.updatedCostCenters,
      state.updatedVehicles,
      state.updatedCompanies,
      state.updatedOrganisationalGroups,
      state.updatedUserGroups,
    ],
  );

  return {
    eventHandlers,
    setState,
    state,
    utils,
  };
};
