import { memo, useState, useCallback } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { useQueryUsers } from '~/data/user';

import LocalStorageService from '~/services/localStorage.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectUsers } from './selectUsers';

const UsersTableComponent = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'email',
      sort: 'asc',
    },
  ]);
  const [searchString, setSearchString] = useState(undefined);

  const { data, isFetching, isPending, isPlaceholderData, refetch } =
    useQueryUsers(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        orderBy: sortModel[0]?.field,
        sort: sortModel[0]?.sort,
        searchString,
      },
      {
        select: selectUsers,
      },
    );

  const handlePaginationModelChange = useCallback((newModel) => {
    setPaginationModel(newModel);
  }, []);

  const handleSortModelChange = useCallback((newModel) => {
    setSortModel(newModel);
  }, []);

  const handleSearchStringChange = useCallback((newSearchString) => {
    // Show results from start when search string changes.
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
    setSearchString(newSearchString);
  }, []);

  const filterFunction = useCallback((string) => string, []);

  return (
    <SettingsTable
      entity="user"
      title="Benutzer"
      columns={columns}
      filterFunction={filterFunction}
      items={data?.users ?? []}
      loading={isPending || (isFetching && isPlaceholderData)}
      localStorageKey={LocalStorageService.USERS_TABLE}
      multiPermissionGrantDefaultSubjectType={
        PermissionGrant.SUBJECT_TYPE.USER.KEY
      }
      multiPermissionGrantFixedPicker={PermissionGrant.TYPE.SUBJECT}
      onPaginationModelChange={handlePaginationModelChange}
      onSearchStringChange={handleSearchStringChange}
      onSortModelChange={handleSortModelChange}
      paginationModel={paginationModel}
      refreshData={refetch}
      rows={data?.rows ?? []}
      sortBy="email"
      sortModel={sortModel}
      totalRowCount={data?.totalCount ?? 0}
      hasActiveStateFilter
      hasMultiPermissionGrantEditing
    />
  );
};

export const UsersTable = withErrorBoundary(memo(UsersTableComponent));
