import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';

import {
  setDashboard_selectedColumns,
  setDashboard_selectableColumns,
} from '~/redux/filtersSlice';

import LocalStorageService from '~/services/localStorage.service';
import DashboardService from '~/services/dashboard.service';
import ExportService from '~/services/export.service';
import FeatureService from '~/services/feature.service';
import ClientPortalMessage from '../salesPackages/clientPortal/clientPortalMessage';

import { dateUtils, parseDate } from '~/utils/dateUtils';
import { precision } from '~/utils/number';
import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import UnitUtils from '~/utils/unitUtils';

import BasicTable from '../BasicTable';
import { PackageBasicRestrictionMessage } from '../salesPackages/packageBasicRestriction/packageBasicRestrictionMessage';
import DashboardReportChips from './DashboardReportChips';

const mapStateToProps = (state) => ({
  deliveryNotes: state.deliveryNotes,
  selectedColumns: state.filters.dashboard_selectedColumns,
  selectableColumns: state.filters.dashboard_selectableColumns,
  // subscribe to companyAccount state so that update of clientPortal feature flag leads to rerender
  companyAccount: state.companyAccount,
  customFields: state.customFields,
});
const mapDispatchToProps = () => ({
  setDashboard_selectedColumns,
  setDashboard_selectableColumns,
});

class DashboardReport extends React.Component {
  constructor(props) {
    super(props);

    this.SELECTABLE_COLUMNS = [
      {
        field: 'processState',
        headerName: 'Status',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'acceptState',
        headerName: 'Angenommen',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'settledStatus',
        headerName: 'Abgerechnet',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'date',
        headerName: 'LFS-Datum',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
        type: 'date',
        valueGetter: parseDate,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
            dateUtils.DATE_FORMAT.YYYY_MM_DD,
          ),
      },
      {
        field: 'number',
        headerName: 'LFS-Nr.',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'supplier',
        headerName: 'Lieferant',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'recipient',
        headerName: 'Abnehmer',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'toSiteRecipient',
        headerName: 'Bestätigter Lieferort',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'toSiteSupplier',
        headerName: 'Lieferort (Lieferantenbezeichnung)',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'costCenter',
        headerName: 'Kostenstelle',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'articleNumber',
        headerName: 'Artikel-Nr.',
        flex: 4,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
      {
        field: 'article',
        headerName: 'Artikel',
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      },
    ];
  }

  componentDidMount() {
    this.initSelectableColumns();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.customFields) !==
      JSON.stringify(prevProps.customFields)
    ) {
      this.initSelectableColumns();
    }
  }

  initSelectableColumns() {
    const selectableColumns = cloneDeep(this.SELECTABLE_COLUMNS);

    for (const customField of this.props.customFields.customFields) {
      selectableColumns.push({
        field: customField.key,
        headerName: customField.displayName,
        flex: 6,
        sortable: true,
        resizableText: true,
        disableReorder: true,
      });
    }

    this.props.setDashboard_selectableColumns(selectableColumns);
  }

  onClick = (clickedColumn) => {
    Log.productAnalyticsEvent('(De)select column', Log.FEATURE.REPORT);

    let newSelectedColumns = [...this.props.selectedColumns];

    if (this.columnIsSelected(clickedColumn)) {
      newSelectedColumns = ArrayUtils.remove(newSelectedColumns, clickedColumn);
    } else {
      newSelectedColumns.push(clickedColumn);
    }

    const sortedColumns = this.props.selectableColumns.map(
      (selectableColumn) => selectableColumn.headerName,
    );
    this.props.setDashboard_selectedColumns(
      sortedColumns.filter((column) => newSelectedColumns.includes(column)),
    );
  };
  onReorderColumns = (sourceIndex, destinationIndex) => {
    Log.productAnalyticsEvent('Reorder columns', Log.FEATURE.REPORT);

    let newSelectableColumns = cloneDeep(this.props.selectableColumns);
    newSelectableColumns = ArrayUtils.moveItem(
      newSelectableColumns,
      sourceIndex,
      destinationIndex,
    );
    this.props.setDashboard_selectableColumns(newSelectableColumns);

    const sortedColumns = newSelectableColumns.map(
      (selectableColumn) => selectableColumn.headerName,
    );
    this.props.setDashboard_selectedColumns(
      sortedColumns.filter((column) =>
        this.props.selectedColumns.includes(column),
      ),
    );
  };

  columnIsSelected(column) {
    return this.props.selectedColumns.includes(column);
  }

  noColumnIsSelected() {
    const selectableColumns = this.props.selectableColumns.map(
      (selectableColumn) => selectableColumn.headerName,
    );

    return (
      ArrayUtils.getOverlappingValues(
        this.props.selectedColumns,
        selectableColumns,
      ).length === 0
    );
  }

  getSelectedColumns() {
    const selectedColumns = cloneDeep(
      this.props.selectedColumns
        .map((selectedColumn) =>
          this.props.selectableColumns.find(
            (selectableColumn) =>
              selectableColumn.headerName === selectedColumn,
          ),
        )
        .filter((selectedColumn) => selectedColumn?.field),
    );

    // The renderCell callback must be set here again because functions are not working anymore when retrieving data from redux store.
    for (const selectedColumn of selectedColumns) {
      if (selectedColumn.field === 'date') {
        selectedColumn.valueGetter = parseDate;
        selectedColumn.renderCell = ({ value }) =>
          dateUtils.getFormattedDate_safe(
            value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
            dateUtils.DATE_FORMAT.YYYY_MM_DD,
          );
      }
    }

    return selectedColumns;
  }

  getColumns() {
    let maxPrecision = 0;

    for (const row of this.props.data) {
      const p = precision(row.value);

      if (p > maxPrecision && p <= 2) {
        maxPrecision = p;
      }
    }

    const selectedColumns = this.getSelectedColumns();

    selectedColumns.push(
      {
        field: 'value',
        headerName: 'Menge',
        flex: 2,
        sortable: true,
        resizableText: true,
        disableReorder: true,
        type: 'number',
        renderCell: (params) =>
          UnitUtils.formatDeWithPrecision_safe(
            params.value,
            maxPrecision,
            maxPrecision,
          ),
      },
      {
        field: 'unit',
        headerName: 'Einheit',
        flex: 1,
        sortable: true,
        resizableText: true,
        disableReorder: true,
        renderCell: (params) => UnitUtils.getAbbreviatedUnit(params.value),
      },
    );

    return selectedColumns;
  }

  onExcelDlnExport = (downloadOption) => {
    const dlnIds = ArrayUtils.removeDuplicates(
      this.props.data.map((item) => item.dlnId),
    );

    if (!dlnIds) {
      return;
    }

    ExportService.exportDeliveryNotes(dlnIds, downloadOption);
  };

  render() {
    if (FeatureService.clientPortal()) {
      return (
        <div className="flex-c-c flex-1">
          <ClientPortalMessage />
        </div>
      );
    }

    if (FeatureService.packageBasicRestriction()) {
      return (
        <div className="flex-c-c flex-1">
          <PackageBasicRestrictionMessage />
        </div>
      );
    }

    // convert date to string with format YYYY.MM.DD so that data is aggregated on a daily basis
    const refinedData = this.props.data.map((item) => {
      return {
        ...item,
        date: dateUtils.getFormattedDate_safe(
          item.date,
          dateUtils.DATE_FORMAT.YYYY_MM_DD,
          dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
        ),
      };
    });

    const isWeight = UnitUtils.isWeightUnit(this.props.selectedUnit);
    const unitKey = isWeight ? 'weightUnit' : 'amountUnit';
    const valueKey = isWeight ? 'weightValue' : 'amountValue';

    const columns = [
      ...this.getSelectedColumns().map(
        (selectedColumn) => selectedColumn.field,
      ),
      unitKey,
    ];

    const aggregatedData = DashboardService.aggregateValues(
      refinedData,
      columns,
      valueKey,
      true,
    ).map((item) => {
      // Map the unit and value back to the default 'unit' and 'value' keys
      item.unit = item[unitKey];
      item.value = item[valueKey];

      delete item[unitKey];
      delete item[valueKey];

      return {
        ...item,
      };
    });

    // parse unit and date to human-readable formats
    const excelData = aggregatedData.map((item) => {
      const newItem = { ...item }; // cloning object is needed to not modify aggregatedData by reference

      if (newItem[unitKey]) {
        newItem.unit = UnitUtils.getAbbreviatedUnitString(newItem[unitKey]);
      }

      delete newItem[unitKey];
      newItem.date &&= dateUtils.getFormattedDate_safe(
        newItem.date,
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
        dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
      );

      return newItem;
    });

    return (
      <div className="flex flex-1 flex-col">
        <DashboardReportChips
          selectedColumns={this.props.selectedColumns}
          selectableColumns={this.props.selectableColumns}
          onClick={this.onClick}
          onReorderColumns={this.onReorderColumns}
        />
        {this.noColumnIsSelected() ? (
          <div className="mt-10px">
            * Bitte wähle mindestens eine Spalte (Status, LFS-Datum, ...) aus,
            um die Berichte-Funktion zu verwenden.
          </div>
        ) : null}
        <div className="pt-30px max-h-[600px] min-h-[400px] flex-1">
          <BasicTable
            rows={aggregatedData}
            columns={this.getColumns()}
            disableColumnReorder
            excelData={excelData}
            excelColumns={this.getColumns()}
            onExcelDlnExport={this.onExcelDlnExport}
            multiplePdfDownload={this.props.data.length > 1}
            disableRowSelectionOnClick
            loading={this.props.deliveryNotes.deliveryNotesLoading}
            localStorageKey={LocalStorageService.DASHBOARD_REPORT}
            productAnalyticsFeature={Log.FEATURE.REPORT}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(DashboardReport);
