import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useQueryUserFull } from '~/data/user';

import Company from '~/models/masterdata/Company';

import User from '~/models/masterdata/User';

import Log from '~/utils/Log';

import {
  getDefaultSignatureRoleOptions,
  handleSubmit as onSubmit,
  onChange,
} from './utils';

const selectCompanies = (state) => state.companies.companies;
const selectUserinfo = (state) => state.userinfo;

export const useUserForm = ({
  closeForm,
  onOpenOrganisationalGroup,
  onRefreshSubjects,
  type,
  userCompanyId,
  userId,
}) => {
  const isCreatingUser = type === 'create';

  const companies = useSelector(selectCompanies);
  const userinfo = useSelector(selectUserinfo);

  const {
    data: user,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchUser,
  } = useQueryUserFull(userId, userCompanyId);

  const getDefaultUser = () => {
    const user = new User();

    user.company =
      companies.find(({ id }) => id === userinfo.userinfo.company?.id) ??
      new Company({
        id: userinfo.userinfo.company?.id,
      });

    return user;
  };

  const [state, setState] = useState({
    isSubmitting: false,
    user: isCreatingUser ? getDefaultUser() : user,
  });

  const getUnsavedChanges = () => {
    if (isCreatingUser) {
      return [];
    }

    if (isLoading || isError) {
      return [];
    }

    return User.getDifferentValues(user, state.user);
  };

  const handleUpdateUser = (newUser) => {
    setState((prevState) => ({
      ...prevState,
      user: newUser,
    }));
  };

  const handleChangeOrganisationalGroups = (organisationalGroups) => {
    setState((prevState) => {
      const newUser = cloneDeep(prevState.user);

      newUser.organisationalGroups = organisationalGroups.map(({ id }) => id);

      Log.info(
        'Change form value of organisational groups',
        {
          from: state.user.organisationalGroups,
          to: newUser.organisationalGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change organisational groups',
        Log.FEATURE.USER,
      );

      return {
        ...prevState,
        user: newUser,
      };
    });
  };

  const handleChange = (event) => {
    onChange(event, state.user, handleUpdateUser);
  };

  const handleOpenOrganisationalGroup = (organisationalGroup) =>
    onOpenOrganisationalGroup(organisationalGroup, getUnsavedChanges());

  const handleChangeCompany = (companyId) => {
    setState((prevState) => {
      const newUser = cloneDeep(prevState.user);
      newUser.company = new Company({ id: companyId });

      Log.info(
        'Change form value of company',
        {
          from: state.user.company,
          to: newUser.company,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change company', Log.FEATURE.USER);

      return {
        ...prevState,
        user: newUser,
      };
    });
  };

  const handleChangeDefaultSignatureRole = (event) => {
    setState((prevState) => {
      const newUser = cloneDeep(prevState.user);
      newUser.signatureRoles.defaultSignatureRole = event.target.value;

      Log.info(
        'Change form value of default signature role',
        {
          from: state.user.signatureRoles.defaultSignatureRole,
          to: newUser.signatureRoles.defaultSignatureRole,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change default signature role',
        Log.FEATURE.USER,
      );

      return {
        ...prevState,
        user: newUser,
      };
    });
  };

  const resetForm = useCallback(() => {
    const newUser = user ? cloneDeep(user) : getDefaultUser();

    setState((prevState) => ({
      ...prevState,
      user: newUser,
    }));
  }, [user]);

  const resetDefaultValues = () =>
    setState((prevState) => {
      const newUser = cloneDeep(prevState.user);
      if (!newUser.company.id) {
        newUser.company =
          companies.find(({ id }) => id === userinfo.userinfo.company?.id) ??
          new Company({
            id: userinfo.userinfo.company?.id,
          });
      }

      return {
        ...prevState,
        user: newUser,
      };
    });

  const resetDefaultSignatureRole = () =>
    setState((prevState) => {
      const newUser = cloneDeep(prevState.user);

      if (newUser?.signatureRoles?.noSignatureRoleIsSelected?.()) {
        newUser.signatureRoles.defaultSignatureRole = null;
      } else if (
        !getDefaultSignatureRoleOptions(newUser.signatureRoles).some(
          ({ id }) => id === newUser.signatureRoles.defaultSignatureRole,
        )
      ) {
        newUser.signatureRoles.defaultSignatureRole =
          getDefaultSignatureRoleOptions(newUser.signatureRoles)[0]?.id ?? null;
      }

      return {
        ...prevState,
        user: newUser,
      };
    });

  useEffect(() => {
    resetForm();
  }, [isError, isSuccess]);

  useEffect(() => {
    resetDefaultValues();
  }, [isError, isSuccess, companies]);

  useEffect(() => {
    resetDefaultSignatureRole();
  }, [JSON.stringify(state.user?.signatureRoles)]);

  const handleSubmit = (event) => {
    onSubmit({
      closeForm,
      event,
      isCreatingUser,
      originalData: user,
      refetchUser,
      refetchUsers: onRefreshSubjects,
      resetForm,
      setState,
      user: state.user,
    });
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.USER);

    closeForm();
    resetForm();
  };

  return {
    getDefaultSignatureRoleOptions,
    getUnsavedChanges,
    handleCancel,
    handleChange,
    handleChangeCompany,
    handleChangeDefaultSignatureRole,
    handleChangeOrganisationalGroups,
    handleOpenOrganisationalGroup,
    handleSubmit,
    handleUpdateUser,
    isCreatingUser,
    isError,
    isLoading,
    isSubmitting: state.isSubmitting,
    isSuccess,
    refetchUser,
    user: state.user,
  };
};
