import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
} from '@mui/x-data-grid-pro';

import { ComponentTestIds } from '~/constants/test-ids';

export const Pagination = () => {
  const apiRef = useGridApiContext();

  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const firstRow = page * pageSize + 1;
  const lastRow = Math.min((page + 1) * pageSize, rowCount);

  const handlePageChange = (newPage: number) => (event: React.MouseEvent) => {
    event.preventDefault();

    apiRef.current.setPage(newPage);
  };

  const hasNextPage = page < pageCount - 1;
  const isFetchingNextPage = false; // TODO: get from props
  const hasPreviousPage = page > 0;
  const isFetchingPreviousPage = false; // TODO: get from props

  return (
    <Box
      data-testid={ComponentTestIds.TABLE.PAGINATION}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
      px={2}
    >
      <Typography variant="body2" className="user-select-none">
        {`${firstRow}-${lastRow} von ${rowCount}`}
      </Typography>
      <IconButton
        onMouseDown={handlePageChange(page - 1)}
        disabled={!hasPreviousPage || isFetchingPreviousPage}
        aria-label="Previous page"
      >
        <KeyboardArrowLeftIcon fontSize="small" />
      </IconButton>
      <IconButton
        onMouseDown={handlePageChange(page + 1)}
        disabled={!hasNextPage || isFetchingNextPage}
        aria-label="Next page"
      >
        <KeyboardArrowRightIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
