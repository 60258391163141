import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import LocalStorageService from '~/services/localStorage.service';
import MapperService from '~/services/mapper.service';

import Address from '~/models/masterdata/Address';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Site from '~/models/masterdata/Site';

import { dateUtils } from '~/utils/dateUtils';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../../SettingsTable';

import { columns } from './constants';

// Memoized selectors using createSelector
const selectSites = (state) => state.sites?.sites || [];
const selectCompanies = (state) => state.companies?.companies || [];
const selectCostCenters = (state) => state.costCenters?.costCenters || [];

const selectCostCentersMap = createSelector(
  [selectCostCenters],
  (costCenters) => new Map(costCenters.map(({ id, name }) => [id, name])),
);

const selectCompaniesMap = createSelector(
  [selectCompanies],
  (companies) => new Map(companies.map(({ id, name }) => [id, name])),
);

const SiteTableRaw = () => {
  const sites = useSelector(selectSites);
  const sitesLoading = useSelector((state) => state.sites?.sitesLoading);
  const companiesMap = useSelector(selectCompaniesMap);
  const costCentersMap = useSelector(selectCostCentersMap);

  const getCompany = useCallback(
    (company) => companiesMap.get(company),
    [companiesMap],
  );

  const getCostCenters = useCallback(
    (costCenters) => {
      if (!costCenters?.length) return '';
      return costCenters
        .map((costCenterId) => costCentersMap.get(costCenterId) ?? '')
        .join(', ');
    },
    [costCentersMap],
  );

  const getSearchableRow = useMemo(
    () =>
      memoize((row) =>
        MapperService.addSearchString(row, [
          'id',
          'name',
          'type',
          'costCenters',
          'address',
          'company',
          'coords',
        ]),
      ),
    [],
  );

  const rows = useMemo(() => {
    const newSites = ES6ClassFactory.convertToES6Class(sites, new Site());

    return newSites.map((site) => {
      const row = {
        id: site.id,
        name: site.name,
        type: Site.getSiteType(site.type),
        active: site.active ? Site.IS_ACTIVE.YES : Site.IS_ACTIVE.NO,
        costCenters: getCostCenters(site.costCenters),
        address: Address.getConcatenatedAddress(site.address),
        company: getCompany(site.company),
        coords:
          site.coords?.latitude && site.coords?.longitude
            ? `${site.coords.latitude}, ${site.coords.longitude}`
            : '',
        start: dateUtils.getFormattedDate_safe(
          site.start,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ),
        end: dateUtils.getFormattedDate_safe(
          site.end,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ),
      };

      return getSearchableRow(row);
    });
  }, [getCompany, getCostCenters, getSearchableRow, sites]);

  return (
    <SettingsTable
      entity="site"
      title="Standorte"
      rows={rows}
      columns={columns}
      items={sites}
      loading={sitesLoading}
      multiPermissionGrantDefaultEntityType={
        PermissionGrant.ENTITY_TYPE.SITE.KEY
      }
      multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
      localStorageKey={LocalStorageService.SITE_TABLE}
      hasActiveStateFilter
      hasMultiPermissionGrantEditing
    />
  );
};

// Use memo to prevent unnecessary re-renders
export const SiteTable = withErrorBoundary(
  memo(SiteTableRaw),
  'Standorte konnten nicht geladen werden.',
);
