import { type UUID } from '~/types/common';

import { type OrganizationalUnitsQueryParams } from './useQueryOrganizationalUnits';

/**
 * Query key factory for organizational unit-related queries.
 */
export const queryKeysOrganizationalUnit = {
  base: () => 'organizationalUnits' as const,
  get: (organizationalUnitId: UUID) =>
    [
      queryKeysOrganizationalUnit.base(),
      'detail',
      { organizationalUnitId },
    ] as const,
  getAll: (queryParams: Partial<OrganizationalUnitsQueryParams>) =>
    [queryKeysOrganizationalUnit.base(), 'list', queryParams] as const,
} as const;
