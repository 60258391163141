import urlcat from 'urlcat';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

/**
 * Paths for all endpoints of the VESTIGAS API used in the application.
 */
export const ENDPOINT = {
  COMPANY: {
    GET: (companyId: UUID): string =>
      urlcat('company/:companyId', { companyId }),
    GET_ALL: 'company',
    GET_LOGO: (companyId: UUID): string => `company/${companyId}/logo`,
  },
  ORGANIZATIONAL_UNIT: {
    ADD: (organizationalUnitId: UUID, entityType: EntityType): string =>
      urlcat('access_control/org_unit/:organizationalUnitId/:entityType', {
        organizationalUnitId,
        entityType,
      }),
    DELETE: (
      organizationalUnitId: UUID,
      entityType: EntityType,
      entityId: UUID,
    ): string =>
      urlcat(
        'access_control/org_unit/:organizationalUnitId/:entityType/:entityId',
        {
          organizationalUnitId,
          entityType,
          entityId,
        },
      ),
    GET: (organizationalUnitId: UUID): string =>
      urlcat('access_control/org_unit/:organizationalUnitId', {
        organizationalUnitId,
      }),
    GET_ALL: 'access_control/org_unit/test_all',
  },
  USER: {
    GET: (userId: UUID): string => urlcat('user/:userId', { userId }),
    GET_ALL: 'user',
    GET_PUSH_NOTIFICATION_SETTINGS: (userId: UUID): string =>
      urlcat('user/notification/settings', { user_id: userId }), // endpoint uses query parameter 'user_id'!
    GET_SETTINGS: (userId: UUID): string =>
      urlcat('admin/user/:userId/settings', { userId }),
    GET_SIGNATURE_ROLES: (userId: UUID): string =>
      urlcat('user/:userId/signature_roles', { userId }),
    UPDATE: (userId: UUID): string => urlcat('admin/users/:userId', { userId }),
    UPDATE_SETTINGS: (userId: UUID): string =>
      urlcat('admin/user/:userId/settings', { userId }),
    UPDATE_SIGNATURE_ROLES: (userId: UUID): string =>
      urlcat('user/:userId/signature_roles', { userId }),
    UPDATE_PUSH_NOTIFICATION_SETTINGS: (userId: UUID): string =>
      urlcat('user/notification/settings', { user_id: userId }), // endpoint uses query parameter 'user_id'!
  },
  USER_GROUP: {
    GET: (userGroupId: UUID): string =>
      urlcat('access_control/user_group/:userGroupId', { userGroupId }),
    GET_ALL: 'access_control/user_group/test_all',
  },
  VEHICLE: {
    GET: (vehicleId: UUID): string =>
      urlcat('vehicle/:vehicleId', { vehicleId }),
    GET_ALL: 'vehicle',
  },
  SITE: {
    GET_ALL: 'site',
    // Add other site-related endpoints as needed
  },
} as const;
