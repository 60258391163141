import React from 'react';
import { connect } from 'react-redux';
import { SettingsTable } from '../../SettingsTable';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import UserGroup from '~/models/masterdata/UserGroup';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import UserGroupService from '~/services/userGroup.service';
import MapperService from '~/services/mapper.service';

const columns = [
  {
    field: 'name',
    headerName: 'Benutzer-Gruppe',
    width: 400,
    sortable: true,
    resizableText: true,
    filterOperators: getGridStringOperators().filter(
      (op) => op.value === 'contains',
    ),
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
    filterable: false,
  },
  // { field: 'minPathDepth', headerName: 'Ebene', width: 100, sortable: true, resizableText: true },
];

const mapStateToProps = (state) => ({
  userGroups: state.userGroups,
});

class UserGroupTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData = () => {
    const newUserGroups = ES6ClassFactory.convertToES6Class(
      this.props.userGroups?.userGroups,
      new UserGroup(),
    );

    this.setState({
      rows: newUserGroups?.map((userGroup) => {
        const row = {
          id: userGroup.id,
          name: userGroup.name,
        };

        return MapperService.addSearchString(row, ['id', 'name']);
      }),
    });
  };

  render() {
    return (
      <SettingsTable
        entity="userGroup"
        title="Benutzer-Gruppen"
        rows={this.state.rows}
        columns={columns}
        items={this.props.userGroups.userGroups}
        loading={this.props.userGroups.userGroupsLoading}
        refreshData={UserGroupService.refreshUserGroups}
        multiPermissionGrantDefaultSubjectType={
          PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.SUBJECT}
        localStorageKey={LocalStorageService.USER_GROUP_TABLE}
        hasMultiPermissionGrantEditing
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(UserGroupTable),
  'Benutzer-Gruppen konnten nicht geladen werden.',
);
