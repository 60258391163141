import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',

    '& *': {
      boxSizing: 'border-box',
      fontFamily: 'Inter',
    },
  },

  textField: {
    backgroundColor: 'white',
    height: '3rem',
    borderRadius: '0.5rem',
    width: 300,

    '& .MuiInputAdornment-positionEnd': {
      color: '#C5C9D6',

      '& .MuiSvgIcon-root': {
        margin: `0 !important`,
        height: '1.5rem',
      },
    },

    '& .MuiIconButton-root': {
      padding: 0,
    },

    '& .MuiInputBase-root': {
      height: '100%',

      '&::before': {
        display: 'none',
      },

      '&::after': {
        display: 'none',
      },
    },
  },

  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 0,
    position: 'relative',
    padding: '2rem',

    '& .filter-item': {
      position: 'relative',
    },

    '& .left-filters': {
      '& .MuiChip-root': {
        color: '#8D94A8',
        borderRadius: '1.5rem',
        padding: `0 0.75rem !important`,
        height: '2.5rem',
        borderColor: '#C5C9D6',

        '&[data-active="true"]': {
          backgroundColor: '#E4E6ED',
          borderColor: '#343A4D',

          '& .MuiChip-label': {
            color: '#343A4D',
            fontWeight: 600,
          },

          '& .MuiSvgIcon-root': {
            color: '#343A4D ',
          },
        },

        '& .MuiChip-label': {
          fontSize: '1rem',
          color: '#5D647A',
        },

        '& .MuiChip-avatar': {
          margin: 0,
          width: 20,
          height: 20,
          color: '#8D94A8',
        },
      },
    },

    '& .right-filters': {
      '& .excel-icon': {
        width: '3rem',
        height: '3rem',
        borderRadius: '0.5rem',
        border: '1px solid #C5C9D6',
      },

      '& .form-icon': {
        width: '3rem',
        height: '3rem',
        borderRadius: '0.5rem',
        border: '1px solid #C5C9D6',

        '& .MuiSvgIcon-root': {
          margin: 0,
        },
      },

      '& .MuiButtonBase-root': {
        height: 48,
        textTransform: 'unset',
        fontWeight: 600,
        fontSize: '1rem',
        borderRadius: '0.5rem',

        '& .MuiSvgIcon-root': {
          marginRight: '0.8125rem',
        },
      },
    },
  },

  kanbanContainer: {
    paddingTop: '1rem',
    paddingLeft: '1rem',
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 'calc(100vh - 165px)',
    position: 'relative',

    '&::before': {
      content: '""',
      pointerEvents: 'none',
      zIndex: 9,
      position: 'fixed',
      bottom: '0',
      left: '0',
      right: '0',
      height: '8rem',
      background:
        'linear-gradient(360deg,#fff 0,hsla(0,0%,100%,.738) 19%,hsla(0,0%,100%,.541) 34%,hsla(0,0%,100%,.382) 47%,hsla(0,0%,100%,.278) 56.5%,hsla(0,0%,100%,.194) 65%,hsla(0,0%,100%,.126) 73%,hsla(0,0%,100%,.075) 80.2%,hsla(0,0%,100%,.042) 86.1%,hsla(0,0%,100%,.021) 91%,hsla(0,0%,100%,.008) 95.2%,hsla(0,0%,100%,.002) 98.2%,hsla(0,0%,100%,0))',
    },

    '&.is-loading-indicator': {
      overflow: 'hidden',
    },

    '& .kanban-divider': {
      height: '3.25rem',
    },

    '& .loading-indicator': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      zIndex: 99,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
