import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';
import { type UserSignatureRoles } from '~/types/userSignatureRoles';

import Log from '~/utils/Log';

import { queryKeysUser } from './queryKeys';

/**
 * Fetches user signature roles data from the API.
 * @param {UUID} userId - The ID of the user to fetch.
 * @returns {Promise<UserSignatureRoles|null>} The user data if successful, null otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/get_user_permitted_signature_roles_user__user_uuid__signature_roles_get
 */
export const fetchUserSignatureRoles = async (userId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER.GET_SIGNATURE_ROLES(userId))
      .json<UserSignatureRoles>();

    return response;
  } catch (error) {
    Log.error('Error fetching user signature roles', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for fetching user signature roles.
 *
 * @param {string} userId - The ID of the user.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query result.
 */
export const useQueryUserSignatureRoles = (
  userId: UUID,
  options: Parameters<typeof useQuery>[0],
) =>
  useQuery({
    queryKey: queryKeysUser.signatureRoles(userId),
    queryFn: async () => fetchUserSignatureRoles(userId),
    ...options,
  });
