import React, { useState, useCallback, memo } from 'react';

import { useQueryOrganizationalUnits } from '~/data/organizationalUnit';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import LocalStorageService from '~/services/localStorage.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { selectOrganizationalUnits } from './selectOrganizationalUnits';

const columns = [
  {
    field: 'name',
    headerName: 'Organisations-Gruppe',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
    filterable: false,
  },
];

const OrganizationalUnitsTableComponent = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  const [searchString, setSearchString] = useState(undefined);

  const { data, isFetching, isPending, isPlaceholderData, refetch } =
    useQueryOrganizationalUnits(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        orderBy: sortModel[0]?.field,
        sort: sortModel[0]?.sort,
        searchString,
      },
      {
        select: selectOrganizationalUnits,
      },
    );

  const handlePaginationModelChange = useCallback((newModel) => {
    setPaginationModel(newModel);
  }, []);

  const handleSortModelChange = useCallback((newModel) => {
    setSortModel(newModel);
  }, []);

  const handleSearchStringChange = useCallback((newSearchString) => {
    // Reset to first page when search string changes
    setPaginationModel((previousModel) => ({
      ...previousModel,
      page: 0,
    }));
    setSearchString(newSearchString);
  }, []);

  return (
    <SettingsTable
      entity="organisationalGroup"
      title="Organisations-Gruppen"
      rows={data?.rows ?? []}
      columns={columns}
      items={data?.organisationalGroups ?? []}
      loading={isPending || (isFetching && isPlaceholderData)}
      multiPermissionGrantDefaultEntityType={
        PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
      }
      multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
      onPaginationModelChange={handlePaginationModelChange}
      onSearchStringChange={handleSearchStringChange}
      onSortModelChange={handleSortModelChange}
      paginationModel={paginationModel}
      refreshData={refetch}
      sortModel={sortModel}
      totalRowCount={data?.totalCount ?? 0}
      hasMultiPermissionGrantEditing
    />
  );
};

export const OrganizationalUnitsTable = withErrorBoundary(
  memo(OrganizationalUnitsTableComponent),
  'Organisations-Gruppen konnten nicht geladen werden.',
);
