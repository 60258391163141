import { useState, useRef } from 'react';
import {
  Add as AddIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { Button, ButtonGroup } from '@mui/material';

import CostCenterService from '~/services/costCenter.service';
import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import CSVValidator from '~/models/masterdata/CSVValidator';
import Site from '~/models/masterdata/Site';
import User from '~/models/masterdata/User';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import BrowserUtils from '~/utils/browserUtils';

import { BurgerMenu } from '../BurgerMenu';
import { Spinner } from '../Spinner';

export const SettingsCSVUpload = ({ entity, openCreateForm }) => {
  const [isCSVUploading, setIsCSVUploading] = useState(false);
  const [controlledCSVValue, setControlledCSVValue] = useState(''); // uncontrolled inputs don't allow selecting the same image twice
  const [csvUploadMode, setCsvUploadMode] = useState(null);

  const inputFile = useRef(null);

  const handleClick = (event) => {
    switch (event) {
      case 'csv-upload-with-cost-centers': {
        Log.info('Upload CSV with cost centers');
        Log.productAnalyticsEvent(
          'Upload CSV with cost centers',
          Log.FEATURE.SETTINGS_TABLE,
        );
        inputFile.current.click();
        setCsvUploadMode('csv-upload-with-cost-centers');
        break;
      }

      case 'csv-upload': {
        Log.info('Upload CSV');
        Log.productAnalyticsEvent('Upload CSV', Log.FEATURE.SETTINGS_TABLE);
        inputFile.current.click();
        setCsvUploadMode('csv-upload');
        break;
      }

      case 'csv-rules-examples': {
        Log.info('Open CSV rules and examples');
        Log.productAnalyticsEvent(
          'Open CSV rules and examples',
          Log.FEATURE.SETTINGS_TABLE,
        );
        BrowserUtils.openNewTab(
          'https://www.notion.so/vestigas/README-26-01-2024-5cda2267e5794ed1bf62fa8e74dca27d',
        );
        break;
      }
    }
  };

  const handleCSVFileUploadSubmit = async (event) => {
    setIsCSVUploading(true);

    let csvCallback = null;

    switch (entity) {
      case 'user': {
        csvCallback = UserService.createUsersViaCSV;
        break;
      }

      case 'site': {
        if (csvUploadMode === 'csv-upload-with-cost-centers') {
          csvCallback = SiteService.createSitesAndCostCentersViaCSV;
        }

        if (csvUploadMode === 'csv-upload') {
          csvCallback = SiteService.createSitesViaCSV;
        }

        break;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid entity type: ' + entity),
        );
      }
    }

    if (!csvCallback) {
      setIsCSVUploading(false);
      return;
    }

    const file = event.target.files[0];

    let allowedColumns = [];

    if (entity === 'user') {
      allowedColumns = User.CSV_ALLOWED_COLUMNS;
    }

    if (entity === 'site' && csvUploadMode === 'csv-upload-with-cost-centers') {
      allowedColumns = Site.CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS;
    }

    if (entity === 'site' && csvUploadMode === 'csv-upload') {
      allowedColumns = Site.CSV_ALLOWED_COLUMNS;
    }

    const csvValidator = new CSVValidator(allowedColumns, file);

    const [, error] = await promiseHandler(csvValidator.initFile());

    if (error) {
      Log.error('Failed to upload CSV.', error);
      Log.productAnalyticsEvent(
        'Failed to upload CSV',
        Log.FEATURE.SETTINGS_TABLE,
        Log.TYPE.ERROR,
      );
      ToastService.error(['CSV Datei konnte nicht hochgeladen werden.']);
      setIsCSVUploading(false);
      return;
    }

    const validationError = csvValidator.validate()[1];

    if (validationError) {
      Log.info('Invalid CSV: ' + validationError);
      Log.productAnalyticsEvent(
        'Invalid CSV: ' + validationError,
        Log.FEATURE.SETTINGS_TABLE,
      );
      ToastService.error([validationError]);
      setIsCSVUploading(false);
      return;
    }

    const [, error2] = await promiseHandler(csvCallback(file));

    if (error2) {
      if (error2.response.status === 409) {
        ToastService.httpError(
          [
            'Standorte und Kostenstellen konnten nicht angelegt werden, da eine Kostenstelle bereits im VESTIGAS System vorhanden ist.',
          ],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to upload CSV due to duplicate cost center',
          Log.FEATURE.SETTINGS_TABLE,
          Log.TYPE.ERROR,
        );
      } else {
        ToastService.httpError(
          ['CSV Datei konnte nicht hochgeladen werden.'],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to upload CSV',
          Log.FEATURE.SETTINGS_TABLE,
          Log.TYPE.ERROR,
        );
        Log.error('Failed to upload csv.', error2);
      }
    }

    setIsCSVUploading(false);
    setControlledCSVValue('');

    switch (entity) {
      case 'user': {
        UserService.refreshUsers();
        break;
      }

      case 'site': {
        SiteService.refreshSites();
        if (csvUploadMode === 'csv-upload-with-cost-centers') {
          CostCenterService.refreshCostCenters();
        }

        break;
      }

      default: {
        // Handle default case if needed
        break;
      }
    }
  };

  const getOptions = () => {
    const options = [];

    if (entity === 'site') {
      options.push({
        id: 'csv-upload-with-cost-centers',
        name: 'CSV Upload mit Kostenstellen',
      });
    }

    options.push(
      {
        id: 'csv-upload',
        name: 'CSV Upload',
      },
      {
        id: 'csv-rules-examples',
        name: 'CSV Regeln und Beispiele',
      },
    );

    return options;
  };

  return (
    <>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button
          className="primary-button"
          startIcon={isCSVUploading ? null : <AddIcon />}
          onClick={openCreateForm}
          disabled={isCSVUploading}
        >
          {isCSVUploading ? <Spinner title="Erstellen..." /> : 'Erstellen'}
        </Button>
        <BurgerMenu
          className="primary-button"
          id="settings-table-create-burger-menu"
          title={<KeyboardArrowDownIcon />}
          options={getOptions()}
          menuId="settings-table-create-burger-menu"
          onClick={handleClick}
          disabled={isCSVUploading}
        />
      </ButtonGroup>
      <input
        ref={inputFile}
        value={controlledCSVValue}
        type="file"
        className="hidden"
        accept=".csv"
        id="csv-file"
        onChange={handleCSVFileUploadSubmit}
      />
    </>
  );
};
