import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysVehicle } from './queryKeys';

type Vehicle = {
  id: UUID;
  licensePlate: string;
  companyId: UUID;
  orgUnits: UUID[];
  orgUnitPaths: UUID[][];
  permissionsFrom: UUID[];
  isActive: boolean;
};

/**
 * Fetches vehicle data from the API.
 * @param {string} vehicleId - The ID of the vehicle to fetch.
 * @returns {Promise<Object|null>} The vehicle data if successful, null otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/Vehicle/operation/get_vehicle_by_id_vehicle__id__get
 */
export const fetchVehicle = async (vehicleId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.VEHICLE.GET(vehicleId))
      .json<Vehicle>();

    return response;
  } catch (error) {
    Log.error('Error fetching vehicle', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for a vehicle with a given vehicleId.
 * @param {string} vehicleId - The ID of the vehicle to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryVehicle = (
  vehicleId: UUID,
  options: Parameters<typeof useQuery>[0],
) =>
  useQuery({
    queryKey: queryKeysVehicle.get(vehicleId),
    queryFn: async () => fetchVehicle(vehicleId),
    ...options,
  });
