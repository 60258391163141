import React, { memo, useCallback, useMemo, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { fetchCompanies, fetchCompany, queryKeysCompany } from '~/data/company';

import UserUtils from '~/utils/userUtils';

import { SelectServerDriven } from './SelectServerDriven';
import { createLoadOptions, createGetItemData } from './utils';

// Define a Company type based on the expected structure
type Company = {
  id: string;
  name: string;
};

type SelectCompanyProps = {
  className?: string;
  isDisabled?: boolean;
  label?: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  value?: string;
};

const getOptionLabel = ({ name }: Company) => name;

export const SelectCompany = memo(
  ({
    className,
    isDisabled = false,
    label = 'Firma',
    onChange,
    style,
    value,
  }: SelectCompanyProps) => {
    const queryClient = useQueryClient();

    const loadOptions = createLoadOptions<Company>({
      queryClient,
      createQueryKey: ({ page, ...queryParams }) =>
        queryKeysCompany.getAll(queryParams),
      async fetchItems({ page, ...queryParams }) {
        const { data, hasNextPage } = await fetchCompanies(queryParams);

        return {
          data,
          hasNextPage,
        };
      },
    });

    const getItemData = createGetItemData<Company>({
      queryClient,
      createQueryKey: (companyId) => queryKeysCompany.get(companyId),
      fetchItem: fetchCompany,
      queryKeyBase: [queryKeysCompany.base()],
    });

    const handleChange = useCallback(
      (newValue: string) => {
        onChange(newValue);
      },
      [onChange],
    );

    return (
      <SelectServerDriven<Company>
        value={value}
        onChange={handleChange}
        className={className}
        getItemData={getItemData}
        getOptionLabel={getOptionLabel}
        isDisabled={isDisabled}
        isItemSelectionAllowed={UserUtils.isCompanyReadAllowedUser}
        isMultiSelect={false}
        label={label}
        loadOptions={loadOptions}
        style={style}
      />
    );
  },
);
