import React from 'react';
import { connect } from 'react-redux';
import { VerifiedUser as VerifiedUserIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

import Wizard from '~/components/Wizard';
import { withErrorBoundary } from '~/ui/atoms';

import UpdatePermissionsWizardGrantPermissions from './UpdatePermissionsWizardGrantPermissions';
import UpdatePermissionsWizardDeletePermissions from './UpdatePermissionsWizardDeletePermissions';

const mapStateToProps = (state) => ({});

class UpdatePermissionsWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      submittingWizard: false,
      pickedSubjects: [], // We need to control this state on such a high level as we want to prefill the user list in the deletion step with the user from the granting step.
      grantPermissions: true,
      deletePermissions: true,
    };

    this.permissionGrantRef = React.createRef();
    this.permissionDeleteRef = React.createRef();
  }

  resetForm() {
    this.setState({
      pickedSubjects: [],
      grantPermissions: true,
      deletePermissions: true,
    });
  }

  onOpenWizard = () => {
    Log.productAnalyticsEvent(
      'Open update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      open: true,
    });
  };
  onCloseWizard = () => {
    Log.productAnalyticsEvent(
      'Close update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      open: false,
    });
  };
  setGrantPermissions = (grantPermissions) => {
    this.setState({
      grantPermissions,
    });
  };
  setDeletePermissions = (deletePermissions) => {
    this.setState({
      deletePermissions,
    });
  };
  setPickedSubjects = (pickedSubjects) => {
    this.setState({
      pickedSubjects,
    });
  };

  getWizardSteps() {
    const wizardSteps = [
      {
        title: 'Neue Berechtigungen vergeben',
        component: (
          <UpdatePermissionsWizardGrantPermissions
            ref={this.permissionGrantRef}
            grantPermissions={this.state.grantPermissions}
            setGrantPermissions={this.setGrantPermissions}
            pickedSubjects={this.state.pickedSubjects}
            setPickedSubjects={this.setPickedSubjects}
          />
        ),
      },
      {
        title: 'Bestehende Berechtigungen löschen',
        component: (
          <UpdatePermissionsWizardDeletePermissions
            ref={this.permissionDeleteRef}
            deletePermissions={this.state.deletePermissions}
            setDeletePermissions={this.setDeletePermissions}
            pickedSubjects={this.state.pickedSubjects}
            setPickedSubjects={this.setPickedSubjects}
          />
        ),
      },
    ];

    return wizardSteps.map((step) => {
      return {
        ...step,
        component: (
          <div key={step.title} className="mb-8 mt-10">
            {step.component}
          </div>
        ),
      };
    });
  }

  submitPermissions = async () => {
    this.setState({
      submittingWizard: true,
    });

    Log.info(
      'Submit update permissions wizard',
      null,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    if (this.state.grantPermissions) {
      const [response, error] = await promiseHandler(
        this.permissionGrantRef.current.submit(),
      );

      if (error) {
        Log.error('Failed to grant permissions.', error);
        ToastService.error([
          'Berechtigungen konnten nicht vollständig vergeben werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to grant permissions',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingWizard: false,
        });

        return false;
      }

      // Accessing response[0]?.value is a not clean solution.
      // Otherwise, we would have to flatten the promises in the permission grant pickers which makes the code in the child component a bit more complex.
      if (response[0]?.value?.length > 0) {
        ToastService.success(['Berechtigungen wurden vergeben.']);
      }
    }

    if (this.state.deletePermissions) {
      const [response2, error2] = await promiseHandler(
        this.permissionDeleteRef.current.submit(),
      );

      if (error2) {
        Log.error('Failed to delete permissions.', error2);
        ToastService.error([
          'Berechtigungen konnten nicht vollständig gelöscht werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to delete permissions',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingWizard: false,
        });

        return false;
      }

      if (response2.length > 0) {
        ToastService.success(['Berechtigungen wurden gelöscht.']);
      }
    }

    this.setState({
      open: false,
      submittingWizard: false,
    });

    this.resetForm();
    UserService.refreshUsers();

    return true;
  };

  getButton() {
    if (!UserUtils.isPermissionGrantAllowedUser()) {
      return (
        <MissingPermissionsTooltip>
          <Button
            className="disabled-primary-button"
            startIcon={<VerifiedUserIcon />}
            onClick={this.onOpenWizard}
            disabled
          >
            Berechtigungen anpassen
          </Button>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <Button
        className="primary-button"
        startIcon={<VerifiedUserIcon />}
        onClick={this.onOpenWizard}
      >
        Berechtigungen anpassen
      </Button>
    );
  }

  render() {
    return (
      <>
        {this.getButton()}
        <Wizard
          open={this.state.open}
          closeWizard={this.onCloseWizard}
          title="Berechtigungen anpassen"
          steps={this.getWizardSteps()}
          wizardSuccess={this.submitPermissions}
          submittingWizard={this.state.submittingWizard}
          fullWidth
        />
      </>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(UpdatePermissionsWizard),
  'Daten konnten nicht geladen werden.',
);
