import React, { memo, useEffect, useState } from 'react';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';

import FeatureService from '~/services/feature.service';
import LocalStorageService from '~/services/localStorage.service';
import UserService from '~/services/user.service';

import RouteUtils from '~/utils/routeUtils';
import Log from '~/utils/Log';
import { usePrevious } from '~/utils/customHooks';

import { JsonModal } from '../JsonModal';

import { MainNav } from './MainNav';
import { Routes } from './Routes';
import { TopBar } from './TopBar';
import { UnauthorizedPage } from './UnauthorizedPage';
import { useMainNavItems } from './useMainNavItems';

const selectBackdrop = createSelector(
  [(state) => state.backdrop],
  (backdrop) => ({
    open: backdrop.open,
    message: backdrop.message,
  }),
);

const selectUserinfo = createSelector(
  [(state) => state.userinfo],
  (userinfo) => ({
    userPermissions: userinfo.userPermissions,
  }),
);

export const AppLayout = memo(() => {
  const location = useLocation();

  const previousLocation = usePrevious(location);

  const backdrop = useSelector(selectBackdrop);
  const userinfo = useSelector(selectUserinfo);

  const [isNavCollapsed, setIsNavCollapsed] = useState(
    LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.HIDE_DRAWER,
    ),
  );

  const drawerWidthOpen = FeatureService.serviceNotes() ? 280 : 240;
  const drawerWidthClosed = 110;
  const drawerWidth = isNavCollapsed ? drawerWidthClosed : drawerWidthOpen;

  const mainNavItems = useMainNavItems();

  if (location.pathname !== previousLocation?.pathname) {
    try {
      Log.productAnalyticsEvent(
        RouteUtils.getBaseUrl(location.pathname),
        Log.FEATURE.NAVIGATION,
      );
    } catch (error) {
      Log.error('Failed to send page view to product analytics tool', error);
    }
  }

  // Enforce limit of toasts.
  const { toasts } = useToasterStore();
  useEffect(() => {
    for (const t of toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, index) => index >= 5)) {
      toast.dismiss(t.id); // Dismiss – Use toast.remove(t.id) removal without animation
    }
  }, [toasts]);

  return (
    <div className="flex h-screen w-screen">
      <Backdrop
        style={{
          zIndex: 10_000,
          backgroundColor: 'black',
          opacity: 0.8,
          color: 'white',
          fontSize: '25px',
        }}
        open={backdrop.open}
      >
        <CircularProgress color="inherit" className="mr-4" />
        {backdrop.message}
      </Backdrop>
      <Toaster
        toastOptions={{
          className: 'rounded-md max-w-60vw py-1 pl-1',
        }}
      />
      <CssBaseline />
      <TopBar drawerWidth={drawerWidth} />
      <MainNav
        drawerWidth={drawerWidth}
        isNavCollapsed={isNavCollapsed}
        items={mainNavItems}
        setIsNavCollapsed={setIsNavCollapsed}
      />
      <main
        className="will-change-width flex-1 overflow-auto"
        style={{
          height: `calc(100% - 63px)`,
          marginTop: '63px',
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        {UserService.userIsAuthorizedForPage(
          userinfo.userPermissions,
          location.pathname,
        ) ? (
          <Routes />
        ) : (
          <UnauthorizedPage />
        )}
      </main>
      <JsonModal />
    </div>
  );
});

AppLayout.displayName = 'AppLayout';
