import { useEffect, useState } from 'react';

import {
  ArrowForwardIos as ArrowForwardIosIcon,
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
} from '@mui/icons-material';

import Log from '~/utils/Log';
import { LOADING_STATE } from '~/constants/LoadingState';
import { Spinner } from '~/components/Spinner';
import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import ComponentUtils from '~/utils/componentUtils';
import { Popover } from '@mui/material';

export default function Path(props) {
  const [fullPath, setFullPath] = useState([]); // Contains the full path with all elements that are passed from the parent component.
  const [pathToTrackWraps, setPathToTrackWraps] = useState([]); // Needed to check when flex box wraps. Is extended incrementally with one element until flex box wraps.
  const [displayedPath, setDisplayedPath] = useState([]); // Is the path that is displayed to the user. All elements from fullPath are either displayed to the user or hidden in the popover.
  const [hiddenPath, setHiddenPath] = useState([]); // Contains the elements that would be too much. Are therefore hidden in the popover.
  const [wrappedIndex, setWrappedIndex] = useState(null); // Is needed to determine until which element the path should be displayed and which part should be hidden.
  const [anchorElement, setAnchorElement] = useState(null);
  const [loading, setLoading] = useState(LOADING_STATE.NOT_LOADED);
  const defaultName = '...';
  const wrappedItemsClassName = 'wrapped-items-class-name-' + props.id;

  useEffect(() => {
    initPath();
  }, [JSON.stringify(props.path), props.loading]);

  useEffect(() => {
    // Detect the wrap. In case of a wrap, set the index of the path element that causes the wrap.
    const wrappedItems = ComponentUtils.detectWrap(wrappedItemsClassName);
    if (wrappedItems.length > 0) {
      setWrappedIndex(pathToTrackWraps.length);
      return;
    }

    // Extend the path that is used to track when the wrap happens.
    if (fullPath.length > pathToTrackWraps.length) {
      setPathToTrackWraps(
        fullPath.slice(
          fullPath.length - pathToTrackWraps.length - 1,
          fullPath.length,
        ),
      );
    }

    // If the complete path doesn't wrap, display it to the user.
    if (fullPath.length === pathToTrackWraps.length) {
      setDisplayedPath(fullPath);
    }
  }, [JSON.stringify(fullPath), JSON.stringify(pathToTrackWraps)]);

  useEffect(() => {
    if (!wrappedIndex) {
      return;
    }

    // If the wrap has been detected, display the later part of the path and hide the first items in the popover.
    setDisplayedPath(
      fullPath.slice(fullPath.length - wrappedIndex + 1, fullPath.length),
    );
    setHiddenPath(fullPath.slice(0, fullPath.length - wrappedIndex + 2));
  }, [wrappedIndex]);

  // Load the name of the entities.
  const initPath = async () => {
    const path = [];

    setLoading(LOADING_STATE.LOADING);

    for (let index = 0; index < props.path.length; index++) {
      const entityId = props.path[index];

      const [entity, error] = await promiseHandler(
        props.getEntityById(entityId),
      );

      if (error) {
        Log.error('Failed to load entity: ' + props.path);
        Log.productAnalyticsEvent(
          'Failed to load entity in organisational or user group path',
          Log.FEATURE.OTHER_FEATURE,
          Log.TYPE.ERROR,
        );
        path.push(new props.model({ id: entityId, name: defaultName }));
        continue;
      }

      path.push(entity ?? new props.model({ id: entityId, name: defaultName }));
    }

    setFullPath(ArrayUtils.sortByKeyValues(path, props.path, 'id'));

    setLoading(LOADING_STATE.SUCCEEDED);
  };

  const onClick = (entity) => {
    // User isn't authorized to see entity.
    if (entity.name === defaultName) {
      return;
    }

    props.onOpenEntity(entity);
  };

  // If the hidden path has been set, it is clear that the first element in the path opens the popover.
  const pathContainsPopover = () => {
    return hiddenPath.length > 0;
  };

  const getPathElementsAsButtons = (path, openHiddenPath) => {
    return path.map((entity, index) => (
      <div
        className="on-hover-bg-grey200 rounded-5px p-8px cursor-pointer"
        // Open the popover if the clicked path element is the first one and the path contains a popover.
        onClick={(event) =>
          openHiddenPath && index === 0 && pathContainsPopover()
            ? setAnchorElement(event.currentTarget)
            : onClick(entity)
        }
      >
        {entity.name}
      </div>
    ));
  };

  if (props.loading === LOADING_STATE.LOADING) {
    return (
      <div className="flex h-14 w-full items-center justify-center rounded-md bg-gray-200 p-2">
        <Spinner />
      </div>
    );
  }

  if (displayedPath.length > 0) {
    // Display the to be displayed path.
    return (
      <>
        <div className="flex h-14 items-center gap-2 rounded-md bg-gray-200 p-2">
          <div className="flex w-full items-center gap-2">
            {getPathElementsAsButtons(displayedPath, true).map(
              (item, index) => (
                <div
                  key={displayedPath[index].id}
                  className="flex items-center gap-2"
                >
                  {index === 1 && pathContainsPopover() ? (
                    <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '20px' }} />
                  ) : null}
                  {index > 1 || (index === 1 && !pathContainsPopover()) ? (
                    <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
                  ) : null}
                  {item}
                </div>
              ),
            )}
          </div>
        </div>
        <Popover
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          onClose={() => setAnchorElement(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <div className="flex items-center gap-4 rounded-md bg-gray-200 p-2">
            <div className="flex w-full flex-wrap items-center gap-2">
              {getPathElementsAsButtons(hiddenPath).map((item, index) => (
                <div
                  key={hiddenPath[index].id}
                  className="flex items-center gap-2"
                >
                  {index > 0 ? (
                    <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
                  ) : null}
                  {item}
                </div>
              ))}
            </div>
          </div>
        </Popover>
      </>
    );
  }

  // Display the tracking path so that it can be detected whether a wrap would happen.
  return (
    <div className="flex items-center gap-2 rounded-md bg-gray-200 p-2">
      <div className="flex w-full flex-wrap items-center gap-2">
        {getPathElementsAsButtons(pathToTrackWraps).map((item, index) => (
          <div
            key={pathToTrackWraps[index].id}
            className={'flex items-center gap-2 ' + wrappedItemsClassName}
          >
            {index > 0 ? (
              <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
            ) : null}
            {item}
          </div>
        ))}
        {loading === LOADING_STATE.LOADING ? (
          <div className="flex h-9 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : null}
      </div>
    </div>
  );
}
