import React from 'react';

import {
  Close as CloseIcon,
  Info as InfoIcon,
  WarningRounded as WarningRoundedIcon,
} from '@mui/icons-material';

import toast from 'react-hot-toast';
import { IconButton } from '@mui/material';
import { ROUTE } from '~/constants/Route';
import ArrayUtils from '~/utils/arrayUtils';
import { FunctionalLink } from '~/utils/componentUtils';
import { v4 as uuidv4 } from 'uuid';
import Log from '~/utils/Log';

class ToastService {
  constructor() {
    this.MESSAGE = {
      UNAUTHORIZED_ERROR:
        'Daten konnten nicht synchronisiert werden, da du keine gültige Session hast.',
      UNAUTHORIZED_ERROR_REDIRECT: (
        <>
          Bitte logge dich aus und wieder ein. Weitere Informationen findest du
          in den {this.getLink('FAQs', ROUTE.SETTINGS_SUPPORT.ROUTE)}.
        </>
      ),
      COMPANY_LOGO_LOAD_FAILED: 'Firmenlogo konnte nicht geladen werden.',
      DLN_LOAD_FAILED: 'Lieferung konnte nicht vollständig geladen werden.',
      DLN_CHANGES_LOAD_FAILED:
        'Lieferungsänderungen konnten nicht geladen werden.',
      INVOICE_LOAD_FAILED: 'Rechnung konnte nicht geladen werden.',
      ARTICLE_OPEN_FAILED: 'Artikel kann nicht geöffnet werden.',
      VEHICLE_INVALID_INPUT:
        'Fahrzeug kann nicht angelegt werden, da die Eingabe des Kennzeichens ungültig ist.',
      VEHICLE_CREATION_FAILED: 'Fahrzeug konnte nicht angelegt werden.',
      VEHICLE_CREATION_FAILED_DUPLICATE:
        'Fahrzeug konnte nicht angelegt werden, da das Kennzeichen bereits im VESTIGAS System vorhanden ist.',
      VEHICLE_UPDATE_FAILED: 'Fahrzeug konnte nicht aktualisiert werden.',
      VEHICLE_UPDATE_FAILED_DUPLICATE:
        'Fahrzeug konnte nicht aktualisiert werden, da das Kennzeichen bereits im VESTIGAS System vorhanden ist.',
      SITE_REFERENCE_DELETION_FAILED:
        'Standortbezeichnung konnte nicht entfernt werden.',
      SITE_REFERENCE_DELETION_SUCCESSFUL:
        'Standortbezeichnung wurde erfolgreich entfernt.',
      USER_SIGNATURE_ROLE_UPDATE_FAILED:
        'Die Signaturberechtigung für den folgenden Benutzer konnte nicht aktualisiert werden:',
      USER_FEATURE_FLAGS_UPDATE_FAILED:
        'Die Feature-Flags für den folgenden Benutzer konnten nicht aktualisiert werden:',
      USER_DEFAULT_SIGNATURE_ROLE_UPDATE_FAILED:
        'Die Default-Signaturberechtigung für den folgenden Benutzer konnte nicht aktualisiert werden:',
      USER_PUSH_NOTIFICATIONS_UPDATE_FAILED:
        'Die Push Benachrichtigungen für den folgenden Benutzer konnte nicht aktualisiert werden:',
      USER_CREATION_FAILED: 'Benutzer konnte nicht angelegt werden.',
      USER_CREATION_FAILED_DUPLICATE:
        'Benutzer konnte nicht angelegt werden, da er bereits im VESTIGAS System vorhanden ist.',
      USER_UPDATE_FAILED: 'Benutzerdaten konnten nicht aktualisiert werden.',
      SITE_CREATION_FAILED: 'Standort konnte nicht angelegt werden.',
      SITE_UPDATE_FAILED: 'Standort konnte nicht aktualisiert werden.',
      COST_CENTER_CREATION_FAILED: 'Kostenstelle konnte nicht angelegt werden.',
      COST_CENTER_CREATION_FAILED_DUPLICATE:
        'Kostenstelle konnte nicht angelegt werden, da sie bereits im VESTIGAS System vorhanden ist.',
      COST_CENTER_UPDATE_FAILED:
        'Kostenstelle konnte nicht aktualisiert werden.',
      ACCOUNTING_REFERENCE_ASSIGNMENT_FAILED:
        'Kostenstelle konnte nicht geändert werden.',
      COMPANY_CREATION_FAILED: 'Firma konnte nicht angelegt werden.',
      COMPANY_UPDATE_FAILED: 'Firma konnte nicht aktualisiert werden.',
      COMPANY_LOGO_UPLOAD_FAILED:
        'Firmenlogo konnte nicht aktualisiert werden. Es werden die folgenden Datenformate unterstützt: .jpg und .png',
      COMPANY_LOGO_DELETION_FAILED: 'Firmenlogo konnte nicht entfernt werden.',
      CUSTOMER_SUBSCRIPTION_CREATION_FAILED:
        'Subscription konnte nicht angelegt werden.',
      CUSTOMER_SUBSCRIPTION_UPDATE_FAILED:
        'Subscription konnte nicht aktualisiert werden.',
      PDF_TEMPLATE_CREATION_FAILED: 'PDF Vorlage konnte nicht angelegt werden.',
      PDF_TEMPLATE_DOWNLOAD_FAILED:
        'PDF Vorlage konnte nicht heruntergeladen werden.',
      PDF_TEMPLATE_DELETION_FAILED:
        'PDF Template konnte nicht entfernt werden.',
      USER_TYPE_UPDATE_FAILED: 'Benutzertyp konnte nicht aktualisiert werden.',
      USER_PROFILE_PICTURE_UPDATE_FAILED:
        'Profilbild konnte nicht aktualisiert werden. Es werden die folgenden Datenformate unterstützt: .jpg und .png',
      USER_PROFILE_PICTURE_DELETION_FAILED:
        'Profilbild konnte nicht entfernt werden.',
      DLN_DOWNLOAD_NONE_SELECTED: 'Bitte wähle mindestens eine Lieferung aus.',
      DLN_DOWNLOAD_TOO_MANY_SELECTED:
        'Bitte wähle maximal 300 Lieferungen aus.',
      INV_DOWNLOAD_NONE_SELECTED: 'Bitte wähle mindestens eine Rechnung aus.',
      INV_DOWNLOAD_TOO_MANY_SELECTED: 'Bitte wähle maximal 100 Rechnungen aus.',
      PDF_DOWNLOAD_FAILED: 'PDF konnte nicht geladen werden.',
      INVOICE_RECEIVERS_LOAD_FAILED:
        'Rechnungsadressen konnten nicht geladen werden.',
      CONTACT_SUPPORT: (
        <>
          Bitte kontaktiere den{' '}
          {this.getLink('Support', ROUTE.SETTINGS_SUPPORT.ROUTE)}.
        </>
      ),
    };

    this.ID = {
      CREATE_DLN_INVALID_INPUT: 'create-dln-invalid-input',
      DLN_DOWNLOAD_WAIT: 'dln-download-wait',
      DLN_DOWNLOAD_NONE_SELECTED: 'dln-download-none-selected',
      DLN_DOWNLOAD_TOO_MANY_SELECTED: 'dln-download-too-many-selected',
      DLN_PAGE_CHANGE: 'dln-page-change',
      INV_DOWNLOAD_WAIT: 'inv-download-wait',
      INV_DOWNLOAD_NONE_SELECTED: 'inv-download-none-selected',
      INV_DOWNLOAD_TOO_MANY_SELECTED: 'inv-download-too-many-selected',
      SESSION_EXPIRED_LOGOUT: 'session-expired-logout',
      UNAUTHORIZED_ERROR: 'unauthorized-error',
      UNAUTHORIZED_WARNING: 'unauthorized-warning',
    };

    this.TYPE = {
      WARNING: 'warning',
    };

    this.alreadyShownDataLoadErrors = [];
    this.alreadyShownUpdatedDlns = [];

    this.dataLoadErrorTimers = [];
  }

  toastContent(lines, id) {
    const onClick = () => {
      Log.productAnalyticsEvent('Dismiss toast', Log.FEATURE.OTHER_FEATURE);
      toast.dismiss(id);
    };

    return (
      <span className="flex flex-row items-center">
        <div>
          {Array.isArray(lines) ? (
            lines.map((line, index) => {
              return <div key={index}>{line}</div>;
            })
          ) : (
            <div>{lines}</div>
          )}
        </div>
        <IconButton
          onClick={onClick}
          style={{ marginLeft: '10px' }}
          size="large"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </span>
    );
  }

  getBackdropLink(message, link, backdropDuration, backdropMessage) {
    return (
      <FunctionalLink
        to={link}
        message={message}
        backdropDuration={5000}
        backdropMessage={backdropMessage}
        className="main-text bold"
      />
    );
  }

  getLink(message, link) {
    return (
      <FunctionalLink to={link} message={message} className="main-text bold" />
    );
  }

  info(lines) {
    return toast((t) => this.toastContent(lines, t.id), {
      duration: 10_000,
      icon: <InfoIcon className="mr-5px text-primary300" />,
      style: {
        paddingLeft: '15px',
        backgroundColor: '#E4E6ED',
      },
      className: 'info-toast',
    });
  }

  warning(lines) {
    return toast((t) => this.toastContent(lines, t.id), {
      duration: 10_000,
      icon: <WarningRoundedIcon className="mr-5px text-warningBase" />,
      style: {
        paddingLeft: '15px',
        backgroundColor: '#E4E6ED',
      },
    });
  }

  error(lines) {
    return toast.error((t) => this.toastContent(lines, t.id), {
      duration: 15_000,
      style: {
        paddingLeft: '20px',
        backgroundColor: '#E4E6ED',
      },
    });
  }

  success(lines) {
    return toast.success((t) => this.toastContent(lines, t.id), {
      duration: 10_000,
      style: {
        paddingLeft: '20px',
        backgroundColor: '#E4E6ED',
      },
    });
  }

  promise(promise, loadingLines, successLines, errorLines, id) {
    id ??= uuidv4();

    return toast.promise(
      promise,
      {
        loading: () => this.toastContent(loadingLines, id),
        success: () => this.toastContent(successLines, id),
        error: () => this.toastContent(errorLines, id),
      },
      {
        id,
        duration: Infinity,
        style: {
          paddingLeft: '20px',
          backgroundColor: '#E4E6ED',
        },
        success: {
          duration: 10_000,
        },
        error: {
          duration: 10_000,
        },
      },
    );
  }

  dismiss(id) {
    toast.dismiss(id);
  }

  httpError(lines, errorResponse, type, withBackendDescription) {
    switch (errorResponse?.status) {
      case 401: {
        if (type === this.TYPE.WARNING) {
          this.warning(
            [
              this.MESSAGE.UNAUTHORIZED_ERROR,
              this.MESSAGE.UNAUTHORIZED_ERROR_REDIRECT,
            ],
            this.ID.UNAUTHORIZED_WARNING,
          );
        } else {
          this.error(
            [
              this.MESSAGE.UNAUTHORIZED_ERROR,
              this.MESSAGE.UNAUTHORIZED_ERROR_REDIRECT,
            ],
            this.ID.UNAUTHORIZED_ERROR,
          );
        }

        break;
      }

      case 403: {
        if (type === this.TYPE.WARNING) {
          this.warning(['Fehlende Berechtigung:', ...lines]);
        } else {
          this.error(['Fehlende Berechtigung:', ...lines]);
        }

        break;
      }

      case 400:
      case 404:
      case 405:
      case 500:
      case 502:
      case 503: {
        // prevent that user is spammed with data load errors
        if (this.isLoadMessage(lines[0])) {
          if (!this.alreadyShownDataLoadErrors.includes(lines[0])) {
            if (type === this.TYPE.WARNING) {
              this.warning([...lines, this.MESSAGE.CONTACT_SUPPORT]);
            } else {
              this.error([...lines, this.MESSAGE.CONTACT_SUPPORT]);
            }

            this.resetDataLoadErrorTimer(lines[0]);
          }
        } else if (type === this.TYPE.WARNING) {
          this.warning([...lines, this.MESSAGE.CONTACT_SUPPORT]);
        } else {
          this.error([...lines, this.MESSAGE.CONTACT_SUPPORT]);
        }

        break;
      }

      case 422: {
        if (Array.isArray(errorResponse?.data?.detail)) {
          if (errorResponse?.data?.detail)
            for (const validationError of errorResponse?.data?.detail) {
              lines.push(
                this.getValidationError(
                  validationError,
                  withBackendDescription,
                ),
              );
            }
        } else if (typeof errorResponse?.data?.detail.len === 'string') {
          lines.push(
            this.getValidationError(
              errorResponse.data.detail,
              withBackendDescription,
            ),
          );
        }

        if (type === this.TYPE.WARNING) {
          this.warning(lines);
        } else {
          this.error(lines);
        }

        break;
      }

      default: {
        if (type === this.TYPE.WARNING) {
          this.warning(lines);
        } else {
          this.error(lines);
        }

        break;
      }
    }
  }

  getValidationError(validationError, withBackendDescription) {
    switch (validationError.type) {
      case 'value_error.email': {
        return 'E-Mail Adresse ungültig.';
      }

      case 'value_error.custom_error': {
        return validationError.msg;
      }

      case 'value_error': {
        if (validationError.loc?.includes('latitude')) {
          return 'Breitengrad ungültig.';
        }

        if (validationError.loc?.includes('longitude')) {
          return 'Längengrad ungültig.';
        }

        break;
      }

      default: {
        if (withBackendDescription) {
          return (
            JSON.stringify(validationError.loc) +
            '\u00A0\u00A0→\u00A0\u00A0' +
            validationError.msg
          );
        }

        return 'Ungültige Eingabe eines Feldes.';
      }
    }
  }

  resetDataLoadErrorTimer(message) {
    this.alreadyShownDataLoadErrors.push(message);

    this.dataLoadErrorTimers[message] = setTimeout(
      function () {
        this.alreadyShownDataLoadErrors = ArrayUtils.remove(
          this.alreadyShownDataLoadErrors,
          message,
        );
      }.bind(this),
      2000,
    );
  }

  dlnInfo(lines, dlns, backdrop) {
    const now = Date.now();

    // to prevent duplicates, don't show dln in toast if it has been shown in the last 10 seconds
    dlns = dlns.filter((dln) => {
      let dlnIsNew = true;
      for (const alreadyShownDln of this.alreadyShownUpdatedDlns) {
        if (
          alreadyShownDln.id === dln.id &&
          now - alreadyShownDln.date <= 10_000
        ) {
          dlnIsNew = false;
        }
      }

      return dlnIsNew;
    });

    // remove all dlns from this.alreadyShownUpdatedDlns that are older that 10 seconds to prevent that it gets too big
    this.alreadyShownUpdatedDlns = this.alreadyShownUpdatedDlns.filter(
      (item) => {
        return !(now - item.date >= 10_000);
      },
    );

    this.alreadyShownUpdatedDlns = [
      ...this.alreadyShownUpdatedDlns,
      ...dlns.map((item) => {
        return {
          id: item.id,
          date: now,
        };
      }),
    ];

    if (dlns.length === 0) {
      return;
    }

    const dlnList = [];

    for (const [index, dln] of dlns.entries()) {
      if (index === 6) {
        dlnList.push(<div key={index}>...</div>);
        break;
      }

      if (backdrop) {
        dlnList.push(
          <div key={index}>
            {this.getBackdropLink(
              dln.dlnNr,
              ROUTE.DELIVERY_NOTE.ROUTE + '/' + dln.id,
              5000,
              'Lieferdaten werden synchronisiert',
            )}
          </div>,
        );
      } else {
        dlnList.push(
          <div key={index}>
            {this.getLink(dln.dlnNr, ROUTE.DELIVERY_NOTE.ROUTE + '/' + dln.id)}
          </div>,
        );
      }
    }

    this.info([...lines, dlnList]);
  }

  isLoadMessage(inputMessage) {
    const constMessage = Object.keys(this.MESSAGE).find(
      (x) => this.MESSAGE[x] === inputMessage,
    );
    return constMessage?.includes('LOAD');
  }
}

export default new ToastService();
