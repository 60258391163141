import clsx from 'clsx';

import { LOADING_STATE } from '~/constants/LoadingState';

import { cn } from '~/utils/tailwind';

import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

import LinkButton from '../LinkButton';

import Tile from './Tile';

const NumberCard = ({
  className,
  isLoading,
  label,
  style,
  value,
  valueClassName,
}) => {
  return (
    <div
      className={cn(
        'flex h-32 w-36 flex-col items-center justify-center rounded-md p-4 text-center font-bold',
        className,
      )}
      style={style}
    >
      <div
        className={cn(
          'flex h-6 flex-1 items-center justify-center text-5xl leading-6',
          valueClassName,
          {
            'text-4xl': value.toString().length > 4, // Reduce font size for long numbers
            'text-3xl': value.toString().length > 6,
          },
        )}
      >
        {isLoading ? <Spinner /> : value}
      </div>
      <div className="flex-shrink-0 leading-6 text-gray-400">{label}</div>
    </div>
  );
};

const NumberTile = ({
  buttonCallback,
  buttonLink,
  buttonTitle,
  firstNumber,
  firstNumberTitle,
  icon,
  loading,
  secondNumber,
  secondNumberTitle,
  subheader,
  title,
}) => {
  const isLoading = loading === LOADING_STATE.LOADING;

  return (
    <Tile
      title={title}
      icon={icon}
      buttonTitle={buttonTitle}
      buttonLink={buttonLink}
    >
      <div className="flex h-full flex-col items-center justify-between">
        <div className="text-gray-400">{subheader}</div>
        <div className="w-full">
          <div className="mb-30px flex items-center justify-between">
            <NumberCard
              value={firstNumber}
              label={firstNumberTitle}
              isLoading={isLoading}
              className="bg-secondary1"
            />
            <NumberCard
              value={secondNumber}
              label={secondNumberTitle}
              isLoading={isLoading}
              className={secondNumber > 0 ? 'bg-errorFFD0D1' : 'bg-gray-100'}
              valueClassName={secondNumber > 0 ? 'text-gray-600' : ''}
            />
          </div>
          <LinkButton
            title={buttonTitle}
            link={buttonLink}
            callback={buttonCallback}
            className="w-full"
          />
        </div>
      </div>
    </Tile>
  );
};

export default withErrorBoundary(NumberTile, null);
