import cloneDeep from 'lodash/cloneDeep';

import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';

export const onChange = (event, user, onChangeUser) => {
  const newUser = cloneDeep(user);

  const { name, value, type, checked } = event.target;
  const isCheckbox = type === 'checkbox';

  const fieldMap = {
    email: ['email'],
    firstName: ['firstName'],
    lastName: ['lastName'],
    password: ['password'],
    position: ['position'],
    isActive: ['isActive'],
    signatureRoleSupplier: ['signatureRoles', 'supplier'],
    signatureRoleCarrier: ['signatureRoles', 'carrier'],
    signatureRoleRecipient: ['signatureRoles', 'recipient'],
    signatureRoleOnBehalfSupplier: ['signatureRoles', 'onBehalfSupplier'],
    signatureRoleOnBehalfCarrier: ['signatureRoles', 'onBehalfCarrier'],
    signatureRoleOnBehalfRecipient: ['signatureRoles', 'onBehalfRecipient'],
    pushNotificationsDlnCreated: [
      'pushNotificationSettings',
      'dlnCreated',
      'all',
    ],
    pushNotificationsDlnSignReminder: [
      'pushNotificationSettings',
      'dlnSignReminder',
      'all',
    ],
    featureFlagAccessPermitted: ['featureFlags', 'accessPermittedSites'],
    featureFlagUserMetrics: ['featureFlags', 'userMetrics'],
    featureFlagConcreteEditingEnabled: [
      'featureFlags',
      'concreteEditingEnabled',
    ],
    featureFlagConcreteDiary: ['featureFlags', 'concreteDiary'],
    featureFlagLineItemDetailsEditingDisabled: [
      'featureFlags',
      'lineItemDetailsEditingDisabled',
    ],
    featureFlagRecipientSetArrived: ['featureFlags', 'recipientSetArrived'],
    featureFlagUserCannotSetAccepted: ['featureFlags', 'userCannotSetAccepted'],
    featureFlagUserCannotSetDelivered: [
      'featureFlags',
      'userCannotSetDelivered',
    ],
    featureFlagUserCannotSetDeclined: ['featureFlags', 'userCannotSetDeclined'],
    featureFlagShowOrderAtPreview: ['featureFlags', 'showOrderAtPreview'],
    featureFlagOcrEnabled: ['featureFlags', 'ocrEnabled'],
  };

  const camelCaseName = name.replaceAll(/_([a-z])/g, (g) => g[1].toUpperCase());

  if (!fieldMap[camelCaseName]) {
    return;
  }

  let target = newUser;
  for (let index = 0; index < fieldMap[camelCaseName].length - 1; index++) {
    target = target[fieldMap[camelCaseName][index]];
  }

  target[fieldMap[camelCaseName].at(-1)] = isCheckbox ? checked : value;

  onChangeUser(newUser);

  Log.info(
    `Change form value of ${name}`,
    {
      from: user[fieldMap[camelCaseName][0]],
      to: newUser[fieldMap[camelCaseName][0]],
    },
    Log.BREADCRUMB.FORM_CHANGE.KEY,
  );

  const logMessage = `Change ${name.replaceAll('_', ' ')}`;
  FunctionUtils.delayFunction(
    `user_change_${name}`,
    Log.productAnalyticsEvent,
    [logMessage, Log.FEATURE.USER],
  );
};
