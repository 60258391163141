import PushService from '~/services/push.service';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import UserUtils from '~/utils/userUtils';
import { promiseHandler } from '~/utils/promiseHandler';

import Log from '~/utils/Log';

export const updatePushNotificationSettings = async (
  userId,
  pushNotificationSettings,
) => {
  if (!UserUtils.isVestigasAccount() && !UserUtils.isUserWriteAllowedUser()) {
    return;
  }

  Log.productAnalyticsEvent(
    'Update push notification settings',
    Log.FEATURE.USER,
  );

  const [, error] = await promiseHandler(
    PushService.updateSettings(userId, pushNotificationSettings),
  );

  if (error) {
    const lines = [
      ToastService.MESSAGE.USER_PUSH_NOTIFICATIONS_UPDATE_FAILED,
      UserService.getUserEmailFromStore(userId),
    ];
    ToastService.httpError(lines, error.response);
    Log.productAnalyticsEvent(
      'Failed to update push notification settings',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  }
};
