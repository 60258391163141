import { useQueries } from '@tanstack/react-query';

import { queryKeysCompany, fetchCompany } from '~/data/company';

import Company from '~/models/masterdata/Company';
import PushNotificationSettings from '~/models/masterdata/PushNotificationSettings';
import SignatureRoles from '~/models/masterdata/SignatureRoles';
import User from '~/models/masterdata/User';
import UserFeatureFlags from '~/models/masterdata/UserFeatureFlags';

import UserUtils from '~/utils/userUtils';

import { type UUID } from '~/types/common';

import { fetchUser } from './useQueryUser';
import { fetchUserPushNotificationSettings } from './useQueryUserPushNotificationSettings';
import { fetchUserSettings } from './useQueryUserSettings';
import { fetchUserSignatureRoles } from './useQueryUserSignatureRoles';
import { queryKeysUser } from './queryKeys';

/**
 * Custom hook to fetch the full user data including company, user details, signature roles, feature flags, and push notifications.
 *
 * @param {string|null} userId - The ID of the user to fetch data for.
 * @param {string|null|undefined} userCompanyId - The ID of the user's company to fetch data for.
 * @returns {Object} An object containing:
 *   - data: Combined user data including user, company, user feature flags,
 *     push notification settings, and signature roles.
 *   - isError: Boolean indicating if any query resulted in an error.
 *   - isLoading: Boolean indicating if any query is still loading.
 *   - isSuccess: Boolean indicating if all authorized queries were successful.
 *   - refetch: Function to refetch all queries.
 */
export const useQueryUserFull = (userId: UUID, userCompanyId?: UUID) => {
  if (!userId) {
    return {
      data: {},
      isError: false,
      isLoading: false,
      isSuccess: false,
      async refetch() {},
    };
  }

  const canManagePushNotifications =
    UserUtils.isVestigasAccount() || UserUtils.isUserWriteAllowedUser();

  const queries = useQueries({
    queries: [
      {
        queryKey: queryKeysUser.get(userId),
        queryFn: async () => fetchUser(userId),
      },
      {
        queryKey: queryKeysUser.getSignatureRoles(userId),
        queryFn: async () => fetchUserSignatureRoles(userId),
      },
      {
        queryKey: queryKeysUser.getSettings(userId),
        queryFn: async () => fetchUserSettings(userId),
      },
      {
        queryKey: queryKeysUser.getPushNotificationSettings(userId),
        queryFn: async () => fetchUserPushNotificationSettings(userId),
        enabled: canManagePushNotifications,
      },
      {
        queryKey: queryKeysCompany.get(userCompanyId),
        queryFn: () => fetchCompany(userCompanyId),
        enabled: Boolean(userCompanyId),
      },
    ],
  });

  const [
    userQuery,
    signatureRolesQuery,
    settingsQuery,
    pushNotificationsQuery,
    immediateCompanyQuery,
  ] = queries;

  const subsequentCompanyQuery = useQueries({
    queries: [
      {
        queryKey: queryKeysCompany.get(userQuery.data?.companyId),
        queryFn: () => fetchCompany(userQuery.data?.companyId),
        enabled: !userCompanyId && Boolean(userQuery.data?.companyId),
      },
    ],
  })[0];

  /**
   * Combine all queries into one array to manage loading, error, etc.
   * If we receive a companyId, we can immediately fetch the company.
   * Otherwise, we have to fetch the company with a separate company query.
   * allQueries contains all queries that should be executed.
   * For company data, it contains as last item in the array either the query
   * that could be run immediately or the company query that had to be run afterwards.
   */
  const companyQuery = userCompanyId
    ? immediateCompanyQuery
    : subsequentCompanyQuery;
  const allQueries = [...queries.slice(0, -1), companyQuery];

  const isLoading = allQueries.some(({ isLoading }) => isLoading);
  const isError = allQueries.some(({ isError }) => isError);
  const isSuccess = allQueries
    .filter(
      (_, index) =>
        canManagePushNotifications ||
        index !== allQueries.indexOf(pushNotificationsQuery),
    )
    .every(({ isSuccess }) => isSuccess);

  const data = {
    ...new User(userQuery.data),
    company: new Company(companyQuery.data),
    featureFlags: UserUtils.isVestigasAccount()
      ? new UserFeatureFlags(settingsQuery.data?.featureFlags)
      : new UserFeatureFlags(),
    pushNotificationSettings: new PushNotificationSettings(
      pushNotificationsQuery.data,
    ),
    signatureRoles: {
      ...new SignatureRoles(signatureRolesQuery.data),
      ...(settingsQuery.data?.defaultSignatureRole && {
        defaultSignatureRole: settingsQuery.data.defaultSignatureRole,
      }),
    },
  };

  const refetch = async () => {
    await Promise.all(allQueries.map((query) => query.refetch()));
  };

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
