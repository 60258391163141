import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysUserGroup } from './queryKeys';

export type UserGroup = {
  id: UUID;
  name: string;
  users: UUID[];
  memberUserGroups: UUID[];
  parentUserGroups: UUID[];
  orgUnits: UUID[];
  orgUnitPaths: UUID[][];
  userGroupPaths: UUID[];
  grantedPermissions: Array<{
    id: number;
    targetId: UUID;
    targetType: string;
    permissions: string[];
  }>;
  permissionsOn: Array<{
    id: number;
    targetId: UUID;
    targetType: string;
    permissions: string[];
  }>;
};

/**
 * Fetches user group data from the API.
 * @param {UUID} userGroupId - The ID of the user group to fetch.
 * @returns {Promise<UserGroup|undefined>} The user group data if successful, undefined otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/UserGroup/operation/get_user_group_by_id_user_group__user_group_id__get
 */
export const fetchUserGroup = async (
  userGroupId: UUID,
): Promise<UserGroup | undefined> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER_GROUP.GET(userGroupId))
      .json<UserGroup>();

    return response;
  } catch (error) {
    Log.error('Error fetching user group', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Generates the query options for the user group query.
 *
 * @param {UUID} userGroupId - The ID of the user group to fetch.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserGroupQueryOptions = ({
  userGroupId,
  options,
}: {
  userGroupId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => {
  return {
    queryKey: queryKeysUserGroup.get(userGroupId),
    queryFn: async () => fetchUserGroup(userGroupId),
    ...options,
  };
};

/**
 * React Query based custom hook for getting the data for a user group with a given userGroupId.
 * @param {UUID} userGroupId - The ID of the user group to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<UserGroup | undefined>} The result of the useQuery hook.
 */
export const useQueryUserGroup = (
  userGroupId: UUID,
  options: Parameters<typeof useQuery>[0],
): UseQueryResult<UserGroup | undefined> =>
  useQuery(getUserGroupQueryOptions({ userGroupId, options }));
