import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Grid,
  TextField,
} from '@mui/material';

import Select from '~/components/baseComponents/inputs/select/Select';
import BasicForm from '~/components/BasicForm';
import CostCenter from '~/models/masterdata/CostCenter';
import CostCenterService from '~/services/costCenter.service';
import { connect } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import cloneDeep from 'lodash/cloneDeep';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import MasterDataService from '~/services/masterData.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import UserUtils from '~/utils/userUtils';
import FunctionUtils from '~/utils/functionUtils';
import DatePicker from '~/components/baseComponents/inputs/date/DatePicker';
import ObjectUtils from '~/utils/objectUtils';

import { OrganisationalGroupPaths } from '../paths/OrganisationalGroupPaths';
import { PermissionGrantEntityTable } from '../permissionGrant/PermissionsTable';

const mapStateToProps = (state) => ({
  companies: state.companies,
  userinfo: state.userinfo,
});
const mapDispatchToProps = () => ({});

class CostCenterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      costCenter: this.getDefaultCostCenter(),
      costCenterLoading: LOADING_STATE.NOT_LOADED,
      submittingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          this.props.costCenter,
          prevProps.costCenter,
          'permissionGrantsFrom',
        ),
      );
    }

    if (
      JSON.stringify(this.props.companies.companies) !==
      JSON.stringify(prevProps.companies.companies)
    ) {
      this.resetDefaultValues();
    }
  }

  resetForm(resetGeneralCostCenterInformation) {
    if (!resetGeneralCostCenterInformation) {
      // Only reset the granted permissions because this is the only thing that has been changed.
      const newCostCenter = cloneDeep(this.state.costCenter);
      newCostCenter.permissionGrantsFrom = this.props.costCenter
        ? this.props.costCenter.permissionGrantsFrom
        : this.getDefaultCostCenter().permissionGrantsFrom;

      this.setState({
        costCenter: newCostCenter,
      });

      return;
    }

    this.setState({
      costCenter: this.props.costCenter ?? this.getDefaultCostCenter(),
    });

    if (
      this.props.costCenter &&
      !this.props.costCenter.additionalDataInitiated
    ) {
      this.refreshCostCenter();
    }
  }

  resetDefaultValues() {
    const newCostCenter = cloneDeep(this.state.costCenter);

    newCostCenter.company ||= this.props.userinfo.userinfo.company?.id;

    this.setState({
      costCenter: newCostCenter,
    });
  }

  getDefaultCostCenter() {
    const costCenter = new CostCenter();

    costCenter.company = this.props.userinfo.userinfo.company?.id;

    return costCenter;
  }

  handleChangeDate = (date, eventName) => {
    const newCostCenter = cloneDeep(this.state.costCenter);

    newCostCenter[eventName] = new Date(
      new Date(date).setUTCHours(0, 0, 0, 0),
    ).toISOString();

    Log.info(
      `Change form value of ${eventName} date`,
      { from: this.state.costCenter[eventName], to: newCostCenter[eventName] },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );

    Log.productAnalyticsEvent(
      `Change ${eventName} date`,
      Log.FEATURE.COST_CENTER,
    );
    this.setState({
      costCenter: newCostCenter,
    });
  };
  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      name: this.state.costCenter.name,
      company_id: this.state.costCenter.company,
      is_active: this.state.costCenter.active,
      start: this.state.costCenter.start,
      end: this.state.costCenter.end,
    };

    if (this.renderForCreate()) {
      body.org_units = this.state.costCenter.organisationalGroups;
    }

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['company_id'],
        Log.FEATURE.COST_CENTER,
      )
    ) {
      this.setState({
        submittingForm: false,
      });
      return;
    }

    Log.info('Submit cost center form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.COST_CENTER);

    if (this.renderForCreate()) {
      const [id, error] = await promiseHandler(
        CostCenterService.createNewCostCenter(body),
      );

      if (error) {
        if (error.response.status === 409) {
          ToastService.httpError(
            [ToastService.MESSAGE.COST_CENTER_CREATION_FAILED_DUPLICATE],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create duplicate',
            Log.FEATURE.COST_CENTER,
            Log.TYPE.ERROR,
          );
        } else {
          ToastService.httpError(
            [ToastService.MESSAGE.COST_CENTER_CREATION_FAILED],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create',
            Log.FEATURE.COST_CENTER,
            Log.TYPE.ERROR,
          );
          Log.error('Failed to create cost center.', error);
        }

        this.setState({
          submittingForm: false,
        });
        return;
      }

      this.props.closeForm();
      this.resetForm(true);
      await CostCenterService.refreshCostCenters();

      if (this.props.setCostCenter) {
        this.props.setCostCenter(id);
      }
    } else {
      const [data, error] = await promiseHandler(
        CostCenterService.updateCostCenter(this.state.costCenter?.id, body),
      );

      if (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.COST_CENTER_UPDATE_FAILED],
          error.response,
        );
        Log.error(
          'Failed to update cost center. id: ' + this.state.costCenter?.id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.COST_CENTER,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      const [response2, error2] = await promiseHandler(
        OrganisationalGroupService.updateParentOrganisationalGroups(
          this.props.costCenter.id,
          PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
          this.props.costCenter.organisationalGroups,
          this.state.costCenter.organisationalGroups,
        ),
      );

      if (error2) {
        ToastService.httpError(
          ['Organisations-Gruppen konnten nicht geändert werden.'],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update organisational groups',
          Log.FEATURE.COST_CENTER,
          Log.TYPE.ERROR,
        );
      }

      this.props.closeForm();
      this.resetForm(true);
      CostCenterService.refreshCostCenters();
    }

    this.setState({
      submittingForm: false,
    });
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.COST_CENTER);
    this.props.closeForm();
    this.resetForm(true);
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleInputChange = (event) => {
    const newCostCenter = cloneDeep(this.state.costCenter);

    newCostCenter.name = event.target.value;

    Log.info(
      'Change form value of name',
      { from: this.state.costCenter.name, to: newCostCenter.name },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    FunctionUtils.delayFunction(
      'cost_center_change_name',
      Log.productAnalyticsEvent,
      ['Change name', Log.FEATURE.COST_CENTER],
    );

    this.setState({
      costCenter: newCostCenter,
    });
  };
  handleCheckboxChange = (event) => {
    const newCostCenter = cloneDeep(this.state.costCenter);

    newCostCenter.active = event.target.checked;

    Log.info(
      'Change form value of active',
      { from: this.state.costCenter.active, to: newCostCenter.active },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change active checkbox',
      Log.FEATURE.COST_CENTER,
    );

    this.setState({
      costCenter: newCostCenter,
    });
  };
  handleChangeCompany = (event) => {
    const newCostCenter = cloneDeep(this.state.costCenter);

    newCostCenter.company = event.target.value;

    Log.info(
      'Change form value of company',
      { from: this.state.costCenter.company, to: newCostCenter.company },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change company', Log.FEATURE.COST_CENTER);

    this.setState({
      costCenter: newCostCenter,
    });
  };
  handleChangeOrganisationalGroups = (organisationalGroups) => {
    const newCostCenter = cloneDeep(this.state.costCenter);

    newCostCenter.organisationalGroups = organisationalGroups.map(
      (organisationalGroup) => organisationalGroup.id,
    );

    Log.info(
      'Change form value of organisational groups',
      {
        from: this.state.costCenter.organisationalGroups,
        to: newCostCenter.organisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change organisational groups',
      Log.FEATURE.COST_CENTER,
    );

    this.setState({
      costCenter: newCostCenter,
    });
  };
  refreshCostCenter = async () => {
    this.setState({
      costCenterLoading: LOADING_STATE.LOADING,
    });

    const [response, error] = await promiseHandler(
      CostCenterService.refreshCostCenter(this.props.costCenter.id),
    );

    if (error) {
      this.setState({
        costCenterLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      costCenterLoading: LOADING_STATE.SUCCEEDED,
    });
  };

  getPaths() {
    if (this.renderForCreate()) {
      return null;
    }

    return (
      <OrganisationalGroupPaths
        id={this.props.costCenter.id}
        organisationalGroupPaths={
          this.props.costCenter.organisationalGroupPaths
        }
        onOpenOrganisationalGroup={(organisationalGroup) =>
          this.props.onOpenOrganisationalGroup(
            organisationalGroup,
            this.getUnsavedChanges(),
          )
        }
      />
    );
  }

  getUnsavedChanges() {
    if (this.renderForCreate()) {
      return [];
    }

    return CostCenter.getDifferentValues(
      this.props.costCenter,
      this.state.costCenter,
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title={
          'Kostenstelle ' +
          (this.renderForCreate() ? 'Erstellen' : this.props.costCenter.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        id={this.props.costCenter?.id}
        unsavedChanges={this.getUnsavedChanges()}
        missingPermissionsToSubmit={
          this.renderForCreate()
            ? !UserUtils.isCostCenterCreateAllowedUser()
            : !UserUtils.isCostCenterWriteAllowedUser()
        }
      >
        <Grid container direction="row" spacing={3} space={4}>
          {this.props.costCenter?.organisationalGroupPaths?.length > 0 ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  {this.getPaths()}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Kostenstelle</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Kostenstelle"
                  type="text"
                  disabled={!this.renderForCreate()}
                  required
                  fullWidth
                  value={this.state.costCenter?.name}
                  onChange={this.handleInputChange}
                  autoFocus
                  autoComplete="off"
                />
              </Grid>
              {this.renderForCreate() ? null : (
                <Grid item xs={6} lg={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.costCenter?.active}
                        name="is_active"
                      />
                    }
                    onChange={this.handleCheckboxChange}
                    label="Aktiv"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Firma</InputLabel>
                <Select
                  value={this.state.costCenter?.company}
                  fullWidth
                  onChange={this.handleChangeCompany}
                  size="small"
                  options={this.props.companies.companies}
                  loading={this.props.companies.companiesLoading}
                  errorText="Firmen konnten nicht geladen werden."
                  sortOptions
                  sortOptionsByKey="name"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel>Startdatum</InputLabel>
                <DatePicker
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  value={this.state.costCenter.start}
                  maxDate={this.state.costCenter.end}
                  onChange={(value) => this.handleChangeDate(value, 'start')}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <InputLabel>Enddatum</InputLabel>
                <DatePicker
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  value={this.state.costCenter.end}
                  minDate={this.state.costCenter.start}
                  onChange={(value) => this.handleChangeDate(value, 'end')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Ist Teil von...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={
                    PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                  }
                  pickedIds={this.state.costCenter.organisationalGroups}
                  callbackPickedItems={this.handleChangeOrganisationalGroups}
                  onChipClick={(organisationalGroup) =>
                    this.props.onOpenOrganisationalGroup?.(
                      organisationalGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  onUpdatedItemsChange={
                    this.props.onUpdatedOrganisationalGroupsChange
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {this.renderForCreate() ? null : (
            <Grid item xs={12}>
              <PermissionGrantEntityTable
                title="Wer ist auf diese Kostenstelle berechtigt?"
                permissionGrantsFrom={
                  this.state.costCenter.permissionGrantsFrom
                }
                defaultEntities={[this.props.costCenter.id]}
                defaultEntityType={PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY}
                fixedPicker={PermissionGrant.TYPE.ENTITY}
                refreshData={this.refreshCostCenter}
                loading={this.state.costCenterLoading}
              />
            </Grid>
          )}
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(CostCenterForm);
