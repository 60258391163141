import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { LOADING_STATE } from '~/constants/LoadingState';

import Permissions from '~/models/masterdata/Permissions';

import { LightTooltip } from '~/utils/componentUtils';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/Log';

import { withErrorBoundary } from '~/ui/atoms';

import { PermissionForm } from '../PermissionForm';
import { PermissionGrantDialog } from '../PermissionGrantDialog';

import { DeletePermissionButton } from './DeletePermissionButton';
import { GrantPermissionButton } from './GrantPermissionButton';
import { usePermissionForm } from './usePermissionForm';

export const PermissionGrantTableBase = withErrorBoundary(
  ({
    columnVisibilityModel,
    defaultEntities,
    defaultEntityType,
    defaultSubjects,
    defaultSubjectType,
    fixedPicker,
    initRows,
    loading,
    permissionGrants,
    refreshData,
    title,
    type,
  }) => {
    const [state, setState] = useState({
      deletingPermissionGrants: false,
      isPermissionFormOpen: false,
      isPermissionGrantDialogOpen: false,
      isSubmitting: false,
      permissionGrantId: null,
      permissions: new Permissions(),
      rows: [],
      rowSelectionModel: [],
    });

    const { handleDelete, handleSubmit, isSubmitting } = usePermissionForm(
      refreshData,
      permissionGrants,
      type,
    );

    useEffect(() => {
      initRows(permissionGrants, setState);
    }, [permissionGrants]);

    const handleCancel = () => {
      setState((prevState) => ({
        ...prevState,
        isPermissionFormOpen: false,
      }));
    };

    const handleSelect = (event) => {
      Log.info(
        'Change selection value of permission grants',
        {
          from: state.rowSelectionModel,
          to: event,
        },
        Log.BREADCRUMB.SELECTION_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        '(De)select granted permissions',
        Log.FEATURE.PERMISSIONS,
      );

      setState((prevState) => ({
        ...prevState,
        rowSelectionModel: event,
      }));
    };

    const handleAddPermission = () => {
      Log.productAnalyticsEvent(
        'Open permission grant dialog',
        Log.FEATURE.PERMISSION_GRANT_DIALOG,
      );

      setState((prevState) => ({
        ...prevState,
        isPermissionGrantDialogOpen: true,
      }));
    };

    const handleDeletePermission = async () => {
      await handleDelete(state.rowSelectionModel, setState);
    };

    const handleOpenForm = (params) => {
      Log.productAnalyticsEvent('Open form', Log.FEATURE.PERMISSIONS);

      setState((prevState) => ({
        ...prevState,
        isPermissionFormOpen: true,
        permissionGrantId: params.value.permissionGrantId,
        permissions: params.value.permissions,
      }));
    };

    const handleClosePermissionGrantDialog = () => {
      setState((prevState) => ({
        ...prevState,
        isPermissionGrantDialogOpen: false,
      }));
    };

    const getColumns = () => [
      {
        field: 'permissions',
        headerName: '',
        width: 120,
        sortable: true,
        filterable: false,
        renderCell(params) {
          return (
            <div className="flex h-full flex-col justify-center py-0.5">
              <LightTooltip title="Vergebene Berechtigungen einsehen">
                <Button
                  variant="outlined"
                  color="primary"
                  className="h-full"
                  onClick={() => handleOpenForm(params)}
                >
                  Details
                </Button>
              </LightTooltip>
              <PermissionForm
                title="Vergebene Berechtigungen einsehen"
                open={state.isPermissionFormOpen}
                permissions={state.permissions}
                formSuccess={handleSubmit}
                formAbort={handleCancel}
                submittingForm={isSubmitting}
                opacity={0.1}
              />
            </div>
          );
        },
      },
      {
        field: 'defaultRole',
        headerName: 'Berechtigung als',
        width: 200,
        sortable: true,
      },
      {
        field: 'entityType',
        headerName: 'Berechtigung auf',
        width: 200,
        sortable: true,
      },
      {
        field: 'entityName',
        headerName: 'Name',
        width: 250,
        sortable: true,
        renderCell: DatagridUtils.displayCellTooltip,
      },
      {
        field: 'entityId',
        headerName: 'ID',
        width: 350,
        sortable: true,
      },
    ];

    const getSlots = () => ({
      footer: () => null,
      noRowsOverlay: () => (
        <div className="flex h-full w-full items-center justify-center">
          {loading === LOADING_STATE.FAILED
            ? 'Berechtigungen konnten nicht geladen werden.'
            : 'Keine Einträge'}
        </div>
      ),
      toolbar: () => null,
    });

    return (
      <>
        <h3 className="mb-2 mt-4">{title}</h3>
        <div className="h-96">
          <DataGrid
            rows={state.rows}
            columns={getColumns()}
            pageSizeOptions={[]}
            onRowSelectionModelChange={handleSelect}
            rowSelectionModel={state.rowSelectionModel}
            rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
            loading={
              state.deletingPermissionGrants ||
              loading === LOADING_STATE.LOADING
            }
            columnVisibilityModel={columnVisibilityModel}
            slots={getSlots()}
            disableRowSelectionOnClick
            checkboxSelection
          />
        </div>
        <div className="flex items-center justify-between">
          <DeletePermissionButton
            onClick={handleDeletePermission}
            disabled={state.deletingPermissionGrants}
            deleting={state.deletingPermissionGrants}
          />
          <GrantPermissionButton
            onClick={handleAddPermission}
            disabled={state.deletingPermissionGrants}
            deleting={state.deletingPermissionGrants}
          />
        </div>
        {state.isPermissionGrantDialogOpen ? (
          <PermissionGrantDialog
            closeForm={handleClosePermissionGrantDialog}
            defaultEntities={defaultEntities}
            defaultEntityType={defaultEntityType}
            defaultSubjects={defaultSubjects}
            defaultSubjectType={defaultSubjectType}
            fixedPicker={fixedPicker}
            loadData={refreshData}
            open={state.isPermissionGrantDialogOpen}
          />
        ) : null}
      </>
    );
  },
  'Berechtigungen konnten nicht geladen werden.',
);

PermissionGrantTableBase.displayName = 'PermissionGrantTableBase';
