import { type UUID } from '~/types/common';

import { type UsersQueryParams } from './useQueryUsers';

/**
 * Query key factory for user-related queries.
 */
export const queryKeysUser = {
  base: () => 'users' as const,
  get: (userId: UUID) => [queryKeysUser.base(), 'detail', { userId }] as const,
  getAll: (queryParams: Partial<UsersQueryParams>) =>
    [queryKeysUser.base(), 'list', queryParams] as const,
  getFeatureFlags: (userId: UUID) =>
    [queryKeysUser.base(), 'featureFlags', { userId }] as const,
  getPushNotificationSettings: (userId: UUID) =>
    [queryKeysUser.base(), 'pushNotifications', { userId }] as const,
  getSettings: (userId: UUID) =>
    [queryKeysUser.base(), 'settings', { userId }] as const,
  getSignatureRoles: (userId: UUID) =>
    [queryKeysUser.base(), 'signatureRoles', { userId }] as const,
  update: (userId: UUID) =>
    [queryKeysUser.base(), 'detail', { userId }] as const,
  updateFeatureFlags: (userId: UUID) =>
    [queryKeysUser.base(), 'featureFlags', { userId }] as const,
  updateSettings: (userId: UUID) =>
    [queryKeysUser.base(), 'settings', { userId }] as const,
  updateSignatureRoles: (userId: UUID) =>
    [queryKeysUser.base(), 'signatureRoles', { userId }] as const,
} as const;
