import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

import { SettingsCSVUpload } from '../SettingsCSVUpload';

const permissionsMap = new Map([
  ['company', UserUtils.isCompanyCreateAllowedUser],
  ['costCenter', UserUtils.isCostCenterCreateAllowedUser],
  ['organisationalGroup', UserUtils.isOrganisationalGroupCreateAllowedUser],
  ['site', UserUtils.isSiteCreateAllowedUser],
  ['user', UserUtils.isUserCreateAllowedUser],
  ['userGroup', UserUtils.isUserGroupCreateAllowedUser],
  ['vehicle', UserUtils.isVehicleCreateAllowedUser],
]);

export const CreateItemButton = ({ entity, openCreateForm }) => {
  const isMissingPermissions = !permissionsMap.get(entity)?.();

  if (isMissingPermissions) {
    return (
      <MissingPermissionsTooltip>
        <Button
          className="disabled-primary-button"
          startIcon={<AddIcon />}
          onMouseDown={openCreateForm}
          disabled={isMissingPermissions}
        >
          {'Erstellen'}
        </Button>
      </MissingPermissionsTooltip>
    );
  }

  if (['site', 'user'].includes(entity) && UserUtils.isVestigasAccount()) {
    return (
      <SettingsCSVUpload entity={entity} openCreateForm={openCreateForm} />
    );
  }

  return (
    <Button
      className="primary-button"
      startIcon={<AddIcon />}
      onMouseDown={openCreateForm}
    >
      {'Erstellen'}
    </Button>
  );
};
