import PermissionGrant from './PermissionGrant';
import ArrayUtils from '~/utils/arrayUtils';

export default class CostCenter {
  constructor(costCenter, additionalDataInitiated) {
    this.id = costCenter?.id ?? null;
    this.name = costCenter?.name ?? '';
    this.company = costCenter?.company_id ?? costCenter?.companyId ?? '';
    this.active =
      costCenter?.is_active === undefined
        ? costCenter?.isActive === undefined
          ? true
          : costCenter?.isActive
        : costCenter?.is_active;
    this.start = costCenter?.start || null;
    this.end = costCenter?.end || null;

    this.organisationalGroups =
      costCenter?.org_units ?? costCenter?.orgUnits ?? [];
    this.organisationalGroupPaths =
      costCenter?.org_unit_paths ?? costCenter?.orgUnitPaths ?? [];

    this.permissionGrantsFrom =
      (costCenter?.permissions_from ?? costCenter?.permissionsFrom)?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetType: PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
            targetId: this.id,
          }),
      ) ?? [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }
  }

  static getDifferentValues(costCenterA, costCenterB) {
    const differentValues = [];

    if (costCenterA?.id !== costCenterB?.id) {
      differentValues.push('ID');
    }

    if (costCenterA?.name !== costCenterB?.name) {
      differentValues.push('Name');
    }

    if (costCenterA?.active !== costCenterB?.active) {
      differentValues.push('Aktiv');
    }

    if (costCenterA?.company !== costCenterB?.company) {
      differentValues.push('Firma');
    }

    const differentOrganisationalGroups = ArrayUtils.getDifference(
      costCenterA?.organisationalGroups,
      costCenterB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }

  static IS_ACTIVE = {
    YES: 'Ja',
    NO: 'Nein',
  };
}
