import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysUser } from './queryKeys';

type UserPushNotificationSettings = {
  dlnCreated: {
    all: boolean;
    sites: UUID[];
    accRefs: UUID[];
  };
  dlnSignReminder: {
    all: boolean;
    sites: UUID[];
    accRefs: UUID[];
  };
};

/**
 * Fetches user push notification settings data from the API.
 * @param {UUID} userId - The ID of the user to fetch.
 * @returns {Promise<UserPushNotificationSettings|null>} The user data if successful, null otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/get_user_notification_settings_user_notification_settings_get
 */
export const fetchUserPushNotificationSettings = async (userId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER.GET_PUSH_NOTIFICATION_SETTINGS(userId))
      .json<UserPushNotificationSettings>();

    return response;
  } catch (error) {
    Log.error('Error fetching user push notification settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for fetching push notification settings.
 *
 * @param {string} userId - The ID of the user.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query result.
 */
export const useQueryUserPushNotificationSettings = (
  userId: UUID,
  options: Parameters<typeof useQuery>[0],
) =>
  useQuery({
    queryKey: queryKeysUser.getPushNotificationSettings(userId),
    queryFn: fetchUserPushNotificationSettings(userId),
    ...options,
  });
