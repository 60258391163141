import { memo, useState } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

import { Spinner } from '../Spinner';

import { ICON } from './constants';

export const GridToolbarCustomComponent = memo(
  ({ buttonProps, icon, menuItems, title }) => {
    const [anchorElement, setAnchorElement] = useState(null);

    const handleClick = (event) => {
      setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorElement(null);
    };

    if (menuItems.length === 1) {
      const menuItem = menuItems[0];

      return (
        <Button
          variant="outlined"
          size="small"
          startIcon={ICON[icon] ?? null}
          onMouseDown={() => menuItem.onClick()}
        >
          {menuItem.isLoading ? <Spinner /> : null}
          {menuItem.name}
        </Button>
      );
    }

    return (
      <>
        <Button
          className="datagrid-toolbar-button"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          startIcon={ICON[icon] ?? null}
          {...buttonProps}
        >
          {title}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {menuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.name}
              selected={menuItem.selected}
              disabled={menuItem.isLoading}
              onMouseDown={menuItem.onClick ?? handleClose}
              className="flex items-center gap-2"
            >
              {menuItem.isLoading ? <Spinner /> : null}
              {menuItem.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);
