import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeliveriesService from '~/services/deliveries.service';

import { setDelivery_query, setFilterValue } from '~/redux/filtersSlice';
import { DeliveriesTestIds } from '~/constants/test-ids/deliveries.testids';
import Log from '~/utils/Log';

import { FreeTextSearch } from '~/components/baseComponents/inputs/FreeTextSearch';

export const DeliveryFreeTextFilter = () => {
  const dispatch = useDispatch();

  const query = useSelector(({ filters }) => filters.delivery_query);
  const dateRange = useSelector(({ filters }) => filters.delivery_dateRange);

  const handleChange = (params) => {
    // dispatch(
    //   setFilterValue({
    //     page: 'deliveries',
    //     filterKey: 'query',
    //     filterValue: query,
    //   }),
    // );
    dispatch(setDelivery_query(params)); // deprecated Redux action -> use setFilterValue instead
  };

  return (
    <div className="w-300px">
      <FreeTextSearch
        value={query}
        onChange={handleChange}
        productAnalyticsFeature={Log.FEATURE.DELIVERY_LIST}
        disabled={DeliveriesService.isArchiveMode(dateRange)}
        debounceTime={600}
        id="DeliveryFreeTextFilter_delay_function"
        testId={DeliveriesTestIds.FREE_TEXT_SEARCH}
        autoFocus
      />
    </div>
  );
};
