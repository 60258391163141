import { Button } from '@mui/material';

import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

import { Spinner } from '~/components/Spinner';

export const GrantPermissionButton = ({ onClick, disabled, deleting }) => {
  if (!UserUtils.isPermissionGrantAllowedUser()) {
    return (
      <MissingPermissionsTooltip>
        <Button
          variant="outlined"
          color="primary"
          className="mt-2"
          onClick={onClick}
          disabled
        >
          Berechtigungen vergeben
        </Button>
      </MissingPermissionsTooltip>
    );
  }

  return (
    <LightTooltip title="Neue Benutzer oder Benutzer-Gruppen berechtigen.">
      <Button
        variant="outlined"
        color="primary"
        className="mt-2"
        onClick={onClick}
        disabled={disabled}
      >
        {deleting ? (
          <Spinner title="Berechtigungen löschen..." />
        ) : (
          'Berechtigungen vergeben'
        )}
      </Button>
    </LightTooltip>
  );
};
