import { useMemo } from 'react';

import {
  DataGridPro,
  type GridRowsProp,
  type GridCallbackDetails,
  type GridCellParams,
  type GridColDef,
  type GridRowParams,
  type GridRowSelectionModel,
  type MuiEvent,
  type GridPaginationModel,
  type GridCsvExportOptions,
} from '@mui/x-data-grid-pro';

import { LOADING_STATE } from '~/constants/LoadingState';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { noop } from '~/utils/lang';

import {
  DatagridToolbar,
  LoadingState,
  NoResults,
  Pagination,
} from './components';

type P = {
  checkboxSelection?: boolean;
  columns: GridColDef[];
  csvOptions?: GridCsvExportOptions;
  disableRowSelectionOnClick?: boolean;
  filterMode: 'client' | 'server';
  isLoading?: boolean;
  onCellClick?: (
    params: GridCellParams,
    event: MuiEvent,
    details: GridCallbackDetails,
  ) => void;
  onMultiPermissionGrantEdit?: () => void;
  onRowClick?: (
    params: GridRowParams,
    event: MuiEvent,
    details: GridCallbackDetails,
  ) => void;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails,
  ) => void;
  pagination?: boolean;
  paginationMeta?: { totalRowCount?: number };
  paginationMode: 'client' | 'server';
  paginationModel: GridPaginationModel;
  pageSizeOptions: number[];
  rows: GridRowsProp;
  slots?: Record<string, unknown>;
  slotProps?: Record<string, unknown>;
  sortingMode: 'client' | 'server';
  sortingOrder: Array<'asc' | 'desc'>;
  sortModel: Array<{
    field: string;
    sort?: 'asc' | 'desc';
  }>;
  onPaginationModelChange?: (model: GridPaginationModel) => void;
  onSortModelChange?: (
    model: Array<{ field: string; sort?: 'asc' | 'desc' }>,
  ) => void;
};

/**
 *
 * @see https://mui.com/x/api/data-grid/data-grid-pro/
 */
export const DatagridServerDriven = ({
  checkboxSelection,
  columns = [],
  csvOptions,
  disableRowSelectionOnClick,
  filterMode = 'server',
  isLoading,
  onCellClick = noop,
  onMultiPermissionGrantEdit,
  onRowClick = noop,
  onRowSelectionModelChange = noop,
  pageSizeOptions = [],
  pagination = true,
  paginationMeta,
  paginationMode = 'server',
  paginationModel = {
    page: 0,
    pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
  },
  rows = [],
  slots,
  slotProps,
  sortingMode = 'server',
  sortingOrder,
  sortModel,
  onPaginationModelChange = noop,
  onSortModelChange = noop,
}: P) => {
  const customComponents = useMemo(() => {
    const components = [];

    if (onMultiPermissionGrantEdit) {
      components.push({
        title: 'Berechtigungen vergeben',
        icon: 'permissionGrant',
        menuItems: [
          {
            name: 'Berechtigungen vergeben',
            onClick: onMultiPermissionGrantEdit,
          },
        ],
      });
    }

    return components;
  }, [onMultiPermissionGrantEdit]);

  const datagridSlots = {
    loadingOverlay() {
      return <LoadingState />;
    },
    noResultsOverlay() {
      return <NoResults />;
    },
    toolbar() {
      return (
        <DatagridToolbar
          csvOptions={csvOptions}
          customComponents={customComponents}
        />
      );
    },
    pagination: Pagination,
    ...slots,
  };

  return (
    <DataGridPro
      checkboxSelection={checkboxSelection}
      columnHeaderHeight={64}
      columns={columns}
      density="compact"
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      filterMode={filterMode}
      loading={Boolean(
        isLoading === true || isLoading === LOADING_STATE.LOADING,
      )}
      onCellClick={onCellClick}
      onRowSelectionModelChange={onRowSelectionModelChange}
      onRowClick={onRowClick}
      pageSizeOptions={pageSizeOptions}
      pagination={pagination}
      paginationMeta={paginationMeta}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      rows={rows}
      slots={datagridSlots}
      slotProps={slotProps}
      sortingMode={sortingMode}
      sortModel={sortModel}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      rowCount={paginationMeta?.totalRowCount ?? 0}
      disableColumnFilter
      disableDensitySelector
    />
  );
};
