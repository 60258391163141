import BasicModal from '~/components/BasicModal';

import { SiteForm } from '../masterData/site/SiteForm';
import ArticleForm from '../admin/articleMaster/ArticleForm';
import CategoryForm from '../admin/articleMaster/CategoryForm';
import { CompanyForm } from '../masterData/company/CompanyForm';
import CostCenterForm from '../masterData/costCenter/CostCenterForm';
import CustomerSubscriptionForm from '../admin/customerSubscription/CustomerSubscriptionForm';
import CustomFieldForm from '../admin/customField/CustomFieldForm';
import InvoiceCheckIgnoredArticleForm from '../admin/invoiceCheckIgnoredArticle/InvoiceCheckIgnoredArticleForm';
import { OrganizationalUnitForm } from '../masterData/organizationalUnit/OrganizationalUnitForm/OrganizationalUnitForm';
import SignatureFieldForm from '../admin/signatureField/SignatureFieldForm';
import { UserForm } from '../masterData/users/UserForm';
import UserGroupForm from '../masterData/userGroup/UserGroupForm';
import VehiclesForm from '../masterData/vehicle/VehiclesForm';

import { ErrorBoundary } from '~/ui/atoms';

/**
 * FormComponent renders different forms based on the provided entity type and state.
 * It uses an ErrorBoundary to handle errors and display a fallback modal if data cannot be loaded.
 * The settings pages are built in a way that you can open a form from inside of a form,
 * which will then be rendered in the open modal
 * So if a specific item (company, costCenter, organisationalGroup, site, user, userGroup, vehicle)
 * is set in state, we should render the corresponding form for this item.
 * Otherwise we render the form based on the entity type, which is the data that the page is about.
 *
 * @param refreshSubjects - Refresh the data displayed in the table
 */
export const FormComponent = ({
  closeForm,
  entity,
  onOpenCompany,
  onOpenCostCenter,
  onOpenOrganisationalGroup,
  onOpenSite,
  onOpenUser,
  onOpenUserGroup,
  onOpenVehicle,
  refreshEntities,
  refreshSubjects,
  setState,
  state: {
    company,
    costCenter,
    formOpen,
    formType,
    item,
    organisationalGroup,
    site,
    user,
    userGroup,
    vehicle,
  },
}) => {
  if (!formOpen) {
    return null;
  }

  const fallback = (
    <BasicModal title="Fehler" open={formOpen} closeModal={closeForm} fullWidth>
      <div className="h-24 w-full text-center">
        Daten konnten nicht geladen werden.
      </div>
    </BasicModal>
  );

  if (company) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <CompanyForm
          open={formOpen}
          closeForm={closeForm}
          company={company}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((prevState) => ({
              ...prevState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  if (costCenter) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <CostCenterForm
          open={formOpen}
          closeForm={closeForm}
          costCenter={costCenter}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((prevState) => ({
              ...prevState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  if (organisationalGroup) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <OrganizationalUnitForm
          open={formOpen}
          closeForm={closeForm}
          organizationalUnitId={organisationalGroup?.id}
          type={formType}
          onOpenUser={onOpenUser}
          onOpenSite={onOpenSite}
          onOpenCostCenter={onOpenCostCenter}
          onOpenVehicle={onOpenVehicle}
          onOpenCompany={onOpenCompany}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedUsersChange={(users) =>
            setState((prevState) => ({
              ...prevState,
              updatedUsers: users,
            }))
          }
          onUpdatedSitesChange={(sites) =>
            setState((prevState) => ({
              ...prevState,
              updatedSites: sites,
            }))
          }
          onUpdatedCostCentersChange={(costCenters) =>
            setState((prevState) => ({
              ...prevState,
              updatedCostCenters: costCenters,
            }))
          }
          onUpdatedVehiclesChange={(vehicles) =>
            setState((prevState) => ({
              ...prevState,
              updatedVehicles: vehicles,
            }))
          }
          onUpdatedCompaniesChange={(companies) =>
            setState((prevState) => ({
              ...prevState,
              updatedCompanies: companies,
            }))
          }
          // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
          onUpdatedUserGroupsChange={(userGroups) =>
            setState((prevState) => ({
              ...prevState,
              updatedUserGroups: userGroups,
            }))
          }
          onRefreshEntities={refreshEntities}
        />
      </ErrorBoundary>
    );
  }

  if (site) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <SiteForm
          open={formOpen}
          closeForm={closeForm}
          site={site}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((prevState) => ({
              ...prevState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  if (user) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <UserForm
          open={formOpen}
          closeForm={closeForm}
          userId={user?.id}
          userCompanyId={user?.companyId}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onRefreshSubjects={refreshSubjects}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) => {
            setState((prevState) => ({
              ...prevState,
              updatedOrganisationalGroups: organisationalGroups,
            }));
          }}
        />
      </ErrorBoundary>
    );
  }

  if (userGroup) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <UserGroupForm
          open={formOpen}
          closeForm={closeForm}
          userGroup={userGroup}
          type={formType}
          onOpenUser={onOpenUser}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedUsersChange={(users) =>
            setState((prevState) => ({
              ...prevState,
              updatedUsers: users,
            }))
          }
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((prevState) => ({
              ...prevState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
          // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
          onRefreshEntities={refreshEntities}
        />
      </ErrorBoundary>
    );
  }

  if (vehicle) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <VehiclesForm
          open={formOpen}
          closeForm={closeForm}
          vehicle={vehicle}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((prevState) => ({
              ...prevState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  switch (entity) {
    case 'user': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <UserForm
            open={formOpen}
            closeForm={closeForm}
            userId={item?.id}
            userCompanyId={item?.companyId}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onOpenUserGroup={onOpenUserGroup}
            onRefreshSubjects={refreshSubjects}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) => {
              setState((prevState) => ({
                ...prevState,
                updatedOrganisationalGroups: organisationalGroups,
              }));
            }}
          />
        </ErrorBoundary>
      );
    }

    case 'site': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <SiteForm
            open={formOpen}
            closeForm={closeForm}
            site={item}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((prevState) => ({
                ...prevState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'costCenter': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CostCenterForm
            open={formOpen}
            closeForm={closeForm}
            costCenter={item}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((prevState) => ({
                ...prevState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'vehicle': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <VehiclesForm
            open={formOpen}
            closeForm={closeForm}
            vehicle={item}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((prevState) => ({
                ...prevState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'company': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CompanyForm
            open={formOpen}
            closeForm={closeForm}
            company={item}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((prevState) => ({
                ...prevState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'organisationalGroup': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <OrganizationalUnitForm
            open={formOpen}
            closeForm={closeForm}
            organizationalUnitId={item?.id}
            type={formType}
            onOpenUser={onOpenUser}
            onOpenSite={onOpenSite}
            onOpenCostCenter={onOpenCostCenter}
            onOpenVehicle={onOpenVehicle}
            onOpenCompany={onOpenCompany}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onOpenUserGroup={onOpenUserGroup}
            onUpdatedUsersChange={(users) =>
              setState((prevState) => ({
                ...prevState,
                updatedUsers: users,
              }))
            }
            onUpdatedSitesChange={(sites) =>
              setState((prevState) => ({
                ...prevState,
                updatedSites: sites,
              }))
            }
            onUpdatedCostCentersChange={(costCenters) =>
              setState((prevState) => ({
                ...prevState,
                updatedCostCenters: costCenters,
              }))
            }
            onUpdatedVehiclesChange={(vehicles) =>
              setState((prevState) => ({
                ...prevState,
                updatedVehicles: vehicles,
              }))
            }
            onUpdatedCompaniesChange={(companies) =>
              setState((prevState) => ({
                ...prevState,
                updatedCompanies: companies,
              }))
            }
            // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
            onUpdatedUserGroupsChange={(userGroups) =>
              setState((prevState) => ({
                ...prevState,
                updatedUserGroups: userGroups,
              }))
            }
            onRefreshEntities={refreshEntities}
          />
        </ErrorBoundary>
      );
    }

    case 'userGroup': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <UserGroupForm
            open={formOpen}
            closeForm={closeForm}
            userGroup={item}
            type={formType}
            onOpenUser={onOpenUser}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onOpenUserGroup={onOpenUserGroup}
            onUpdatedUsersChange={(users) =>
              setState((prevState) => ({
                ...prevState,
                updatedUsers: users,
              }))
            }
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((prevState) => ({
                ...prevState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
            // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
            onRefreshEntities={refreshEntities}
          />
        </ErrorBoundary>
      );
    }

    case 'customField': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CustomFieldForm
            open={formOpen}
            closeForm={closeForm}
            customField={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'signatureField': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <SignatureFieldForm
            open={formOpen}
            closeForm={closeForm}
            signatureField={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'article': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <ArticleForm
            open={formOpen}
            closeForm={closeForm}
            article={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'category': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CategoryForm
            open={formOpen}
            closeForm={closeForm}
            category={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'customerSubscription': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CustomerSubscriptionForm
            open={formOpen}
            closeForm={closeForm}
            customerSubscription={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'invoiceCheckIgnoredArticle': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <InvoiceCheckIgnoredArticleForm
            open={formOpen}
            closeForm={closeForm}
            invoiceCheckIgnoredArticle={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    default: {
      return <ErrorBoundary fallback={() => fallback}>{null}</ErrorBoundary>;
    }
  }
};
