import React from 'react';
import { connect } from 'react-redux';
import CostCenter from '~/models/masterdata/CostCenter';
import { SettingsTable } from '../../SettingsTable';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import MapperService from '~/services/mapper.service';
import { dateUtils } from '~/utils/dateUtils';

const columns = [
  {
    field: 'name',
    headerName: 'Kostenstelle',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'active',
    headerName: 'Aktiv',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'start',
    headerName: 'Startdatum',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'end',
    headerName: 'Enddatum',
    width: 300,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  costCenters: state.costCenters,
});

class CostCenterTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({
      rows: this.props.costCenters?.costCenters?.map((costCenter) => {
        const row = {
          id: costCenter.id,
          name: costCenter.name,
          active: costCenter.active
            ? CostCenter.IS_ACTIVE.YES
            : CostCenter.IS_ACTIVE.NO,
          start: dateUtils.getFormattedDate_safe(
            costCenter.start,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
          end: dateUtils.getFormattedDate_safe(
            costCenter.end,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
        };

        return MapperService.addSearchString(row, ['id', 'name']);
      }),
    });
  };

  render() {
    return (
      <SettingsTable
        entity="costCenter"
        title="Kostenstellen"
        rows={this.state.rows}
        columns={columns}
        items={this.props.costCenters.costCenters}
        loading={this.props.costCenters.costCentersLoading}
        multiPermissionGrantDefaultEntityType={
          PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
        localStorageKey={LocalStorageService.COST_CENTER_TABLE}
        hasActiveStateFilter
        hasMultiPermissionGrantEditing
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CostCenterTable),
  'Kostenstellen konnten nicht geladen werden.',
);
