import React from 'react';
import { connect } from 'react-redux';

import { UserSignatureIcon } from '~/assets/icons';

import { withErrorBoundary } from '~/ui/atoms';
import Tile from './Tile';
import { LOADING_STATE } from '~/constants/LoadingState';
import BasicTable from '../BasicTable';
import LocalStorageService from '~/services/localStorage.service';
import Log from '~/utils/Log';
import ContextMenu from '../menu/ContextMenu';
import Invoice from '~/models/invoices/Invoice';
import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';
import unitUtils from '~/utils/unitUtils';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import BrowserUtils from '~/utils/browserUtils';
import { ROUTE } from '~/constants/Route';
import { InvoiceColumnCombinedStatus } from '../invoices/invoiceColumns/InvoiceColumnCombinedStatus';
import InvoiceColumnUnsignedDeliveryNoteIds from '../invoices/invoiceColumns/InvoiceColumnUnsignedDeliveryNoteIds';
import { RequestSignatureForm } from '../deliveries/deliveryNoteActions/RequestSignatureForm';

const mapStateToProps = (state) => ({
  invoices: state.invoices,
  oldestFilteredDlnDate: state.filters.oldestFilteredDlnDate,
});

class InvoicesListTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredRows: [],
      loading: LOADING_STATE.NOT_LOADED,
      contextMenu: null,
      requestDeliveryNoteSignatureFormOpenInvoiceId: null,
      requestDeliveryNoteSignatureFormOpenDeliveryNoteIds: [],
    };
  }

  componentDidMount() {
    this.filterRows();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.timeframe) !==
        JSON.stringify(prevProps.timeframe) ||
      this.props.invoices.filteredIncomingInvoicesVersion !==
        prevProps.invoices.filteredIncomingInvoicesVersion
    ) {
      this.filterRows();
    }
  }

  filterRows() {
    this.setState({
      loading: LOADING_STATE.LOADING,
    });

    const filteredRows = this.props.invoices.filteredIncomingInvoiceRows.filter(
      ({ date, delayedSigned, errorAndWarningCategories }) => {
        const inDateRange =
          Date.parse(date) >= this.props.timeframe.from &&
          Date.parse(date) <= this.props.timeframe.to;

        if (!inDateRange) {
          return false;
        }

        if (
          this.props.type === 'missing_signatures' &&
          errorAndWarningCategories.includes(
            InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY,
          )
        ) {
          return true;
        }

        if (this.props.type === 'delayed_signed' && delayedSigned) {
          return true;
        }

        return false;
      },
    );

    this.setState({
      filteredRows,
      loading: LOADING_STATE.SUCCEEDED,
    });
  }

  onOpenInvoice = (id) => {
    Log.productAnalyticsEvent('Open invoice', Log.FEATURE.HOME);
    this.props.history.push(ROUTE.INCOMING_INVOICE.ROUTE + '/' + id);
  };
  onOpenInvoiceInNewTab = () => {
    Log.productAnalyticsEvent('Open invoice in new tab', Log.FEATURE.HOME);
    BrowserUtils.openNewTab(
      ROUTE.INCOMING_INVOICE.ROUTE + '/' + this.state.contextMenu.id,
    );
    this.onCloseContextMenu();
  };
  onOpenContextMenu = (event) => {
    event.preventDefault();

    if (this.state.contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      this.setState({
        contextMenu: null,
      });

      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: {
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
        id: event.currentTarget.dataset.id,
      },
    });
  };
  onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: null,
    });
  };
  onRequestSignatures = (id, deliveryNoteIds) => {
    this.setState({
      requestDeliveryNoteSignatureFormOpenInvoiceId: id,
      requestDeliveryNoteSignatureFormOpenDeliveryNoteIds: deliveryNoteIds,
    });
  };

  getColumns() {
    const columns = [];

    if (this.props.type === 'missing_signatures') {
      columns.push({
        field: 'unsignedDeliveryNoteIds',
        headerName: 'Aktionen',
        sortable: true,
        width: 100,
        renderCell: (params) => (
          <InvoiceColumnUnsignedDeliveryNoteIds
            params={params}
            onRequestSignatures={this.onRequestSignatures}
          />
        ),
      });
    }

    columns.push(
      {
        field: 'combinedStatus',
        headerName: 'Status',
        width: 130,
        sortable: true,
        renderCell: (params) => <InvoiceColumnCombinedStatus params={params} />,
      },
      {
        field: Invoice.PROPERTY.SELLER.KEY,
        headerName: Invoice.PROPERTY.SELLER.STRING,
        width: 200,
        sortable: true,
        resizableText: true,
      },
      {
        field: Invoice.PROPERTY.NUMBER.KEY,
        headerName: Invoice.PROPERTY.NUMBER.STRING,
        width: 100,
        sortable: true,
        resizableText: true,
      },
      {
        field: Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY,
        headerName: Invoice.PROPERTY.TOTAL_PRICE_GROSS.STRING,
        width: 100,
        sortable: true,
        resizableText: true,
        type: 'number',
        renderCell: (params) =>
          unitUtils.formatDeMoneyAmount_safe(params.value),
      },
      {
        field: Invoice.PROPERTY.CURRENCY.KEY,
        headerName: Invoice.PROPERTY.CURRENCY.STRING,
        width: 10,
        sortable: true,
        resizableText: true,
      },
      {
        field: Invoice.PROPERTY.DATE.KEY,
        headerName: Invoice.PROPERTY.DATE.STRING,
        type: 'date',
        width: 120,
        sortable: true,
        resizableText: true,
        valueGetter: parseDate,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
      },
      {
        field: Invoice.PROPERTY.TO_SITE.KEY,
        headerName: Invoice.PROPERTY.TO_SITE.STRING,
        width: 300,
        sortable: true,
        resizableText: true,
      },
    );

    return columns;
  }

  getTitle() {
    if (this.props.type === 'missing_signatures') {
      return (
        'Rechnungen mit fehlenden Signaturen: ' + this.state.filteredRows.length
      );
    }

    if (this.props.type === 'delayed_signed') {
      return (
        'Rechnungen mit nachträglichen Signaturen: ' +
        this.state.filteredRows.length
      );
    }

    return null;
  }

  getRightAnnotation() {
    if (!this.props.oldestFilteredDlnDate) {
      return null;
    }

    const fromDate = dateUtils.getFormattedDate_safe(
      this.props.timeframe.from,
      dateUtils.DATE_FORMAT.DD_MM_YYYY,
    );

    const toDate = dateUtils.getFormattedDate_safe(
      this.props.timeframe.to,
      dateUtils.DATE_FORMAT.DD_MM_YYYY,
    );

    return `(${fromDate} - ${toDate})`;
  }

  getCookieId() {
    if (this.props.type === 'missing_signatures') {
      return LocalStorageService.MISSING_SIGNATURES_INVOICES_TILE;
    }

    if (this.props.type === 'delayed_signed') {
      return LocalStorageService.DELAYED_SIGNATURES_INVOICES_TILE;
    }

    return null;
  }

  render() {
    return (
      <Tile
        title={this.getTitle()}
        rightAnnotation={this.getRightAnnotation()}
        icon={<UserSignatureIcon className="icon-medium" />}
        width={2}
        loading={this.props.invoices.incomingInvoicesLoading}
      >
        <div className="mt-10px h-225px">
          <BasicTable
            rows={this.state.filteredRows}
            columns={this.getColumns()}
            pageSize={100_000}
            onRowClick={(rowData) => this.onOpenInvoice(rowData.row.id)}
            onRowRightClick={this.onOpenContextMenu}
            sortModel={[
              {
                field: Invoice.PROPERTY.DATE.KEY,
                sort: 'desc',
              },
            ]}
            loading={this.state.loading}
            localStorageKey={this.getCookieId()}
            productAnalyticsFeature={Log.FEATURE.HOME}
            hideFooter
            hideHeader
          />
          <ContextMenu
            contextMenu={this.state.contextMenu}
            onClose={this.onCloseContextMenu}
            onOpen={() => this.onOpenInvoice(this.state.contextMenu.id)}
            onOpenInNewTab={this.onOpenInvoiceInNewTab}
          />
          {/* For an explanation why the form is not in the InvoiceColumnUnsignedDeliveryNoteIds component, check the comments in DeliveryList.js */}
          {this.state.requestDeliveryNoteSignatureFormOpenInvoiceId ? (
            <RequestSignatureForm
              permittedUserIds={[]}
              deliveryNoteIds={
                this.state.requestDeliveryNoteSignatureFormOpenDeliveryNoteIds
              }
              open={this.state.requestDeliveryNoteSignatureFormOpenInvoiceId}
              closeForm={() =>
                this.setState({
                  requestDeliveryNoteSignatureFormOpenInvoiceId: null,
                  requestDeliveryNoteSignatureFormOpenDeliveryNoteIds: [],
                })
              }
            />
          ) : null}
        </div>
      </Tile>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(InvoicesListTile),
  null,
);
