export const columns = [
  {
    field: 'licensePlate',
    headerName: 'Kennzeichen',
    width: 250,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'company',
    headerName: 'Firma',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
] as const;
