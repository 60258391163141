export const columns = [
  {
    field: 'email',
    headerName: 'E-Mail',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'companyName',
    headerName: 'Firma',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'isActive',
    headerName: 'Aktiv',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
] as const;
