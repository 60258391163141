import { useQueries, useQuery } from '@tanstack/react-query';

import DeliveriesService from '~/services/deliveries.service';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysDeliveryNote } from './queryKeys';

const getSharedWithUsersQueryOptions = ({
  deliveryNoteId,
  options,
}: {
  deliveryNoteId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => ({
  queryKey: queryKeysDeliveryNote.getSharedWithUsers(deliveryNoteId),
  queryFn: () => DeliveriesService.getUsersOfSharedDeliveryNote(deliveryNoteId),
  ...options,
});

export const useQuerySharedWithUsersOfDln = (
  deliveryNoteId: UUID,
  options?: Parameters<typeof useQuery>[0],
) =>
  useQuery(
    getSharedWithUsersQueryOptions({
      deliveryNoteId,
      options,
    }),
  );

const combineUsers = (results) => {
  const isLoading = results.some(({ isLoading }) => isLoading);
  const isError = results.some(({ isError }) => isError);

  const sharedUserIds =
    isLoading || isError ? [] : results.flatMap(({ data }) => data || []);

  return {
    data: sharedUserIds,
    isLoading,
    isError,
  };
};

export const useQuerySharedWithUsersOfDlns = (deliveryNoteIds: UUID[] = []) =>
  useQueries({
    queries: deliveryNoteIds.filter(Boolean).map((deliveryNoteId) =>
      getSharedWithUsersQueryOptions({
        deliveryNoteId,
        options: {
          onError(error: unknown) {
            Log.error(
              `Failed to get users of shared delivery note ${deliveryNoteId}.`,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to load shared users',
              Log.FEATURE.SHARE_DELIVERY_NOTE,
              Log.TYPE.ERROR,
            );
          },
        },
      }),
    ),
    combine: (results) => combineUsers(results),
  });
