import { type UUID } from '~/types/common';

type VehicleQueryParams = Record<string, string | number | undefined>; // TODO: type vehicles query

/**
 * Query key factory for vehicle-related queries.
 */
export const queryKeysVehicle = {
  base: () => 'vehicles' as const,
  get: (vehicleId: UUID) =>
    [queryKeysVehicle.base(), 'detail', { vehicleId }] as const,
  getAll: (queryParams: Partial<VehicleQueryParams>) =>
    [queryKeysVehicle.base(), 'list', queryParams] as const,
} as const;
