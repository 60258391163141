import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { promiseHandler } from '~/utils/promiseHandler';

import Log from '~/utils/Log';

export const updateSignatureRoles = async (userId, userSignatureRoles) => {
  Log.productAnalyticsEvent('Update signature roles', Log.FEATURE.USER);

  const [, error] = await promiseHandler(
    UserService.updateUserSignatureRoles(userId, userSignatureRoles),
  );

  if (error) {
    Log.productAnalyticsEvent(
      'Failed to update signature roles',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );

    const lines = [
      ToastService.MESSAGE.USER_SIGNATURE_ROLE_UPDATE_FAILED,
      UserService.getUserEmailFromStore(userId),
    ];
    ToastService.httpError(lines, error.response);
  }
};
