import cloneDeep from 'lodash/cloneDeep';
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, TextField } from '@mui/material';

import { LOADING_STATE } from '~/constants/LoadingState';

import { useQueryOrganizationalUnit } from '~/data/organizationalUnit';

import OrganisationalGroup from '~/models/masterdata/OrganisationalGroup';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import OrganisationalGroupService from '~/services/organisationalGroup.service';
import ToastService from '~/services/toast.service';

import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import BasicForm from '~/components/BasicForm';

import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';
import ObjectUtils from '~/utils/objectUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

import { OrganisationalGroupPaths } from '../../paths/OrganisationalGroupPaths';
import { PermissionGrantEntityTable } from '../../permissionGrant/PermissionsTable';

import { selectOrganizationalUnit } from '../selectOrganizationalUnit';

export const OrganizationalUnitForm = ({
  closeForm,
  onOpenCompany,
  onOpenCostCenter,
  onOpenOrganisationalGroup,
  onOpenSite,
  onOpenUser,
  onOpenUserGroup,
  onOpenVehicle,
  onRefreshEntities,
  onUpdatedCompaniesChange,
  onUpdatedCostCentersChange,
  onUpdatedSitesChange,
  onUpdatedUserGroupsChange,
  onUpdatedUsersChange,
  onUpdatedVehiclesChange,
  open,
  organizationalUnitId,
  type,
}) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [deletingForm, setDeletingForm] = useState(false);
  const [organisationalGroupState, setOrganisationalGroupState] = useState(
    new OrganisationalGroup(),
  );
  const [organisationalGroupLoading, setOrganisationalGroupLoading] = useState(
    LOADING_STATE.NOT_LOADED,
  );

  const {
    data: organisationalGroup,
    isLoading,
    isError,
  } = useQueryOrganizationalUnit(organizationalUnitId, {
    select: selectOrganizationalUnit,
  });

  const resetForm = useCallback(
    (resetGeneralOrganisationalGroupInformation) => {
      if (!resetGeneralOrganisationalGroupInformation) {
        setOrganisationalGroupState((prevState) => {
          const newOrganisationalGroup = cloneDeep(prevState);
          newOrganisationalGroup.permissionGrantsFrom = organisationalGroup
            ? organisationalGroup.permissionGrantsFrom
            : new OrganisationalGroup().permissionGrantsFrom;

          return newOrganisationalGroup;
        });

        return;
      }

      setOrganisationalGroupState(
        organisationalGroup ?? new OrganisationalGroup(),
      );

      if (organisationalGroup && !organisationalGroup.additionalDataInitiated) {
        refreshOrganisationalGroup();
      }
    },
    [organisationalGroup],
  );

  useEffect(() => {
    if (organisationalGroup) {
      resetForm(true);
    }
  }, [organisationalGroup, resetForm]);

  useEffect(() => {
    if (
      organisationalGroup &&
      JSON.stringify(organisationalGroup) !==
        JSON.stringify(organisationalGroupState)
    ) {
      resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          organisationalGroup,
          organisationalGroupState,
          'permissionGrantsFrom',
        ),
      );
    }
  }, [organisationalGroup, organisationalGroupState, resetForm]);

  const renderForCreate = () => type === 'create';

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !organisationalGroupState.isHighestOrganisationalGroup() &&
      organisationalGroupState.parentOrganisationalGroups.length === 0
    ) {
      ToastService.warning([
        'Bitte wähle mindestens eine "Mutter"-Organisations-Gruppe aus, zu der diese Organisations-Gruppe gehört.',
      ]);

      Log.productAnalyticsEvent(
        'Missing parent organisational group',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    setSubmittingForm(true);

    const {
      costCenters,
      childOrganisationalGroups,
      companies,
      name,
      parentOrganisationalGroups,
      sites,
      userGroups,
      users,
      vehicles,
    } = organisationalGroupState;

    const body = {
      accounting_references: costCenters,
      child_ous: childOrganisationalGroups,
      companies,
      name,
      parent_ous: parentOrganisationalGroups,
      sites,
      user_groups: userGroups,
      users,
      vehicles,
    };

    Log.info(
      'Submit organisational group form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.ORGANISATIONAL_GROUP);

    if (renderForCreate()) {
      const [, error] = await promiseHandler(
        OrganisationalGroupService.createOrganisationalGroup(body),
      );

      if (error) {
        ToastService.httpError(
          ['Organisations-Gruppe konnte nicht angelegt werden.'],
          error.response,
        );

        Log.error('Failed to create organisational group.', error);
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.ORGANISATIONAL_GROUP,
          Log.TYPE.ERROR,
        );

        setSubmittingForm(false);
        return;
      }
    } else {
      const [, error] = await promiseHandler(
        OrganisationalGroupService.updateOrganisationalGroup(
          organisationalGroup.id,
          {
            name: body.name,
          },
        ),
      );

      // Should be passed via body to not have those many backend calls but currently the backend doesn't accept it like this.
      // Marc will change this: https://innovent-consulting.atlassian.net/browse/VGSD-2316
      const [, error1] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.USER.KEY,
          organisationalGroup.users,
          organisationalGroupState.users,
        ),
      );
      const [, error2] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.SITE.KEY,
          organisationalGroup.sites,
          organisationalGroupState.sites,
        ),
      );
      const [, error3] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
          organisationalGroup.costCenters,
          organisationalGroupState.costCenters,
        ),
      );
      const [, error4] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.VEHICLE.KEY,
          organisationalGroup.vehicles,
          organisationalGroupState.vehicles,
        ),
      );
      const [, error5] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
          organisationalGroup.companies,
          organisationalGroupState.companies,
        ),
      );
      const [, error6] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY,
          organisationalGroup.userGroups,
          organisationalGroupState.userGroups,
        ),
      );
      // update child organisational units
      const [, error7] = await promiseHandler(
        OrganisationalGroupService.updateEntities(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          organisationalGroup.childOrganisationalGroups,
          organisationalGroupState.childOrganisationalGroups,
        ),
      );
      // update parent organisational units
      const [, error8] = await promiseHandler(
        OrganisationalGroupService.updateParentOrganisationalGroups(
          organisationalGroup.id,
          PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          organisationalGroup.parentOrganisationalGroups,
          organisationalGroupState.parentOrganisationalGroups,
        ),
      );

      if (
        error ||
        error1 ||
        error2 ||
        error3 ||
        error4 ||
        error5 ||
        error6 ||
        error7 ||
        error8
      ) {
        ToastService.error([
          'Organisations-Gruppe konnte nicht vollständig aktualisiert werden.',
        ]);
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.ORGANISATIONAL_GROUP,
          Log.TYPE.ERROR,
        );
        Log.error(
          'Failed to update organisational group.',
          error ||
            error1 ||
            error2 ||
            error3 ||
            error4 ||
            error5 ||
            error6 ||
            error7 ||
            error8,
        );
        setSubmittingForm(false);
        return;
      }
    }

    setSubmittingForm(false);
    closeForm();
    resetForm(true);
    await OrganisationalGroupService.refreshOrganisationalGroups();
    onRefreshEntities();
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.ORGANISATIONAL_GROUP);

    closeForm();
    resetForm(true);
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete organisational group',
      {
        id: organisationalGroup.id,
      },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.ORGANISATIONAL_GROUP);

    setDeletingForm(true);

    const [, error] = await promiseHandler(
      OrganisationalGroupService.deleteOrganisationalGroup(
        organisationalGroup.id,
      ),
    );

    if (error) {
      ToastService.httpError(
        ['Organisations-Gruppe konnte nicht gelöscht werden.'],
        error.response,
      );

      Log.error('Failed to delete organisational group.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.ERROR,
      );

      setDeletingForm(false);
      return;
    }

    setDeletingForm(false);
    closeForm();
    await OrganisationalGroupService.refreshOrganisationalGroups();
    onRefreshEntities();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setOrganisationalGroupState((prevState) => {
      const newOrganisationalGroup = cloneDeep(prevState);
      newOrganisationalGroup[name] = value;

      Log.info(
        `Change form value of ${name}`,
        {
          from: prevState[name],
          to: value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );

      FunctionUtils.delayFunction(
        'organisational_group_change_name',
        Log.productAnalyticsEvent,
        ['Change name', Log.FEATURE.ORGANISATIONAL_GROUP],
      );

      return newOrganisationalGroup;
    });
  };

  const handleChangeEntity = (entityType, entities) => {
    setOrganisationalGroupState((prevState) => {
      const newOrganisationalGroup = cloneDeep(prevState);
      newOrganisationalGroup[entityType] = entities.map(({ id }) => id);

      Log.info(
        `Change form value of ${entityType}`,
        {
          from: prevState[entityType],
          to: newOrganisationalGroup[entityType],
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        `Change ${entityType}`,
        Log.FEATURE.ORGANISATIONAL_GROUP,
      );

      return newOrganisationalGroup;
    });
  };

  const refreshOrganisationalGroup = async () => {
    setOrganisationalGroupLoading(LOADING_STATE.LOADING);

    const [, error] = await promiseHandler(
      OrganisationalGroupService.refreshOrganisationalGroup(
        organisationalGroup.id,
      ),
    );

    if (error) {
      setOrganisationalGroupLoading(LOADING_STATE.FAILED);
      return;
    }

    setOrganisationalGroupLoading(LOADING_STATE.SUCCEEDED);
  };

  const getPaths = () => {
    if (renderForCreate()) {
      return null;
    }

    if (organisationalGroup?.isHighestOrganisationalGroup?.()) {
      return 'Diese Organisations-Gruppe ist die höchste Gruppe in der Hierarchie.';
    }

    return (
      <OrganisationalGroupPaths
        id={organisationalGroup?.id}
        organisationalGroupPaths={organisationalGroup?.organisationalGroupPaths?.map(
          (organisationalGroupPath) => organisationalGroupPath.slice(0, -1),
        )}
        onOpenOrganisationalGroup={(og) =>
          onOpenOrganisationalGroup(og, getUnsavedChanges())
        }
      />
    );
  };

  const getUnsavedChanges = () => {
    if (renderForCreate()) {
      return [];
    }

    return OrganisationalGroup.getDifferentValues(
      organisationalGroup,
      organisationalGroupState,
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading organizational unit data</div>;
  }

  return (
    <BasicForm
      open={open}
      formSuccess={handleSubmit}
      formAbort={handleCancel}
      formDelete={
        !renderForCreate() &&
        !organisationalGroup?.isHighestOrganisationalGroup?.()
          ? handleDelete
          : null
      }
      title={
        'Organisations-Gruppe ' +
        (renderForCreate() ? 'Erstellen' : organisationalGroupState.name)
      }
      fullWidth
      submittingForm={submittingForm}
      deletingForm={deletingForm}
      id={organisationalGroup?.id}
      unsavedChanges={getUnsavedChanges()}
      missingPermissionsToSubmit={
        renderForCreate()
          ? !UserUtils.isOrganisationalGroupCreateAllowedUser()
          : !UserUtils.isOrganisationalGroupWriteAllowedUser()
      }
      missingPermissionsToDelete={
        !UserUtils.isOrganisationalGroupDeleteAllowedUser()
      }
    >
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              {getPaths()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3 className="main-text mt-0">Organisations-Gruppe</h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="name-input"
                name="name"
                label="Name"
                type="text"
                fullWidth
                required
                value={organisationalGroupState.name}
                onChange={handleInputChange}
                autoFocus
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3 className="mt-20px main-text">Enthält die folgenden...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={PermissionGrant.ENTITY_TYPE.USER.KEY}
                pickedIds={organisationalGroupState.users}
                callbackPickedItems={(users) =>
                  handleChangeEntity('users', users)
                }
                onChipClick={(user) => onOpenUser(user, getUnsavedChanges())}
                onUpdatedItemsChange={onUpdatedUsersChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={PermissionGrant.ENTITY_TYPE.SITE.KEY}
                pickedIds={organisationalGroupState.sites}
                callbackPickedItems={(sites) =>
                  handleChangeEntity('sites', sites)
                }
                onChipClick={(site) => onOpenSite(site, getUnsavedChanges())}
                onUpdatedItemsChange={onUpdatedSitesChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY}
                pickedIds={organisationalGroupState.costCenters}
                callbackPickedItems={(costCenters) =>
                  handleChangeEntity('costCenters', costCenters)
                }
                onChipClick={(costCenter) =>
                  onOpenCostCenter(costCenter, getUnsavedChanges())
                }
                onUpdatedItemsChange={onUpdatedCostCentersChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={PermissionGrant.ENTITY_TYPE.VEHICLE.KEY}
                pickedIds={organisationalGroupState.vehicles}
                callbackPickedItems={(vehicles) =>
                  handleChangeEntity('vehicles', vehicles)
                }
                onChipClick={(vehicle) =>
                  onOpenVehicle(vehicle, getUnsavedChanges())
                }
                onUpdatedItemsChange={onUpdatedVehiclesChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={PermissionGrant.ENTITY_TYPE.COMPANY.KEY}
                pickedIds={organisationalGroupState.companies}
                callbackPickedItems={(companies) =>
                  handleChangeEntity('companies', companies)
                }
                onChipClick={(company) =>
                  onOpenCompany(company, getUnsavedChanges())
                }
                onUpdatedItemsChange={onUpdatedCompaniesChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={
                  PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                }
                pickedIds={organisationalGroupState.childOrganisationalGroups}
                callbackPickedItems={(childOrganisationalGroups) =>
                  handleChangeEntity(
                    'childOrganisationalGroups',
                    childOrganisationalGroups,
                  )
                }
                onChipClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(
                    organisationalGroup,
                    getUnsavedChanges(),
                  )
                }
                // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
                // onUpdatedItemsChange={this.props.onUpdatedOrganisationalGroupsChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY}
                pickedIds={organisationalGroupState.userGroups}
                callbackPickedItems={(userGroups) =>
                  handleChangeEntity('userGroups', userGroups)
                }
                onChipClick={(userGroup) =>
                  onOpenUserGroup(userGroup, getUnsavedChanges())
                }
                onUpdatedItemsChange={onUpdatedUserGroupsChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3 className="mt-20px main-text">Ist Teil von...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <ComplexPaginatedEntityMultiPicker
                entityType={
                  PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                }
                pickedIds={organisationalGroupState.parentOrganisationalGroups}
                callbackPickedItems={(parentOrganisationalGroups) =>
                  handleChangeEntity(
                    'parentOrganisationalGroups',
                    parentOrganisationalGroups,
                  )
                }
                onChipClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(
                    organisationalGroup,
                    getUnsavedChanges(),
                  )
                }
                // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
                // onUpdatedItemsChange={this.props.onUpdatedOrganisationalGroupsChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {renderForCreate() ? null : (
          <Grid item xs={12}>
            <PermissionGrantEntityTable
              title="Wer ist auf diese Organisations-Gruppe berechtigt?"
              permissionGrantsFrom={
                organisationalGroupState.permissionGrantsFrom
              }
              defaultEntities={[organisationalGroup?.id]}
              defaultEntityType={
                PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
              }
              fixedPicker={PermissionGrant.TYPE.ENTITY}
              refreshData={refreshOrganisationalGroup}
              loading={organisationalGroupLoading}
            />
          </Grid>
        )}
      </Grid>
    </BasicForm>
  );
};
