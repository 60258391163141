import { type UUID } from '~/types/common';

import { type SitesQueryParams } from './useQuerySites';

/**
 * Query key factory for site-related queries.
 */
export const queryKeysSite = {
  base: () => 'sites' as const,
  get: (siteId: UUID) => [queryKeysSite.base(), 'detail', { siteId }] as const,
  getAll: (queryParams: Partial<SitesQueryParams>) =>
    [queryKeysSite.base(), 'list', queryParams] as const,
  getAllLegacy: () => [queryKeysSite.base(), 'list', 'LEGACY'] as const,
  getSiteIdentifierProposals: () =>
    [queryKeysSite.base(), 'siteIdentifierProposals'] as const,
} as const;
