import snakecaseKeys from 'snakecase-keys';
import { useEffect } from 'react';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';

import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { vestigasApi } from 'services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { queryKeysVehicle } from './queryKeys';

export type VehicleQueryParams = {
  limit: number;
  offset: number;
  searchString?: string;
  filterActive?: boolean;
  filterCompany?: string;
  orderBy:
    | 'id'
    | 'license_plate'
    | 'company_id'
    | 'company_name'
    | 'created_on'
    | 'modified_on';
  returnTotalCount: boolean;
  sort: 'ASC' | 'DESC';
};

const defaultQueryParams: VehicleQueryParams = {
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  searchString: undefined,
  filterActive: undefined,
  filterCompany: undefined,
  orderBy: 'license_plate',
  returnTotalCount: true,
  sort: 'ASC',
};

export type VehicleListItem = {
  companyId: UUID;
  companyName: string;
  createdOn: string;
  id: UUID;
  isActive: boolean;
  licensePlate: string;
  modifiedOn: string;
};

type VehicleResponse = {
  count: number;
  data: VehicleListItem[];
};

/**
 * Generates the query options for the vehicles query so they can be shared between the useQuery hook and the prefetching.
 */
export const getVehiclesQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<VehicleQueryParams>;
  options?: UseQueryOptions;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryKey: queryKeysVehicle.getAll(qp),
    queryFn: async () => fetchVehicles(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all vehicles from the API.
 * @see https://app.dev.vestigas.com/redoc#tag/Vehicle/operation/get_vehicles_vehicle_get
 */
export const fetchVehicles = async (
  queryParams: Partial<VehicleQueryParams>,
): Promise<VehicleResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase() as 'ASC' | 'DESC';

  for (const key of Object.keys(qp)) {
    if (qp[key] === undefined) {
      delete qp[key];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.VEHICLE.GET_ALL, {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<VehicleResponse>();

    return response;
  } catch (error) {
    Log.error('Error fetching vehicle', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all vehicles with given query parameters.
 * Handles pagination and prefetches the next page of vehicles for better performance.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Vehicle/operation/get_vehicles_vehicle_get
 */
export const useQueryVehicles = (
  queryParams: Partial<VehicleQueryParams>,
  options: UseQueryOptions,
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getVehiclesQueryOptions({
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? defaultQueryParams.limit),
        },
        options,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery({
    ...getVehiclesQueryOptions({ queryParams, options }),
    placeholderData: keepPreviousData,
  });
};
