import { useEffect } from 'react';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { queryKeysCompany } from './queryKeys';

export type CompaniesQueryParams = {
  limit: number;
  offset: number;
  searchString?: string;
  orderBy: 'name' | 'id' | 'city' | 'country';
  sort: PaginationSortDirection;
};

export type Address = {
  city: string;
  country: string;
  postCode: string;
  streetName: string;
  buildingNumber: string;
};

export type CompanyData = {
  name: string;
  address: Address;
  addDlnsToInvoice?: boolean;
  invoiceReceiverList?: any[];
  sendInvoiceExternalXml?: boolean;
  dlnsMultiFileToInvoice?: boolean;
  emailReceivedTimeToSubject?: boolean;
  addInvoiceProtocolToInvoice?: boolean;
  dlnSimplifiedJsonsToInvoice?: boolean;
  orgUnits?: UUID[];
};

export type Company = {
  id: UUID;
  name: string;
  data: CompanyData;
};

type CompaniesResponse = {
  count: number;
  data: Company[];
};

const defaultQueryParams: CompaniesQueryParams = {
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  searchString: undefined,
  orderBy: 'name',
  sort: 'ASC',
};

/**
 * Generates the query options for the companies query so they can be shared between the useQuery hook and the prefetching.
 */
export const getCompaniesQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<CompaniesQueryParams>;
  options?: Omit<
    UseQueryOptions<CompaniesResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryKey: queryKeysCompany.getAll(qp),
    queryFn: async () => fetchCompanies(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all companies from the API.
 * @param queryParams - The query parameters for fetching companies.
 * @returns The company data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Company/operation/get_companies_company_get
 */
export const fetchCompanies = async (
  queryParams: Partial<CompaniesQueryParams>,
): Promise<CompaniesResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase() as PaginationSortDirection;

  for (const key of Object.keys(qp)) {
    if (qp[key as keyof CompaniesQueryParams] === undefined) {
      delete qp[key as keyof CompaniesQueryParams];
    }
  }

  try {
    return await vestigasApi
      .get(ENDPOINT.COMPANY.GET_ALL, { searchParams: qp })
      .json<CompaniesResponse>();
  } catch (error) {
    Log.error('Error fetching companies', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all companies with given query parameters.
 * Handles pagination and prefetches the next page of companies for better performance.
 */
export const useQueryCompanies = (
  queryParams: Partial<CompaniesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getCompaniesQueryOptions({
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
        options,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<CompaniesResponse, Error>({
    ...getCompaniesQueryOptions({ queryParams, options }),
    placeholderData: keepPreviousData,
  });
};
