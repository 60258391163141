import { useQueries } from '@tanstack/react-query';

import { fetchUser, queryKeysUser } from '~/data/user';

import { type UUID } from '~/types/common';

type SelectedUser = {
  id: UUID;
  email: string;
  name: string;
};

/**
 * Custom hook to fetch and process user data for selected user IDs.
 *
 * @param selectedUserIds - An array of UUIDs representing the selected user IDs.
 * @returns An object containing:
 *   - data: An array of SelectedUser objects with id, email, and name.
 *   - isLoading: A boolean indicating if any of the queries are still loading.
 *   - isSuccess: A boolean indicating if all queries were successful.
 */
export const useSelectedUsers = (selectedUserIds: UUID[]) =>
  useQueries({
    queries: selectedUserIds.map((userId) => ({
      queryKey: queryKeysUser.get(userId),
      queryFn: () => fetchUser(userId),
    })),
    combine: (results) => ({
      data: results
        .map(({ data }) => data)
        .filter(Boolean)
        .map(({ id, email, firstName, lastName }) => ({
          id,
          email: email ?? '',
          name: [firstName, lastName].filter(Boolean).join(' '),
        })) as SelectedUser[],
      isError: results.some(({ isError }) => isError),
      isLoading: results.some(({ isLoading }) => isLoading),
      isSuccess: results.every(({ isSuccess }) => isSuccess),
    }),
  });
