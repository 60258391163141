import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import UserUtils from '~/utils/userUtils';
import { promiseHandler } from '~/utils/promiseHandler';

import Log from '~/utils/Log';

export const updateDefaultSignatureRole = async (
  userId,
  defaultSignatureRole,
) => {
  if (!UserUtils.isVestigasAccount()) {
    return;
  }

  Log.productAnalyticsEvent('Update default signature role', Log.FEATURE.USER);

  const [, error] = await promiseHandler(
    UserService.updateUserDefaultSignatureRole(userId, defaultSignatureRole),
  );

  if (error) {
    const lines = [
      ToastService.MESSAGE.USER_DEFAULT_SIGNATURE_ROLE_UPDATE_FAILED,
      UserService.getUserEmailFromStore(userId),
    ];
    ToastService.httpError(lines, error.response);
    Log.productAnalyticsEvent(
      'Failed to update default signature role',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  }
};
