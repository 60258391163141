import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  CreateDeliveryIcon,
  CreateDeliveryOutlinedIcon,
  DeliveryIcon,
  DeliveryOutlinedIcon,
  EditIcon,
  EditOutlinedIcon,
  FolderIcon,
  FolderOutlinedIcon,
  HomeIcon,
  HomeOutlinedIcon,
  IncomingInvoiceIcon,
  IncomingInvoiceOutlinedIcon,
  InvoiceIcon,
  InvoiceOutlinedIcon,
  OutgoingInvoiceIcon,
  OutgoingInvoiceOutlinedIcon,
  PieChartIcon,
  PieChartOutlinedIcon,
  ReportIcon,
  ReportOutlinedIcon,
  SettingsIcon,
  SettingsOutlinedIcon,
  SupplierIcon,
} from '~/assets/icons';
import {
  PersonSearch as PersonSearchIcon,
  PersonSearchOutlined as PersonSearchOutlinedIcon,
} from '@mui/icons-material';

import { ROUTE } from '~/constants/Route';
import { TAB } from '~/constants/Tab';

import {
  setDelivery_tab as setDeliveryTab,
  setDashboard_tab as setDashboardTab,
} from '~/redux/filtersSlice';

import FeatureService from '~/services/feature.service';
import DeliveriesService from '~/services/deliveries.service';

import RouteUtils from '~/utils/routeUtils';
import Log from '~/utils/Log';

const getMainNavItems = ({
  currentPath,
  dashboardDateRange,
  dataExchanges,
  dispatch,
  filters,
  setDashboardTab,
  setDeliveryTab,
}) => [
  {
    name: 'Home',
    filledIcon: <HomeIcon className="icon-small" />,
    outlinedIcon: <HomeOutlinedIcon className="icon-small" />,
    children: [
      {
        name: 'Home',
        route: ROUTE.HOME.ROUTE,
        filledIcon: <HomeIcon className="icon-extra-small" />,
        outlinedIcon: <HomeOutlinedIcon className="icon-extra-small" />,
        isSelected: currentPath === ROUTE.HOME.ROUTE || currentPath === '/home',
      },
    ],
  },
  {
    name: 'Statistiken',
    filledIcon: <PieChartIcon className="icon-small" />,
    outlinedIcon: <PieChartOutlinedIcon className="icon-small" />,
    children: [
      {
        name: TAB.DASHBOARD.CHARTS.NAME,
        route: ROUTE.DASHBOARD.ROUTE,
        filledIcon: <PieChartIcon className="icon-extra-small" />,
        outlinedIcon: <PieChartOutlinedIcon className="icon-extra-small" />,
        callback: () => dispatch(setDashboardTab(TAB.DASHBOARD.CHARTS.INDEX)),
        isSelected:
          currentPath === ROUTE.DASHBOARD.ROUTE &&
          filters.dashboard_tab === TAB.DASHBOARD.CHARTS.INDEX,
      },
      {
        name: TAB.DASHBOARD.REPORT.NAME,
        route: ROUTE.DASHBOARD.ROUTE,
        filledIcon: <ReportIcon className="icon-extra-small" />,
        outlinedIcon: <ReportOutlinedIcon className="icon-extra-small" />,
        callback() {
          dispatch(setDashboardTab(TAB.DASHBOARD.REPORT.INDEX));
          Log.productAnalyticsEvent(
            'Open dashboard reports tab',
            Log.FEATURE.MENU,
          );
        },
        isSelected:
          currentPath === ROUTE.DASHBOARD.ROUTE &&
          filters.dashboard_tab === TAB.DASHBOARD.REPORT.INDEX,
        disabled: DeliveriesService.isArchiveMode(dashboardDateRange),
        disabledByClientPortal: FeatureService.clientPortal(),
        disabledByPackageBasicRestriction:
          FeatureService.packageBasicRestriction(),
      },
      {
        name: 'Benutzerstatistiken',
        route: ROUTE.USER_METRICS.ROUTE,
        filledIcon: <PersonSearchIcon className="icon-extra-small" />,
        outlinedIcon: <PersonSearchOutlinedIcon className="icon-extra-small" />,
        isSelected: currentPath === ROUTE.USER_METRICS.ROUTE,
        disabledByClientPortal: FeatureService.clientPortal(),
        disabledByPackageBasicRestriction:
          FeatureService.packageBasicRestriction(),
      },
    ],
  },
  {
    name: 'Lieferungen',
    filledIcon: <DeliveryIcon className="icon-small" />,
    outlinedIcon: <DeliveryOutlinedIcon className="icon-small" />,
    children: [
      {
        name: TAB.DELIVERY.LIST.NAME,
        route: ROUTE.DELIVERIES.ROUTE,
        filledIcon: <DeliveryIcon className="icon-extra-small" />,
        outlinedIcon: <DeliveryOutlinedIcon className="icon-extra-small" />,
        callback: () => dispatch(setDeliveryTab(TAB.DELIVERY.LIST.INDEX)),
        isSelected:
          (currentPath === ROUTE.DELIVERIES.ROUTE &&
            filters.delivery_tab !== TAB.DELIVERY.CHANGES.INDEX) ||
          RouteUtils.getMainRoute(currentPath) === ROUTE.DELIVERIES.ROUTE,
      },
      {
        name: TAB.DELIVERY.CHANGES.NAME,
        route: ROUTE.DELIVERIES.ROUTE,
        filledIcon: <EditIcon className="icon-extra-small" />,
        outlinedIcon: <EditOutlinedIcon className="icon-extra-small" />,
        callback() {
          dispatch(setDeliveryTab(TAB.DELIVERY.CHANGES.INDEX));
          Log.productAnalyticsEvent(
            'Open delivery changes tab',
            Log.FEATURE.MENU,
          );
        },
        isSelected:
          currentPath === ROUTE.DELIVERIES.ROUTE &&
          filters.delivery_tab === TAB.DELIVERY.CHANGES.INDEX,
        disabledByClientPortal: FeatureService.clientPortal(),
      },
      {
        name: 'Lieferung anlegen',
        route: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
        filledIcon: <CreateDeliveryIcon className="icon-extra-small" />,
        outlinedIcon: (
          <CreateDeliveryOutlinedIcon className="icon-extra-small" />
        ),
        isSelected: currentPath === ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
      },
      {
        name: 'Leistungsnachweis anlegen',
        route: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
        filledIcon: <CreateDeliveryIcon className="icon-extra-small" />,
        outlinedIcon: (
          <CreateDeliveryOutlinedIcon className="icon-extra-small" />
        ),
        isSelected: currentPath === ROUTE.CREATE_SERVICE_NOTE.ROUTE,
      },
      {
        name: 'Betontagebuch',
        route: ROUTE.CONCRETE_DIARY.ROUTE,
        filledIcon: <FolderIcon className="icon-extra-small" />,
        outlinedIcon: <FolderOutlinedIcon className="icon-extra-small" />,
        isSelected: currentPath === ROUTE.CONCRETE_DIARY.ROUTE,
        disabledByClientPortal: FeatureService.clientPortal(),
        disabledByPackageBasicRestriction:
          FeatureService.packageBasicRestriction(),
      },
    ],
  },
  {
    name: 'Rechnungen',
    filledIcon: <InvoiceIcon className="icon-small" />,
    outlinedIcon: <InvoiceOutlinedIcon className="icon-small" />,
    disabledByClientPortal: FeatureService.clientPortal(),
    disabledByModuleInvoiceRestriction:
      FeatureService.moduleInvoiceRestriction(),
    children: [
      {
        name: 'Eingangsrechnungen',
        route: ROUTE.INCOMING_INVOICES.ROUTE,
        filledIcon: <IncomingInvoiceIcon className="icon-extra-small" />,
        outlinedIcon: (
          <IncomingInvoiceOutlinedIcon className="icon-extra-small" />
        ),
        isSelected:
          currentPath === ROUTE.INCOMING_INVOICES.ROUTE ||
          RouteUtils.getMainRoute(currentPath) ===
            ROUTE.INCOMING_INVOICES.ROUTE,
        disabledByClientPortal: FeatureService.clientPortal(),
        disabledByModuleInvoiceRestriction:
          FeatureService.moduleInvoiceRestriction(),
      },
      {
        name: 'Ausgangsrechnungen',
        route: ROUTE.OUTGOING_INVOICES.ROUTE,
        filledIcon: <OutgoingInvoiceIcon className="icon-extra-small" />,
        outlinedIcon: (
          <OutgoingInvoiceOutlinedIcon className="icon-extra-small" />
        ),
        isSelected:
          currentPath === ROUTE.OUTGOING_INVOICES.ROUTE ||
          RouteUtils.getMainRoute(currentPath) ===
            ROUTE.OUTGOING_INVOICES.ROUTE,
        disabledByClientPortal: FeatureService.clientPortal(),
        disabledByModuleInvoiceRestriction:
          FeatureService.moduleInvoiceRestriction(),
      },
    ],
  },
  {
    name: 'Lieferanten',
    filledIcon: <SupplierIcon className="icon-small" />,
    outlinedIcon: <SupplierIcon className="icon-small" />,
    error: dataExchanges.currentIssues,
    children: [
      {
        name: 'Lieferanten',
        route: ROUTE.DATA_EXCHANGE.ROUTE,
        filledIcon: <SupplierIcon className="icon-extra-small" />,
        outlinedIcon: <SupplierIcon className="icon-extra-small" />,
        isSelected: currentPath === ROUTE.DATA_EXCHANGE.ROUTE,
      },
    ],
  },
  {
    name: 'Einstellungen',
    filledIcon: <SettingsIcon className="icon-small" />,
    outlinedIcon: <SettingsOutlinedIcon className="icon-small" />,
    children: [
      {
        name: 'Einstellungen',
        route: ROUTE.SETTINGS_MASTERDATA.ROUTE,
        filledIcon: <SettingsIcon className="icon-extra-small" />,
        outlinedIcon: <SettingsOutlinedIcon className="icon-extra-small" />,
        isSelected:
          currentPath === ROUTE.SETTINGS_MASTERDATA.ROUTE ||
          RouteUtils.getMainRoute(currentPath) === ROUTE.SETTINGS.ROUTE,
      },
    ],
  },
];

const selectFilters = (state) => state.filters;
const selectDataExchanges = (state) => state.dataExchanges;
const selectDashboardDateRange = (state) =>
  state.filters.dashboard_selectedDateRange;

/**
 * Custom hook to get the main navigation items.
 *
 * This hook combines various pieces of state and dispatch functions to generate
 * the main navigation items for the application. It uses memoization to optimize
 * performance by only recalculating the navigation items when the dependencies change.
 *
 * @returns {Array} An array of navigation item objects, each containing information
 * about a main navigation item and its children.
 */
export const useMainNavItems = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const filters = useSelector(selectFilters);
  const dataExchanges = useSelector(selectDataExchanges);
  const dashboardDateRange = useSelector(selectDashboardDateRange);

  const currentPath = location.pathname;

  return useMemo(
    () =>
      getMainNavItems({
        currentPath,
        dashboardDateRange,
        dataExchanges,
        dispatch,
        filters,
        setDashboardTab,
        setDeliveryTab,
      }),
    [currentPath, dashboardDateRange, dataExchanges, dispatch, filters],
  );
};
