import cloneDeep from 'lodash/cloneDeep';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { LOADING_STATE } from '~/constants/LoadingState';

import { removeCompanyLogo } from '~/redux/companiesSlice';

import CompanyService from '~/services/company.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import ToastService from '~/services/toast.service';

import Address from '~/models/masterdata/Address';
import Company from '~/models/masterdata/Company';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

export const useCompanyForm = ({ closeForm, company, type }) => {
  const dispatch = useDispatch();

  const getDefaultCompany = useCallback(() => {
    const defaultCompany = new Company();
    defaultCompany.address.country = Address.DEFAULT_COUNTRY_CODE.DE;

    return defaultCompany;
  }, []);

  const [formState, setFormState] = useState({
    company: getDefaultCompany(),
    companyLoading: LOADING_STATE.NOT_LOADED,
    companyLogo: null,
    companyLogoHasBeenUpdated: false,
    submittingForm: false,
  });

  useEffect(() => {
    resetForm(true);
  }, [company]);

  const resetForm = useCallback(
    (resetGeneralCompanyInformation) => {
      if (!resetGeneralCompanyInformation) {
        const newCompany = cloneDeep(formState.company);
        newCompany.permissionGrantsFrom = company
          ? company.permissionGrantsFrom
          : getDefaultCompany().permissionGrantsFrom;

        setFormState((prevState) => ({
          ...prevState,
          company: newCompany,
        }));

        return;
      }

      setFormState({
        company: company ?? getDefaultCompany(),
        companyLogo: null,
        companyLogoHasBeenUpdated: false,
      });

      if (company && !company.additionalDataInitiated) {
        refreshCompany();
      }
    },
    [company, getDefaultCompany, formState.company],
  );

  const refreshCompany = useCallback(async () => {
    setFormState((prevState) => ({
      ...prevState,
      companyLoading: LOADING_STATE.LOADING,
    }));

    const [, error] = await promiseHandler(
      CompanyService.refreshCompany(company.id),
    );

    if (error) {
      setFormState((prevState) => ({
        ...prevState,
        companyLoading: LOADING_STATE.FAILED,
      }));
      return;
    }

    setFormState((prevState) => ({
      ...prevState,
      companyLoading: LOADING_STATE.SUCCEEDED,
    }));
  }, [company]);

  const renderForCreate = useCallback(() => type === 'create', [type]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormState((prevState) => ({
      ...prevState,
      submittingForm: true,
    }));

    const { address, name, organisationalGroups } = formState.company;
    const body = { address, name };

    if (renderForCreate()) {
      body.orgUnits = organisationalGroups;
    }

    Log.info('Submit company form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.COMPANY);

    if (renderForCreate()) {
      const [companyId, error] = await promiseHandler(
        CompanyService.createNewCompany(snakecaseKeys(body, { deep: true })),
      );

      if (error) {
        handleFormError('COMPANY_CREATION_FAILED', error);
        return;
      }

      await uploadCompanyLogo(companyId);
    } else {
      await updateCompany(body);
    }

    setFormState((prevState) => ({
      ...prevState,
      submittingForm: false,
    }));
    closeForm();
    resetForm(true);
    CompanyService.refreshCompanies();
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.COMPANY);

    closeForm();
    resetForm(true);
  };

  const handleFormError = (message, error) => {
    ToastService.httpError([ToastService.MESSAGE[message]], error.response);
    Log.error(`Failed to process company form.`, error);
    Log.productAnalyticsEvent(
      'Failed to process',
      Log.FEATURE.COMPANY,
      Log.TYPE.ERROR,
    );

    setFormState((prevState) => ({
      ...prevState,
      submittingForm: false,
    }));
  };

  const uploadCompanyLogo = async (companyId) => {
    if (!(formState.companyLogo?.size > 0)) {
      return;
    }

    Log.productAnalyticsEvent('Upload company logo', Log.FEATURE.COMPANY);

    const [, error] = await promiseHandler(
      CompanyService.uploadCompanyLogo(companyId, formState.companyLogo),
    );

    if (error) {
      handleFormError('COMPANY_LOGO_UPLOAD_FAILED', error);
      return;
    }

    dispatch(removeCompanyLogo(companyId));
  };

  const updateCompany = async (body) => {
    const [, error] = await promiseHandler(
      CompanyService.updateCompany(
        formState.company?.id,
        snakecaseKeys(body, { deep: true }),
      ),
    );

    if (error) {
      handleFormError('COMPANY_UPDATE_FAILED', error);
      return;
    }

    const [, error2] = await promiseHandler(
      OrganisationalGroupService.updateParentOrganisationalGroups(
        company.id,
        PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
        company.organisationalGroups,
        formState.company.organisationalGroups,
      ),
    );

    if (error2) {
      handleFormError('ORGANISATIONAL_GROUP_UPDATE_FAILED', error2);
      return;
    }

    await handleCompanyLogoUpdate();
  };

  const handleCompanyLogoUpdate = async () => {
    if (formState.companyLogo?.size > 0) {
      await uploadCompanyLogo(formState.company?.id);
      return;
    }

    const [, error] = await promiseHandler(
      CompanyService.deleteCompanyLogo(formState.company?.id),
    );

    if (error) {
      handleFormError('COMPANY_LOGO_DELETION_FAILED', error);
      return;
    }

    dispatch(removeCompanyLogo(formState.company?.id));
  };

  const handleInputChange = (event) => {
    const newCompany = cloneDeep(formState.company);

    const { name, value } = event.target;

    switch (name) {
      case 'name': {
        newCompany.name = value;
        break;
      }

      case 'street_name': {
        newCompany.address.streetName = value;
        break;
      }

      case 'building_number': {
        newCompany.address.buildingNumber = value;
        break;
      }

      case 'city': {
        newCompany.address.city = value;
        break;
      }

      case 'post_code': {
        newCompany.address.postCode = value;
        break;
      }
    }

    Log.info('Change form value', {
      from: formState.company[name],
      to: newCompany[name],
    });

    setFormState((prevState) => ({
      ...prevState,
      company: newCompany,
    }));
  };

  const handleChangeCountry = (event) => {
    const newCompany = cloneDeep(formState.company);
    newCompany.address.country = event.target.value;

    setFormState((prevState) => ({
      ...prevState,
      company: newCompany,
    }));
  };

  const handleChangeOrganisationalGroups = (organisationalGroups) => {
    const newCompany = cloneDeep(formState.company);
    newCompany.organisationalGroups = organisationalGroups.map(({ id }) => id);

    setFormState((prevState) => ({
      ...prevState,
      company: newCompany,
    }));
  };

  const setCompanyLogo = (companyLogo) => {
    setFormState((prevState) => ({
      ...prevState,
      companyLogo,
      companyLogoHasBeenUpdated: true,
    }));
  };

  const getUnsavedChanges = () => {
    if (renderForCreate()) {
      return [];
    }

    const differentValues = Company.getDifferentValues(
      company,
      formState.company,
    );
    if (formState.companyLogoHasBeenUpdated) {
      differentValues.push('Firmenlogo');
    }

    return differentValues;
  };

  return {
    formState,
    getUnsavedChanges,
    handleCancel,
    handleChangeCountry,
    handleChangeOrganisationalGroups,
    handleInputChange,
    handleSubmit,
    refreshCompany,
    renderForCreate,
    setCompanyLogo,
  };
};
