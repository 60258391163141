import { useQueries } from '@tanstack/react-query';

import DeliveriesService from '~/services/deliveries.service';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysDeliveryNote } from './queryKeys';

type QueryResult = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data?: UUID[];
};

const combinePermittedUsers = (results: QueryResult[]): QueryResult => {
  const isLoading = results.some(({ isLoading }) => isLoading);
  const isSuccess = results.every(({ isSuccess }) => isSuccess);
  const isError = results.some(({ isError }) => isError);

  const permittedUserIds = ArrayUtils.removeDuplicates(
    results
      .filter(({ isSuccess }) => isSuccess)
      .flatMap(({ data }) => data || []),
  );

  return {
    data: isSuccess ? permittedUserIds : [],
    isLoading,
    isSuccess,
    isError,
  };
};

export const useQueryPermittedUserIds = (deliveryNoteIds: UUID[] = []) =>
  useQueries({
    queries: deliveryNoteIds.map((deliveryNoteId) => ({
      queryKey: queryKeysDeliveryNote.getPermittedUserIds(deliveryNoteId),
      async queryFn() {
        const deliveryNote =
          await DeliveriesService.getDeliveryNoteById(deliveryNoteId);
        await deliveryNote.initPermittedUsers();

        return deliveryNote.permittedUsers.map(({ id }) => id);
      },
      onError(error: unknown) {
        Log.error(
          `Failed to load permitted users for delivery note id: ${deliveryNoteId}`,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to load permitted users',
          Log.FEATURE.NOTIFICATIONS,
          Log.TYPE.ERROR,
        );
      },
    })),
    combine: combinePermittedUsers,
  });
