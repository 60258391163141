import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export default class CustomerSubscription {
  constructor(customerSubscription) {
    this.id = customerSubscription?.id ?? '';
    this.name = customerSubscription?.name ?? '';
    this.active =
      customerSubscription?.is_active === undefined
        ? customerSubscription?.isActive === undefined
          ? true
          : customerSubscription?.isActive
        : customerSubscription?.is_active;
    this.type =
      customerSubscription?.subscription_type ??
      customerSubscription?.subscriptionType ??
      'process_state_change';
    this.events =
      customerSubscription?.subscription_events ??
      customerSubscription?.subscriptionEvents ??
      [];
    this.includeDln =
      customerSubscription?.include_dln_data ??
      customerSubscription?.includeDlnData;
    this.authType =
      customerSubscription?.auth_type ??
      customerSubscription?.authType ??
      'key_auth';
    this.keyAuthHeader =
      customerSubscription?.key_auth_header ??
      customerSubscription?.keyAuthHeader ??
      '';
    this.keyAuthValue =
      customerSubscription?.key_auth_value ??
      customerSubscription?.keyAuthValue ??
      '';
    this.callbackUrl =
      customerSubscription?.callback_url ??
      customerSubscription?.callbackUrl ??
      '';
    this.callbackPort =
      customerSubscription?.callback_port ??
      customerSubscription?.callbackPort ??
      null;
  }

  getEventStrings() {
    return this.events.map((key) => CustomerSubscription.getEventString(key));
  }

  static getEventString(eventKey) {
    const event = Object.keys(CustomerSubscription.EVENT).find(
      (x) => CustomerSubscription.EVENT[x].KEY === eventKey,
    );

    if (!event) {
      throw new EnumValueNotFoundException('Invalid event: ' + eventKey);
    }

    return CustomerSubscription.EVENT[event].STRING;
  }

  static EVENT = {
    READY_FOR_OUTPUT: {
      KEY: 'readyForOutput',
      STRING: 'Ausgabe',
    },
    CANCELLED: {
      KEY: 'cancelled',
      STRING: 'Storniert',
    },
    IN_TRANSPORT: {
      KEY: 'inTransport',
      STRING: 'In Transport',
    },
    ARRIVED: {
      KEY: 'arrived',
      STRING: 'Übergabe',
    },
    DELIVERED: {
      KEY: 'delivered',
      STRING: 'Geliefert',
    },
  };
  static IS_ACTIVE = {
    YES: 'Ja',
    NO: 'Nein',
  };
}
