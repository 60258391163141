import React from 'react';
import { connect } from 'react-redux';
import { SettingsTable } from '../../SettingsTable';
import CustomerSubscription from '~/models/masterdata/CustomerSubscription';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import MapperService from '~/services/mapper.service';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'active',
    headerName: 'Aktiv',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'events',
    headerName: 'Events',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'callbackUrl',
    headerName: 'URL',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  customerSubscriptions: state.customerSubscriptions,
});

class CustomerSubscriptionTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({
      rows: this.props.customerSubscriptions?.customerSubscriptions?.map(
        (customerSubscription) => {
          const row = {
            id: customerSubscription.id,
            name: customerSubscription.name,
            active: customerSubscription.active
              ? CustomerSubscription.IS_ACTIVE.YES
              : CustomerSubscription.IS_ACTIVE.NO,
            events: customerSubscription.getEventStrings().join(', '),
            includeDln: customerSubscription.includeDln,
            callbackUrl: customerSubscription.callbackUrl,
          };

          return MapperService.addSearchString(row, [
            'id',
            'name',
            'events',
            'callbackUrl',
          ]);
        },
      ),
    });
  };

  render() {
    return (
      <SettingsTable
        entity="customerSubscription"
        title="Lieferungen-Updates"
        rows={this.state.rows}
        columns={columns}
        items={this.props.customerSubscriptions.customerSubscriptions}
        localStorageKey={LocalStorageService.CUSTOMER_SUBSCRIPTION_TABLE}
        loading={this.props.customerSubscriptions.customerSubscriptionsLoading}
        hasActiveStateFilter
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(CustomerSubscriptionTable),
  'Lieferungen-Updates konnten nicht geladen werden.',
);
