import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LocalStorageService from '~/services/localStorage.service';
import MapperService from '~/services/mapper.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';

const selectCompanies = (state) => state.companies?.companies;
const selectCompaniesLoading = (state) => state.companies?.companiesLoading;

const CompanyTable = () => {
  const companies = useSelector(selectCompanies);
  const companiesLoading = useSelector(selectCompaniesLoading);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const loadData = () => {
      setRows(
        companies?.map(({ address, id, name }) => {
          const row = {
            id,
            name,
            address: address.getConcatenatedAddress(),
          };

          return MapperService.addSearchString(row, ['id', 'name', 'address']);
        }),
      );
    };

    if (companies?.length > 0) {
      loadData();
    }
  }, [companies]);

  return (
    <SettingsTable
      entity="company"
      title="Firmen"
      rows={rows}
      columns={columns}
      items={companies}
      loading={companiesLoading}
      multiPermissionGrantDefaultEntityType={
        PermissionGrant.ENTITY_TYPE.COMPANY.KEY
      }
      multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
      localStorageKey={LocalStorageService.COMPANY_TABLE}
      hasMultiPermissionGrantEditing
    />
  );
};

export default withErrorBoundary(
  memo(CompanyTable),
  'Firmen konnten nicht geladen werden.',
);
