import { useQuery } from '@tanstack/react-query';

import SiteService from '~/services/site.service';

import { queryKeysSite } from './queryKeys';

export const useQuerySiteIdentifierProposals = (options) =>
  useQuery({
    queryKey: queryKeysSite.getSiteIdentifierProposals(),
    queryFn: SiteService.getSiteIdentifierProposals,
    ...options,
  });
